import {Component, Input} from "@angular/core";
import {Modal} from "ngx-modialog/plugins/bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {CmAmazonPicturesService} from "../../services/cm-amazon-picture.service";

/**
 * Created by anikinma on 20.06.16.
 */
@Component({
  selector: 'cm-pictures',
  template: require('./cm-pictures.component.html'),
  styles: [require('./cm-pictures.component.scss').toString()]
})

export class CmPicturesComponent {

  @Input() pictures: Array<any> = [];
  @Input() readonly: boolean = false;
  @Input() insideModalWindow: boolean = false;

  constructor(private modal: Modal,
              private translate: TranslateService,
              private amazonPictures: CmAmazonPicturesService
  ) {
  }

  loadPictures(event) {

    if (!this.pictures) {
      this.pictures = [];
    }
    let files = event.target.files;

    if (event.target.files && (event.target.files.length + this.pictures.length) > 6) {
      let message: string;
      try {
        message = (<any>this.translate.get('overviewPhoto.MaxPictureMsg')).value;
      } catch (e) {
        console.log('Error: translation problems');
      }

      if(!message) message = 'Only 6 pictures can be uploaded!';

      this.modal.alert()
        .message(message)
        .open();
      return;
    }

    for (let file of files) {
      let reader = new FileReader();
      let dHolder = this.pictures;
      let t = this;
      reader.addEventListener("load", function () {
        dHolder[dHolder.length++] = reader.result;
      }, false);
      reader.readAsDataURL(file);
    }
    this.clearInputFile(event.target);
  }

  clearInputFile(f) {
    if (f.value) {
      try {
        f.value = ''; //for IE11, latest Chrome/Firefox/Opera...
      } catch (err) {
      }
      if (f.value) { //for IE5 ~ IE10
        var form = document.createElement('form'),
          parentNode = f.parentNode, ref = f.nextSibling;
        form.appendChild(f);
        form.reset();
        parentNode.insertBefore(f, ref);
      }
    }
  }

  removePicture(picture) {
    var index = this.pictures.indexOf(picture, 0);
    if (index > -1) {
      this.pictures.splice(index, 1);
    }
  }

  viewPicture(picture) {
    this.modal.alert()
      .message('<img class="img-responsive" src="' + this.getPicture(picture) + '"/>')
      .open();
  }

  private getPicture(data) {
    return this.amazonPictures.getPicture(data);
  }

}
