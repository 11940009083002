import {Marker} from "../../shared/marker";
import {CmLocationData} from "../../shared/location";

export class OutsideJob extends Marker {
    location: CmLocationData;
    priority: number;
    dateFinished: string;
    mustBeFixedAt: string;
    quantities: any;
    toWs: number;
    check: number;
    charge: number;
    assignedTo: Array<string> = [];
}
