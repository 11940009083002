import {CmBikeListItem} from "../cm-bike-short-list/cm-bike-list.model";

export class CmRepairModel {
    id: number;
    user: string;
    branding: string;
    dateFinished: string;
    bike: CmBikeListItem;
    task: number;

    checkedLock: boolean;
    fullCheck: boolean;
    loadToTruck: boolean;
    moveToWs: boolean;
    needAttention: boolean;

    repairTime: number;

    additionalRepair: string;
    comment: string;

    spareParts: CmRepairSparePart[] = [];
}

export class CmRepairSparePart {
    category : string;
    part: string;
    type: string;
    quantity: number;
}