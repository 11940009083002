import {Component, OnInit, ViewChild} from '@angular/core';
import {TableFilter} from "../../../shared/tableFilter";
import {CmRepairModel} from "../cm-repair.model";
import {CmHttpClient} from "../../../services/http_client";
import {Router} from "@angular/router";
import * as moment from "moment/moment";
import {TabTitle} from "../../../shared/tabTitle";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";
import {BIKE_AND_LOCATION_TYPES, BIKE_TYPE, LOCATION_TYPE} from "../../../shared/const/missionType";
import {DataService} from "../../../services/cm-data.service";
import {PageAdapter} from "../../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import {BSModalContext, Modal} from "ngx-modialog/plugins/bootstrap";
import {ReportExtractParam, ReportExtractParamField} from "../../../shared/reportExtractParam";
import {CmComponentTrackerService} from "../../../services/cm-component-tracker-service";
import {overlayConfigFactory} from "ngx-modialog";
import {CmRepairItemComponent} from "../item/cm-repair-item.component";
import {NgxSpinnerService} from "ngx-spinner";
import { SpinnerUtils } from '../../../shared/spinnerUtils';
import {CmReportComponent} from "../../cm-report/cm-report.component";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
    selector: 'cm-repair-list',
    template: require('./cm-repair-list.component.html'),
    styles: [require('../../cm-main/main.css').toString()]
})
export class CmRepairListComponent extends TabTitle implements OnInit {
    private itemList: Array<CmRepairModel> = [];
    private totalExport = 10000;

    private filters: CmBikeRepairFilters = new CmBikeRepairFilters();

    private pageLimit: number = 15;
    private total: number;
    private initPage: number = 1;
    private page: number;
    private stationList = [];

    private missionTypes = [
        {key:BIKE_TYPE,              label: 'mission.bikeType'},
        {key:LOCATION_TYPE,          label: 'mission.locationType'},
        {key:BIKE_AND_LOCATION_TYPES,label: 'mission.bothType'}
    ];

    private spType: string = SpinnerUtils.type;
    private spText: string = SpinnerUtils.text;
    private spColor: string = SpinnerUtils.color;
    private spBdColor: string = SpinnerUtils.bdColor;
    private spFullScreen: boolean = SpinnerUtils.fullScreen;
    private spSize: string = SpinnerUtils.size;

    private reportType: string = "Repair";
    private reportsListLen: Number = 0;
    private childProcessing: boolean = false;
    private maxReportsListSize = 5;
    @ViewChild('archivedrep', {static: false}) archivedChild: CmReportComponent;


    constructor(private http: CmHttpClient,
                private dataService: DataService,
                private router: Router,
                private modal: Modal,
                public translate: CmTranslateService,
                private componentTracker: CmComponentTrackerService,
                private spinner: NgxSpinnerService
    ) {
    super('repairs.Label', translate);
    }

    ngOnInit() {
        this.loadData();
        this.dataService.getStationList().subscribe(data => this.stationList = data);
    }

    public reloadData() {
        this.itemList = [];
        this.loadData();
    }

    private loadData(page?: number) {
        this.spinner.show();
        this.page = page;

        this.http.get<PageAdapter<CmRepairModel>>('/bikes-sql/api/v1/repair/?page=' + (page || this.initPage) +
            '&limit=' + this.pageLimit + this.filters.toString()).subscribe(
            data => {
                this.spinner.hide();
                this.itemList = data.content;
                this.total = data.total;
                if(this.total == 0){
                    this.modal.alert().message(this.translate.get("msg.NoResult")).open();
                }
            },
            error => {
                this.spinner.hide();
                this.itemList = [];
                this.modal.alert().message("error loading list of repairs").open();
            }
        );

        this.missionTypes = this.missionTypes.map(e => {
            e.label = this.translate.get(e.label) ? this.translate.get(e.label) : e.label;
            return e;
        });
    }

    private filter() {
        this.filters.save();
        this.loadData();
    }

    private showDetails(row) {
        this.modal.open(CmRepairItemComponent, overlayConfigFactory({repairId: row.id}, BSModalContext))
    }

    private formatDate(date): string {
        if (date) {
            return moment(date).format('DD.MM.YYYY HH:mm');
        } else {
            return '-';
        }
    }

    private sort(field) {
        this.filters.sort(field);
        this.loadData();
    }

    exportRepairs() {

        if(this.reportsListLen >= this.maxReportsListSize){
            this.archivedChild.removeLastReportFromList();
        }

        let urlParams = this.filters.toString();
        if(urlParams.length > 0) {
            urlParams = '?' + urlParams.substring(1);
        }

        let reportFileName = this.translate.get("report.repair.fileName") ;
        let reportParams = new ReportExtractParam();
        reportParams.method = "GET";
        reportParams.path = '/bikes-sql/api/v1/repair/export/withComplain' + urlParams;

        let trueFalseSubstitutions = {
            "true": this.translate.get("report.repair,cell.true"),
            "false": this.translate.get("report.repair,cell.false")
        };
        let sparePartTypeSubstitutions = {
            "Pieces": this.translate.get("report.repair,cell.Pieces"),
            "Box": this.translate.get("report.repair,cell.Box")
        };

        let stationSubstitution = {};
        this.stationList.forEach(s => stationSubstitution[s.name] = s.text);

        reportParams.fields =
            [
                new ReportExtractParamField("repairId", this.translate.get("report.repair.header.repairId")),
                new ReportExtractParamField("bikeNumber", this.translate.get("report.repair.header.bikeNumber")),
                new ReportExtractParamField("branding", this.translate.get("report.repair.header.branding")),
                new ReportExtractParamField("locationName", this.translate.get("report.repair.header.locationName")),
                new ReportExtractParamField("user", this.translate.get("report.repair.header.user")),
                new ReportExtractParamField("dateFinished", this.translate.get("report.repair.header.dateFinished"), "dd.MM.yyyy HH:mm", "CET"),
                new ReportExtractParamField("taskId", this.translate.get("report.repair.header.task")),
                new ReportExtractParamField("repairTime", this.translate.get("report.repair.header.repairTime")),
                new ReportExtractParamField("fullCheck", this.translate.get("report.repair.header.fullCheck"), undefined, undefined, trueFalseSubstitutions),
                new ReportExtractParamField("checkedLock", this.translate.get("report.repair.header.checkedLock"), undefined, undefined, trueFalseSubstitutions),
                new ReportExtractParamField("needAttention", this.translate.get("report.repair.header.needAttention"), undefined, undefined, trueFalseSubstitutions),
                new ReportExtractParamField("moveToWs", this.translate.get("report.repair.header.moveToWs"), undefined, undefined, trueFalseSubstitutions),
                new ReportExtractParamField("loadToTruck", this.translate.get("report.repair.header.loadToTruck"), undefined, undefined, trueFalseSubstitutions),
                new ReportExtractParamField("additionalRepair", this.translate.get("report.repair.header.additionalRepair")),
                new ReportExtractParamField("comment", this.translate.get("report.repair.header.comment")),
                new ReportExtractParamField("station", this.translate.get("report.repair.header.station"), undefined, undefined, stationSubstitution),
                new ReportExtractParamField("spareParts.id", this.translate.get("report.repair.header.sparePartId")),
                new ReportExtractParamField("spareParts.part", this.translate.get("report.repair.header.sparePartPart")),
                new ReportExtractParamField("spareParts.category", this.translate.get("report.repair.header.sparePartCategory")),
                new ReportExtractParamField("spareParts.quantity", this.translate.get("report.repair.header.sparePartQuantity")),
                new ReportExtractParamField("spareParts.type", this.translate.get("report.repair.header.sparePartType"), undefined, undefined, sparePartTypeSubstitutions),
                new ReportExtractParamField("complains.id", this.translate.get("report.repair.header.complainId")),
                new ReportExtractParamField("complains.category", this.translate.get("report.repair.header.complainCategory")),
                new ReportExtractParamField("complains.issue", this.translate.get("report.repair.header.complainIssue"))
            ];

        this.dataService.getReportExtract2(reportParams, this.reportType ,reportFileName)
            .subscribe(
                data => {
                    this.childProcessing = true;
                    this.archivedChild.startProcessingProcess();

                },
                error => {
                    console.log("Error downloading the file.");
                });

    }



    canLoadXls():boolean {
        return CmGuardService.isOrganizationAdmin();
    }

    processingMessage(event: any) {
        this.childProcessing = event;
    }

    onListSize($event: Number) {
        this.reportsListLen = $event
        let i=0;
    }

}

export class CmBikeRepairFilters extends TableFilter {
    job: number;
    bikeNumber: string;
    dateFinishedFrom: string;
    dateFinishedTo: string;
    users: Array<string>;
    branding: string[];
    type:string;

    constructor() {
        super('bikeRepairList');
    }

    public toString(): string {
        let types = [];
        if(this.type == BIKE_AND_LOCATION_TYPES) {
            types.push(BIKE_TYPE);
            types.push(LOCATION_TYPE);
        } else if (this.type) {
            types.push(this.type);
        }

        let result = super.toString();
        if (this.bikeNumber) {
            result += '&bikeNumber=' + this.bikeNumber;
        }
        if (this.job) {
            result += '&job=' + this.job;
        }
        if (this.dateFinishedFrom) {
            result += '&dateFinishedFrom=' + encodeURIComponent(this.dateFinishedFrom);
        }
        if (this.dateFinishedTo) {
            result += '&dateFinishedTo=' + encodeURIComponent(this.dateFinishedTo);
        }
        if (this.users && this.users.length > 0) {
            result += '&users=' + this.users;
        }
        if (this.branding && this.branding.length > 0) {
            result += '&bikeBranding=' + this.branding;
        }
        if (types && types.length > 0) {
            result += '&types=' + types;
        }
        return result;
    }

    public clear() {
        super.clear();
        this.job = null;
        this.bikeNumber = null;
        this.dateFinishedTo = null;
        this.dateFinishedFrom = null;
        this.users = [];
        this.branding = [];
        this.type = null;
        this.save();
    }
}
