import {Component} from "@angular/core";
import {DialogRef} from "ngx-modialog";
import {CustomModalContext} from "../../cm-assign/cm-assign.component";
import {CmLocation} from "../../../services/cm-location.service";

@Component({
    selector: 'cm-modal-location-list',
    template: require('./cm-modal-location-list.component.html'),
    styles: [require('./cm-modal-location-list.component.scss').toString()]
})
export class CmModalLocationListComponent {
    parent = null;

    constructor(public dialog: DialogRef<CustomModalContext>) {
        this.parent = dialog.context.parent;
    }

    private close() {
        this.dialog.close();
    }

    private selectLocation(location: CmLocation) {
        this.parent.selectLocation(location);
        this.close();
    }
}