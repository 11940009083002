import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import * as moment from "moment/moment";
import {timer, Subscription} from "rxjs";
import {DataService} from "../../services/cm-data.service";
import {CmArchivedReport} from "./cm-archived-report.model";
import {CmProgressbarConfModel} from "./cm-progressbar-conf.model";
import {CmUtil} from "../../shared/cm-util";
import {Modal} from "ngx-modialog/plugins/bootstrap";
import {CmTranslateService} from "../../services/localization/cm-translate.service";

@Component({
    selector: 'cm-report',
    template: require('./cm-report.component.html'),
    styles: [require('./cm-report.component.css').toString()]
})

export class CmReportComponent implements OnInit {
    @Input() reportType: string;
    @Input() hideCancelButton: boolean;
    @Output() processingAction = new EventEmitter();
    @Output() onListSize = new EventEmitter<Number>();

    private pbConf: CmProgressbarConfModel = new CmProgressbarConfModel();
    private archivedReports: CmArchivedReport[];
    processing: boolean = false;
    private updateArchivedListSubs: Subscription;
    private progressbarSubs: Subscription;

    constructor(
        private dataService: DataService,
        private modal: Modal,
        private translate: CmTranslateService){
    }

    ngOnDestroy() {
        this.updateArchivedListSubs.unsubscribe();
        this.progressbarSubs.unsubscribe();
    }

    ngOnInit() {
        this.loadArchivedList();

        this.updateArchivedListSubs = timer(1000, 1000)
            .subscribe(x => {
                if(this.processing) this.loadArchivedList();
            });

        this.progressbarSubs = timer(1000, this.pbConf.delay)
            .subscribe(x => {
                this.pbConf.value = this.pbConf.value + this.pbConf.step  > this.pbConf.max
                    ? this.pbConf.min
                    : this.pbConf.value += Math.floor(Math.random() * this.pbConf.step);
            });
    }


    loadArchivedList() {
        let substObjectMsg = this.translate.get('extractReporting.ObjectsName');
        let substDonectMsg = this.translate.get('extractReporting.DoneName');
        let subPocessing   = this.translate.get('excelReporting.processing');

        this.dataService.getArchivedReportList(this.reportType).subscribe(
            reports => {
                let anyInProcess = false;
                if(reports) {
                    reports.forEach(r => {if(r.ready == false) anyInProcess = true});

                    this.processing = anyInProcess;
                    this.processingAction.emit(this.processing);
                }
                this.archivedReports = reports.sort((r1, r2) => r2.timestampCreated - r1.timestampCreated);
                if(this.archivedReports) {
                    this.archivedReports.forEach(rep => {
                        rep.shortDetails = rep.shortDetails.replace("objects",substObjectMsg ? substObjectMsg :"objects");
                        rep.shortDetails =  rep.shortDetails.replace(/done/,substDonectMsg ? substDonectMsg :"done");
                        if(rep.shortDetails == 'extractReporting.startFillingMsg'){
                            rep.shortDetails = this.translate.get('extractReporting.startFillingMsg')
                        }
                        if(  rep.shortDetails.startsWith("excelReporting.processing")  ){
                            rep.shortDetails =  rep.shortDetails.replace(/excelReporting.processing/,subPocessing ? subPocessing : "Processing...")
                        }
                    }
                    )
                }
                this.onListSize.emit(reports.length);
            }, error1 => {
                console.log("err: " + error1);
            });
    }

    startProcessingProcess(){
        this.processing = true;
        this.loadArchivedList();
    }


    removeArchivedReport(report: CmArchivedReport) {
        this.modal.confirm()
            .title( this.translate.get('extractReporting.confirmDeleteTitle'))
            .message( this.translate.get('extractReporting.confirmDeleteMessage'))
            .okBtn(this.translate.get('dialog.okBtn'))
            .cancelBtn(this.translate.get('dialog.cancelBtn'))
            .open().result
            .then(result => this.dataService.deleteArchivedReport(report.fileUuid)
                .subscribe(resp => {this.loadArchivedList()}),() => {});
    }

    removeLastReportFromList() {
        let size = this.archivedReports.length;
        if(size > 0){
            this.dataService.deleteArchivedReport(this.archivedReports[size-1].fileUuid).subscribe(resp => {this.loadArchivedList()})
        }
    }

    loadArchivedReport(report: CmArchivedReport) {
        let dialogRef = this.modal.alert()
            .message(this.translate.get('processing.Label'))
            .okBtn(this.translate.get('cancel.Label'))
            .open();

        this.dataService.loadArchivedReport(report.fileUuid)
            .subscribe(
                data => {
                            dialogRef.close(true);
                            CmUtil.downloadFile(data, report.filename + '_' + this.timestampToDateStr(report.timestampCreated) + '.xlsx');
                },
                error => {
                            dialogRef.close(true);
                            console.log("Error downloading the file.");
                           this.modal.alert().message("Error downloading the file.").okBtn("Ok").open();
                });
    }

    cancelReport() {
        this.dataService.cancelReportProcessing(this.reportType)
            .subscribe(resp => {
                },
                err => console.log(err));
    }

    timestampToDateStr(timestampCreated: number) {
        // let offsetMillis = (new Date().getTimezoneOffset() * -1)*60*1000;
        return moment(timestampCreated).format('DD.MM.YYYY HH:mm');
    }
}
