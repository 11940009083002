import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Modal} from "ngx-modialog/plugins/bootstrap";

@Component({
    selector: 'cm-user',
    template: require('./cm-user.component.html')
})
export class CmUserComponent {

    @Input() login: string;
    @Output() showUserJobs: EventEmitter<boolean> = new EventEmitter<boolean>();
    private user: CmUserModel;

    private _showUserJobs: boolean = false;

    private jobs: Array<CmUserJob> = [];

    private dragAndDropEl: any;

    constructor(private modal: Modal) {

    }

    ngOnChanges() {
        this.user = new CmUserModel(this.login);
    }

    private showJobs(user) {
        this._showUserJobs = !this._showUserJobs;
        this.showUserJobs.next(this._showUserJobs);
    }

    private recalculate() {
        this.modal.alert()
            .message('Jobs were recalculated!')
            .open();
    }

    private sendToDriver() {
        this.modal.alert()
            .message('New data was transmitted to the driver!')
            .open();
    }

    removeRow(row) {
        let index = this.jobs.indexOf(row, 0);
        if (index > -1) {
            this.jobs.splice(index, 1);
        }
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    drag(ev) {
        this.dragAndDropEl = ev;
    }

    drop(ev, dropTo) {
        ev.preventDefault();

        if (this.dragAndDropEl instanceof CmUserJob) {
            this.dragAndDropEl.priority = dropTo.priority - 1 === 0 ? 1 : dropTo.priority - 1;
            this.dragAndDropEl = null;

            this.jobs.sort((a, b) => {
                return a.priority - b.priority
            });
        }
    }

}

class CmUserModel {
    login: string;
    transporter: string;
    stations: number;
    districts: number;
    bikes: number;

    constructor(login: string, transporter?: string, stations?: number, districts?: number, bikes?: number) {
        this.login = login;
        this.transporter = transporter ? transporter : this.generateTransporter();
        this.districts = districts ? districts : Math.floor(3 * Math.random()) + 1;
        this.stations = stations ? stations : Math.floor(10 * Math.random()) + 5;
        this.bikes = bikes ? bikes : Math.floor(20 * Math.random()) + 15;
    }

    generateTransporter(): string {
        let id = Math.floor(4 * Math.random()) + 1;
        return id % 2 === 0 ? 'Lorry ' + this.generateLicensePlate() : 'Truck ' + this.generateLicensePlate();
    }

    private letters: Array<string> = ['A', 'B', 'C', 'D', 'M', 'L', 'Y', 'Z', 'K']

    private generateLicensePlate() {
        let numbers = '' + Math.floor(10 * Math.random()) + Math.floor(10 * Math.random()) +
            Math.floor(10 * Math.random()) + Math.floor(10 * Math.random());
        return numbers + '-' + this.letters[Math.floor(9 * Math.random())] + this.letters[Math.floor(9 * Math.random())];
    }
}

class CmUserJob {
    from: string;
    to: string;
    bikes: number;
    priority: number;
    status: string;

    constructor(from: string, to: string, bikes: number, priority: number, status: string) {
        this.from = from;
        this.to = to;
        this.bikes = bikes;
        this.priority = priority;
        this.status = status;
    }
}


