export class CmJobItem {
  id: number;
  bike: number;
  qty: number;
  assignedTo: string;
  checked: boolean;
  taskType: JobInLocationType;
  type: string;

  constructor(
    id: number,
    taskType: JobInLocationType,
    inNumber: number,
    assignedTo: string,
    checked?: boolean
  ) {
    this.id = id;
    this.taskType = taskType;
    this.type = this.getType(this.taskType);
    if (taskType === JobInLocationType.move) {
      this.qty = inNumber;
    } else {
      this.bike = inNumber;
    }
    this.assignedTo = assignedTo;
    this.checked = checked || true;
  }

  private getType(taskType: JobInLocationType): string {
    switch (taskType) {
      case JobInLocationType.move:
        return "bikes";
      default:
        return "bike";
    }
  }
}

export enum JobInLocationType {
  move,
  moveToWs,
  check
}
