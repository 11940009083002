export class CmDamageLocationCategoryModel {
    name: string;
    city: string;
    check: number;
    number: number;
    estimatedRepairTime: number;
    suggestedParts: any;
    mappingText: Array<string>;
    closeComplainDirectly: boolean;
}
