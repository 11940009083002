import {Component} from '@angular/core'

/**
 * Created by anikinma on 03.08.16.
 */
@Component({
    selector: 'cm-no-roles',
    template: '<h1>{{"noRoles.Msg" | translate}}</h1>',
})

export class CmNoRolesComponent {

}
