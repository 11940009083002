import {Serializable} from "../../shared/serializable";
import {CmLocationData} from "../../shared/location";
import {CmBikeListItem} from "../cm-bike-short-list/cm-bike-list.model";
import {CmCoordinates} from "../../shared/coordinates";
import {BikeType} from "../../shared/bike";

export class BikeJobListItem extends Serializable {
    id: number;
    bike: CmBikeListItem;
    task: string;
    user: string;
    status: string;
    location: CmLocationData;
    priority: number;
    dateAssigned: string;
    dateCreated: string;
    dateFinished: string;
    issues: Array<string> = [];
    coordinates: CmCoordinates;
    categories: Array<string> = [];
    fullCheck: boolean;
    checkedLock: boolean;
    repairTime: number;
    comment: string;
    spareParts = [];
    estimatedRepairTime: number;
    mustBeFixedAt: string;
    quantities: any;
}

export enum CmJobType {
    TO_CHECK,
    TO_MOVE,
    TO_WORKSHOP,
    FOR_LOCATION,
    LOCATION,
    TO_CHARGE
}
