import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'cm-hour-minute',
    template: require('./cm-hour-minute.component.html'),
    styles: [require('./cm-hour-minute.component.scss').toString()]
})
export class CmHourMinuteComponent implements OnInit {
    @Input() hours: number;
    @Input() minutes: number;

    // Alternative way to use this component
    @Input() duration: number; // If durationTimeUnits not specified then in seconds Otherwise in durationTimeUnits
    @Input() durationTimeUnits: string; // 's' - for seconds, 'm' - for minutes

    @Input() readonly: boolean;

    private hoursHumanReadable: string;
    @Output() outHours: EventEmitter<number> = new EventEmitter<number>();

    @Output() outMinutes: EventEmitter<number> = new EventEmitter<number>();

    @Output() outDuration: EventEmitter<number> = new EventEmitter<number>();
    private minutesHumanReadable: string;

    ngOnInit(): void {
        if(!this.hours) this.hours = 0;
        if(!this.minutes) this.minutes = 0;

        if (this.duration) {
            if (!this.durationTimeUnits || this.durationTimeUnits === 's') {
                this.hours = Math.floor(this.duration / (60 * 60));
                this.minutes = Math.floor((this.duration - this.hours * 60 * 60) / 60)
            } else if (this.durationTimeUnits === 'm') {
                this.hours = Math.floor(this.duration / 60);
                this.minutes = Math.floor(this.duration - this.hours * 60);
            }
        }

        this.calculateHumanReadableValues();
    }

    private setHours() {
        this.outHours.next(this.hours);
        this.setDuration();
    }

    private setMinutes() {
        this.outMinutes.next(this.minutes);
        this.setDuration();
    }

    private setDuration() {
        if (!this.durationTimeUnits || this.durationTimeUnits === 's') {
            this.outDuration.next((this.hours * 60 + this.minutes) * 60);
        } else if (this.durationTimeUnits === 'm') {
            this.outDuration.next(this.hours * 60 + this.minutes);
        }
    }

    calculateHumanReadableValues() {
        this.hoursHumanReadable = this.hours < 10 ? "0" + this.hours : ""+ this.hours;
        this.minutesHumanReadable = this.minutes < 10 ? "0" + this.minutes : "" + this.minutes;

        this.changeHoursModel(this.hoursHumanReadable);
        this.changeMinutesModel(this.minutesHumanReadable);
    }

    changeHoursModel(event: any){
    }

    changeMinutesModel(event: any) {
    }

    changeHours(event: any) {
        let h = event.target.value;
        if(isNaN(h)) {
            this.hours = 0;
        } else {
            if(h < 0) {
                this.hours = 0;
            } else if(h > 23) {
                this.hours = 23;
            } else {
                this.hours = Number(h);
            }
        }

        this.calculateHumanReadableValues();
        this.setHours();
    }

    changeMinutes(event: any) {
        let m = event.target.value;
        if(isNaN(m)) {
            this.minutes = 0;
        } else {
            if(m < 0) {
                this.minutes = 0;
            } else if(m > 50) {
                this.minutes = 50;
            } else {
                this.minutes = Math.round(Number(m)/10) * 10;
            }
        }

        this.calculateHumanReadableValues();
        this.setMinutes();
    }

    plusHour(){
        if(this.hours < 23) {
            this.hours++;
            this.calculateHumanReadableValues();
            this.setHours();
        }
    }

    minusHour(){
        if(this.hours > 0) {
            this.hours--;
            this.calculateHumanReadableValues();
            this.setHours();
        }
    }

    plusMinute(){
        if(this.minutes < 50) {
            this.minutes +=10;
            this.calculateHumanReadableValues();
            this.setMinutes();
        }
    }

    minusMinute() {
        if(this.minutes > 0) {
            this.minutes -= 10;
            this.calculateHumanReadableValues();
            this.setMinutes();
        }
    }

}
