import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmLocationsService} from "../../../services/cm-location.service";
import {CmHttpClient} from "../../../services/http_client";

@Component({
    selector: 'cm-district-filter',
    template: require('./cm-district-filter.component.html')
})
export class CmDistrictFilterComponent implements OnInit {

    @Input() district: string = null;
    @Output() changeDistrict: EventEmitter<string> = new EventEmitter<string>();

    private districtList: Array<string> = [];

    constructor(private cmHttp: CmHttpClient) {
    }

    ngOnInit() {
        this.cmHttp.get<Array<string>>('/bikes-sql/api/v1/locations/districts').subscribe(
            (data:Array<string>) => this.districtList = data
        );
    }

    private _changeDistrict() {
        this.changeDistrict.next(this.district);
    }

}