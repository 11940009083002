import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BSModalContext, Modal } from "ngx-modialog/plugins/bootstrap";
import { CmHttpClient } from "../../../services/http_client";
import { CmTranslateService } from "../../../services/localization/cm-translate.service";
import { TabTitle } from "../../../shared/tabTitle";
import { overlayConfigFactory } from "ngx-modialog";
import { CmSparePartListModalComponent } from "../../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import { CmSparePart } from "../../cm-spare-part/cm-spare-part-list.model";
import { ASC_STRING, DESC_STRING } from "../../cm-sortable-th/cm-sortable-th.component";
import { TableFilter } from "../../../shared/tableFilter";
import {CmDamageLocationCategoryModel} from "../cm-damage-cat-location.model";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
    selector: "cm-damage-cat-item-config",
    template: require("./cm-damage-cat-location-config-item.component.html")
})
export class CmDamageLocationCatConfigItemComponent extends TabTitle implements OnInit {
    public model: CmDamageLocationCategoryModel = new CmDamageLocationCategoryModel();
    public selected: CmSparePart;
    public suggestedParts: Array<CmSparePart> = [];

    private sortField: string;
    private sortDirection: string;

    //filters
    private categories = [];
    private parts = [];

    // Mappings as comma separted string
    private mappingStr: string = "";

    private catExistsError = false;
    private isSavePressed = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modal: Modal,
        private http: CmHttpClient,
        private translationService: CmTranslateService
    ) {
        super("damageCategoryConfig.Label", translationService);

        activatedRoute.params.map(p => p).subscribe(data => {
            if ((<any>data).id !== "-1") this.model.number = (<any>data).id;
        });
    }

    public addSuggestedPart(p: CmSparePart) {
        this.selected = p;

        for (let sp of this.suggestedParts) {
            if (sp.id == p.id) return;
        }

        p.quantity = 1;
        this.suggestedParts.push(p);
    }

    ngOnInit() {
        if (this.model.number) {
            let number = this.model.number;
            this.http.get(`/bikes-sql/api/v1/damage-categories/${this.model.number}`).subscribe((data:CmDamageLocationCategoryModel) => {
                this.model = data;
                this.model.number = number;
                this.model.mappingText.forEach(m => this.mappingStr = this.mappingStr.concat(`${m};`));
            });

            this.http.get(`/bikes-sql/api/v1/damage-categories/suggested-parts/${this.model.number}`).subscribe((data:Array<CmSparePart>) => {
                var m = data;

                for (let sp of m) {
                    this.suggestedParts.push(sp);
                }
            });
        }
    }

    private openParts() {
        this.modal.open(CmSparePartListModalComponent, overlayConfigFactory({ parent: this }, BSModalContext));
    }

    private close() {
        this.router.navigate(["/sec/damageLocationCatConfig"]);
    }

    private incQuantity(sp: CmSparePart) {
        sp.quantity++;
    }

    private decQuantity(sp: CmSparePart) {
        if (sp.quantity > 0) {
            sp.quantity--;
        }
    }

    private removePart(p: CmSparePart) {
        const index: number = this.suggestedParts.indexOf(p);
        if (index !== -1) {
            this.suggestedParts.splice(index, 1);
        }
    }

    private save(isValid: boolean) {
        this.isSavePressed = true;
        if(isValid == false){
            return;
        }
        var arr: { [key: string]: number } = {};

        for (let sp of this.suggestedParts) {
            arr[sp.id] = sp.quantity;
        }

        this.model.suggestedParts = arr;

        this.model.mappingText = this.mappingStr ? this.mappingStr.split(";") : this.model.mappingText;

        this.catExistsError = false;
        this.http.post(`/bikes-sql/api/v1/damage-categories/for_location`, this.model).subscribe(data => {
            this.modal
                .alert()
                .message(this.translationService.get("message.SaveMsg"))
                .open();
        }, error => {
            if (error.status == 400 && error.error && error.error.errors) {
                error.error.errors.forEach(e => {
                    if (e.code == 'DataIntegrityViolationException') {
                        this.catExistsError = true;
                    }
                });
                if (!this.catExistsError)
                    throw error;
            } else {
                throw error;
            }
        });
    }

    private allowedToEditSettings(): boolean {
        return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
    }

    private sort(field) {
        this.sortField = field;
        if (this.sortDirection === ASC_STRING) {
            this.sortDirection = DESC_STRING;
            this.suggestedParts.sort((a, b) => {
                try {
                    return b[field].localeCompare(a[field]);
                } catch (ex) {
                    return (b[field] ? b[field] : -100) >= (a[field] ? a[field] : -100) ? 1 : -1;
                }
            });
        } else {
            this.sortDirection = ASC_STRING;
            this.suggestedParts.sort((a, b) => {
                try {
                    return a[field].localeCompare(b[field]);
                } catch (ex) {
                    return (a[field] ? a[field] : -100) >= (b[field] ? b[field] : -100) ? 1 : -1;
                }
            });
        }
    }
}

