import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CmStationService} from "./cm-station.service";
import {timeout} from "rxjs/operators";

@Injectable()
export class CmHttpClient {

    private urlPrefix: string = window.location.protocol  + '//' + window.location.hostname;
    private defaultTimeout: number = 60000;

    constructor(private http: HttpClient, private stationService: CmStationService) {
    }

    public getUrlPrefix(){
        return this.urlPrefix
    }

    get<T>(url, options?) {
        options = this.setToken(options);
        return this.http.get<T>(this.urlPrefix + url, <Object>options).pipe(
            timeout(this.processTimeout())
        );
    }

    getWithoutAuth<T>(url, options?) {
        return this.http.get<T>(this.urlPrefix + url, <Object>options).pipe(
            timeout(this.processTimeout())
        );
    }

    post<T>(url, data, options?) {
        options = this.setToken(options);
        return this.http.post<T>(this.urlPrefix + url, data, <Object>options).pipe(
            timeout(this.processTimeout())
        );
    }

    put(url, data, options?) {
        return this.http.put(this.urlPrefix + url, data, this.setToken(options)).pipe(
            timeout(this.processTimeout())
        );
    }

    delete(url, body?, options?) {
        if (body) {
            if (options) {
                options.body = body;
            } else {
                let headers = new HttpHeaders({
                    'Content-Type': 'application/json'})
                options = {
                    // new RequestOptions({
                    headers: headers,
                    body: body
                };
            }
        }
        return this.http.delete(this.urlPrefix + url, this.setToken(options)).pipe(
            timeout(this.processTimeout())
        );
    }

    private processTimeout() :number {
        let thisTimeout = localStorage.getItem('timeoutVal') || this.defaultTimeout;
        if (thisTimeout > 0) {
            return thisTimeout as number;
        } else {
            return this.defaultTimeout;
        }
    }

    private setToken(options) {
        let headers: HttpHeaders = new HttpHeaders();
        let responseType = 'json';
        if (options) {
            options.headers      = options.headers || headers;
            options.responseType = options.responseType ? options.responseType : responseType ;
        } else {
            options = {
                headers: headers,
                responseType : responseType
            };
        }

        let token = JSON.parse(localStorage.getItem("authToken"));

        if (token && token.access_token) {
            if(typeof options.headers.set === 'undefined') {
                options.headers.Authorization = "Bearer " + token.access_token;
            } else {
                options.headers = options.headers.set("Authorization", "Bearer " + token.access_token);
            }
        }

        if (this.stationService && this.stationService.station) {
            options.headers = options.headers.set("X-CM-Station", this.stationService.station)
        }

        return options;
    }

}
