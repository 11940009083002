import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {CmHttpClient} from "./http_client";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Subject} from "rxjs/Subject";
import {CmTranslateService} from "./localization/cm-translate.service";
import {CmOrganizationService} from "./cm-organization.service";

@Injectable()
export class CmAuthService {

    private errorAuthText = new Subject<string>();
    errorTextChanges$ = this.errorAuthText.asObservable();

    private successLogin = new Subject<boolean>();
    successLogin$ = this.successLogin.asObservable();
    isLoggedIn: boolean;
    alivePeriod = 60000;

    private deviceManagementUrl: string = '/device-management';
    private loginUrlPrefix: string = '/login/api/v1/in';
    private loginRefreshPrefix: string = '/login/api/v1';
    private logoutPrefix: string = '/login/api/v1/out';
    private confPeriodPrefix: string = '/alive/api/v1/period';

    constructor(private router: Router,
                private сmHttpClient: CmHttpClient,
                private translate: CmTranslateService,
                private organizationService: CmOrganizationService,
                private httpClient: HttpClient
    ) {
    }

    login(username: string, password: string) {

        let headers = this.createHeaders();
        let requestOptions = {
            headers: headers
        };

        let body =
        {
                'username': username,
                'password': password,
                'client_id':'employee-web-portal',
                'grant_type':'password'
        }

        this.сmHttpClient.post(this.deviceManagementUrl + this.loginUrlPrefix,

            body, requestOptions).subscribe(
            data => {
                let token = data;//.json();
                localStorage.setItem("authToken", JSON.stringify(token));
                this.router.navigate(['/sec']);
                this.successLogin.next(true);
                this.isLoggedIn = true;
                this.readAliveParameter();
            },
            error => this.loginError(error)
        );
    }

    readAliveParameter() {
        this.сmHttpClient.get<String>(this.deviceManagementUrl + this.confPeriodPrefix).subscribe(
            (data:any) => {
                let n = data;
                if (n && !isNaN(parseFloat(n)) && isFinite(n)) this.alivePeriod = n*1000;
            });
    }

    logout() {
        this.сmHttpClient.post(this.deviceManagementUrl + this.logoutPrefix, {}).subscribe(
            () => {},
            error => console.log(error)
        );

        localStorage.removeItem('authToken');
        this.organizationService.reset();
        this.successLogin.next(false);
        this.isLoggedIn = false;
        this.router.navigate(['/login']);
    }

    refreshToken(token, urlToRedirect) {
        let headers = this.createHeaders();
        let requestOptions = {
            headers: {Authorization: 'Basic ZW1wbG95ZWUtd2ViLXBvcnRhbDo='}
        };

        let body = new HttpParams()
        .set('refresh_token', token)
        .set('grant_type', 'refresh_token');

        this.сmHttpClient.post(this.deviceManagementUrl + this.loginRefreshPrefix, body, requestOptions).subscribe(
            data => {
                let token = data;//.json();
                localStorage.setItem("authToken", JSON.stringify(token));
                if(urlToRedirect) {
                    this.router.navigate([urlToRedirect]);
                }
            },
            error => {
                this.logout();
            }
        );
    }

    private createHeaders(): HttpHeaders{
        return new HttpHeaders().set('Authorization', 'Basic ZW1wbG95ZWUtd2ViLXBvcnRhbDo=');
    }

    private loginError(error) {
        if (error.status == 400) {
            if(error.error && error.error.error_description) {
                this.errorAuthText.next(this.translate.get(error.error.error_description));
            } else {
                if (error.message) {
                    this.errorAuthText.next(error.message);
                } else {
                    this.errorAuthText.next('Invalid user credentials!');
                }
            }
        } else {
            this.errorAuthText.next('Authentication service is unavailable!');
        }
    }

}
