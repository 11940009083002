import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {CmTranslateService} from "./localization/cm-translate.service";
import {BSModalContext, Modal} from "ngx-modialog/plugins/bootstrap";
import {overlayConfigFactory} from "ngx-modialog";
import {CmModalMessageComponent} from "../components/cm-modal-message/cm-modal-message-component";
import {HttpErrorResponse} from "@angular/common/http";
import {TimeoutError} from "rxjs";

@Injectable()
export class CmGlobalErrorHandler implements ErrorHandler {

    private modal: Modal;
    private translate: CmTranslateService;
    public static  isShownCmModalMessageComponent: boolean =false;

    constructor(private injector: Injector) { }

    handleError(error) {
        console.log(error);
        this.modal = this.injector.get(Modal);
        this.translate = this.injector.get(CmTranslateService);

        if (error instanceof TimeoutError) {
            this.showAlertPopup('Timeout exceeded');
        } else if (error instanceof HttpErrorResponse) {
            this.showHttpErrorPopup(error);
        } else {
            this.showUnexpectedError(error);
        }

        // Dirty hack!!! Don't know why, but in case of http errors app freeze without this line
        window.dispatchEvent(new Event('resize'));
    }

    private showHttpErrorPopup(error) {
        if (error.status == 403) {
            this.showAlertPopup('Access denied!');
        } else if (error.status == 400 && error.error && error.error.errors) {
            let errors = error.error.errors;
            let message = '';
            errors.forEach(e => message += 'Error in field ' + e.field + ': ' + (e.defaultMessage || e.code) + '<br/>');
            this.showAlertPopup(message);
        } else {
            this.showUnexpectedError(error);
        }
    }

    private showAlertPopup(message) {
        this.modal.alert().message(message).open();
    }

    private showUnexpectedError(error) {
    if(!CmGlobalErrorHandler.isShownCmModalMessageComponent) {
        CmGlobalErrorHandler.isShownCmModalMessageComponent = true;

        let promptMessage = this.translate.get("modal.msg.prompt");

        let message;
        if (error instanceof HttpErrorResponse) {
            if (error.message) {
                message = error.message
                if (error.error.message){
                    message = message + "  -   " + error.error.message;
                }

            } else {
                message = JSON.stringify(error.error);
            }
        } else {
            message = error.stack || error.message;
        }

        this.modal.open(CmModalMessageComponent, overlayConfigFactory({
            msgName: "Error",
            shortMsg: promptMessage,
            extMsg: message
        }, BSModalContext));
    }
    }

}