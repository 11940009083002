import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { CmHttpClient } from "./http_client";
import { CmGuardService } from "./guards/cm-guard.service";
import { CmCoordinates } from "./../shared/coordinates";
import {CmCity} from "./../components/cm-map/cm-map.component"
import {map, share} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class CmOrganizationService {
  private citySettingsUrl = "/bikes-sql/api/v1/cities";

  private settings: CmCity;

  constructor(private http: CmHttpClient) {}

  public loadOrganizationSettingsByLoginContext(): Observable<any> {
    if (this.settings) {
      return of(this.settings);
    } else {
      let orgaName: string = CmGuardService.getUserOrganizationFromToken();
      // Extract organization name from login context
      return this.http.get<CmCity>(this.citySettingsUrl + "/name/" + orgaName).pipe(map(settings => this.settings = settings));
    }
  }

  private isSettingsValid(orgaSettings): boolean {
    return (
      orgaSettings &&
      orgaSettings.eastLongitude &&
      orgaSettings.westLongitude &&
      orgaSettings.northLatitude &&
      orgaSettings.southLatitude
    );
  }

  // Loading the coordinates from remote service happens async. Make sure to use async pipe in template to subscribe to the returned Observable.
  public loadCoordinatesByLoginContext(): Observable<CmCoordinates> {
    let orgaSettings = this.loadOrganizationSettingsByLoginContext();
    return orgaSettings
      .pipe(map((response:any) =>
        // Check for empty body to avoid parse error
        this.extractCoordinates((<any>response))
      ))
      .pipe(share());
  }

  // Return the orgas coordinates or the coordinates of Berlin as fall back.
  public extractCoordinates(orgaSettings?): CmCoordinates {
    let coordinates: CmCoordinates = new CmCoordinates();

    if (this.isSettingsValid(orgaSettings)) {
      coordinates.latitude = (orgaSettings.northLatitude + orgaSettings.southLatitude) / 2;
      coordinates.longitude = (orgaSettings.eastLongitude + orgaSettings.westLongitude) / 2;
    } else {
      console.warn(`Invalid orga settings ${JSON.stringify(orgaSettings)}. Refering to fall back orga Berlin instead!`);
      coordinates.latitude = BERLIN_CENTER[0];
      coordinates.longitude = BERLIN_CENTER[1];
    }

    return coordinates;
  }

  public reset() {
    this.settings = null;
  }
}
export const BERLIN_CENTER = [52.520075, 13.404812];
