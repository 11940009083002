import { DEFAULT_LANGUAGE } from "./../../../app.component";
import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/cm-data.service";
import { TranslateService } from "@ngx-translate/core";
import { Modal } from "ngx-modialog/plugins/bootstrap";
import { TabTitle } from "../../../shared/tabTitle";
import { CmTranslateService } from "../../../services/localization/cm-translate.service";

@Component({
  template: require("./cm-settings.component.html")
})
export class CmSettingsComponent extends TabTitle implements OnInit {
  //language configuration vars
  langList: any[];
  selectedLang: string;
  halUrl: string;
  timeoutValue: number;
  lang = "settings.Language";

  constructor(
    private _dataService: DataService,
    private translate: TranslateService,
    private modal: Modal,
    public translateService: CmTranslateService
  ) {
    super("menu.SettingsLabel", translateService);
  }

  selectLang(langValue) {
    this.selectedLang = this.langList.filter(lang => lang.id == langValue)[0].id;
  }

  save() {
    localStorage.setItem("customerLang", this.selectedLang);
    localStorage.setItem("timeoutVal", this.timeoutValue.toString());
    localStorage.setItem("halUrl", this.halUrl);
    this.translate.use(this.selectedLang);

    this.modal
      .alert()
      .message("Saved!")
      .open();
  }

  ngOnInit(): any {
    this.selectedLang = localStorage.getItem("customerLang") || DEFAULT_LANGUAGE;
    this.timeoutValue = parseInt(localStorage.getItem("timeoutVal")) || 60000;
    this.halUrl = localStorage.getItem("halUrl") || "https://hal-prod.service.dbrent.net";
    localStorage.setItem("halUrl", this.halUrl);
    this._dataService.getLanguages().then(languages => (this.langList = languages));
  }
}
