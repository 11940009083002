import { CmUtil, SORT_DIRECTION } from './../../../shared/cm-util';
import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CmHttpClient} from "../../../services/http_client";
import * as moment from "moment/moment";
import {BSModalContext, Modal} from "ngx-modialog/plugins/bootstrap";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";
import {DataService} from "../../../services/cm-data.service";
import {CmSparePart} from "../cm-spare-part-list.model";
import {overlayConfigFactory} from "ngx-modialog";
import {CmModalSparePartCatWindow} from "../../cm-spare-parts-category/rename/cm-spare-part-cat-window.component";
import {CmSparePartCategory} from "../../cm-spare-parts-category/cm-spare-part-category.model";
import {TabTitle} from "../../../shared/tabTitle";
import {CmAmazonPicturesService} from "../../../services/cm-amazon-picture.service";

@Component({
    selector: 'cm-spare-part-detail',
    template: require('./cm-spare-part-detail.component.html'),
    styles: [require('./cm-spare-part-detail.component.scss').toString()]
})
export class CmSparePartDetailComponent extends TabTitle implements OnInit {

    private movementList: Array<MovementItem> = [];
    private id: number;
    private partData: CmSparePart = new CmSparePart();

    private stationList = [];
    private stationCodes = [];
    private categories = [];

    private movementLimit: number = 10;
    private isSavePressed = false;

    constructor(private router: Router,
                private r: ActivatedRoute,
                private http: CmHttpClient,
                private modal: Modal,
                private dataService: DataService,
                private translationService: CmTranslateService,
                private amazonPictures: CmAmazonPicturesService) {
        super('menu.InventoryLabel', translationService);

        r.params.map(p => p).subscribe(data => {
            this.id = parseInt((<any>data).id);
        });
    }

    ngOnInit() {
        this.loadCategories();

        if (this.id !== -1) {
            this.dataService.getStationList().subscribe(
                stations => {
                    if(stations.length > 0) {
                        this.stationList = stations;
                        this.stationCodes= this.stationList===null? null : this.stationList.map(function(station){return station.name});
                        this.http.post<Array<MovementItem>>('/bikes-sql/api/v1/moving/' + this.id + '?limit=' + this.movementLimit, this.stationCodes)
                            .subscribe(
                                data => {
                                if (data.length > 0) {
                                    this.movementList = data;
                                }
                            }
                        );

                    }
                }
            )//
            this.http.get<CmSparePart>('/bikes-sql/api/v1/parts/' + this.id).subscribe(
                data => this.partData = data
            )
        } else {
            this.id = null;
        }
    }

    public loadCategories(showHidden=false) {
        this.dataService.getSparePartsCategoriesList(showHidden).subscribe(
            data => this.categories = CmUtil.simpleSort(data.filter(d => d.text !== null), "text", SORT_DIRECTION.ASC)
        );
    }

    private close() {
        this.router.navigate(['/sec/sparePart']);
    }

    submitPictures() {
        let pictureLinks = [];

        if (!this.partData || !this.partData.pictures || this.partData.pictures.length < 1) return;

        let pictures = this.partData.pictures;
        for (let i = 0; i < pictures.length; i++) {
            if (pictures[i]) {
                pictureLinks.push(this.amazonPictures.submitPicture(pictures[i]));
            }
        }

        return pictureLinks;
    }

    private save(isValid: boolean) {
        this.isSavePressed = true;
        if(isValid == false){
            // this.modal.alert().message(this.translationService.get('damageCategoryConfig.EmptyFieldLabel')).open();
            return;
        }
        let pictureLinks = this.submitPictures();

        let partDataCopy = JSON.parse(JSON.stringify(this.partData));
        partDataCopy.pictures = pictureLinks;

        if (this.id) {
            this.http.put('/bikes-sql/api/v1/parts/' + this.id, partDataCopy).subscribe(
                () => this.modal.alert()
                    .message(this.translationService.get('message.SaveMsg'))
                    .open()
                    .result
                    .then(dialog => dialog.result)
                    .then(result => this.close())
                    .catch(err => {
                    }),
                error => this.modal.alert()
                    .message(
                        this.translationService.get(
                            DataService.getErrorMsg(error)
                        )
                    )
                    .open()
            )
        } else {
            this.http.post('/bikes-sql/api/v1/parts/add/' + partDataCopy.categoryId, partDataCopy).subscribe(
                () => this.modal.alert()
                    .message(this.translationService.get('message.SaveMsg'))
                    .open()
                    .result
                    .then(dialog => dialog.result)
                    .then(result => this.close())
                    .catch(err => {
                    }),
                error => this.modal.alert()
                    .message(
                        this.translationService.get(
                            DataService.getErrorMsg(error)
                        )
                    )
                    .open()
            )
        }
    }

    private formatDate(date): string {
        return date ? moment(date).format('DD.MM.YYYY') : '-';
    }

    private getStationName(testId) : string {
        try {
            for (let station of this.stationList) {
                if (parseInt(testId) === parseInt(station.name)) {
                    return station.text;
                }
            }
        } catch (e) {
            //ignore the parse error and return testId
        }
        return testId;
    }

    private renameCategory() {
        if (this.partData.categoryId) {
            let catNameItemList = this.categories.filter(el => el.name ===this.partData.categoryId);
            if (catNameItemList && catNameItemList.length === 1) {
                this.modal.open(CmModalSparePartCatWindow,
                    overlayConfigFactory({
                            parent: this,
                            category: new CmSparePartCategory(this.partData.categoryId, catNameItemList[0].text)
                        },
                        BSModalContext));
            }
        }
    }
    private  reloadCategories(event){
        this.loadCategories( event.currentTarget.checked);
    }

    onTextChange(text){
        return text.trimLeft();
    }


}

class MovementItem {
    when: string;
    quantity: number;
    from: string;
    to: string;
    who: string;
}
