import {Component, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import { CmHttpClient } from "../../../services/http_client";
import { DataService } from "../../../services/cm-data.service";
import { CmSparePart } from "../cm-spare-part-list.model";
import { Router } from "@angular/router";
import { TableFilter } from "../../../shared/tableFilter";
import { TabTitle } from "../../../shared/tabTitle";
import { CmTranslateService } from "../../../services/localization/cm-translate.service";
import { CmUtil } from "../../../shared/cm-util";
import {CmGuardService} from "../../../services/guards/cm-guard.service";
import * as moment from 'moment/moment';
import {Modal, OneButtonPreset} from "ngx-modialog/plugins/bootstrap";
import {HttpHeaders, HttpResponse} from "@angular/common/http";
import {PageAdapter} from "../list-modal/cm-spare-part-list-modal.component";
import {CmComponentTrackerService} from "../../../services/cm-component-tracker-service";
import {NgxSpinnerService} from "ngx-spinner";
import { SpinnerUtils } from '../../../shared/spinnerUtils';
import {CmReportComponent} from "../../cm-report/cm-report.component";
import {ReportExtractParam, ReportExtractParamField} from "../../../shared/reportExtractParam";
import {PagerService} from "../../../services/cm-pager.service";

@Component({
  selector: "cm-spare-part-list",
  template: require("./cm-spare-part-list.component.html"),
  styles: [require("../../cm-main/main.css").toString()]
})
export class CmSparePartListComponent extends TabTitle implements OnInit {
  private spareParts: Array<CmSparePart> = [];
  private totalExport = 10000;

  private pageLimit: number = 15;
  private total: number;
  private page: number = 1;

  private filters: Filters = new Filters();
  private categories = [];
  private parts = [];
  private stationList = [];
  private stationCodes = [];

  private csvFile;
  private csvFileName: string;

  private reportType: string = "Inventory";
  private reportsListLen: Number = 0;
  private childProcessing: boolean = false;
  private maxReportsListSize = 5;
    @ViewChild('archivedrep', {static: false}) archivedChild: CmReportComponent;



    private spType: string = SpinnerUtils.type;
    private spText: string = SpinnerUtils.text;
    private spColor: string = SpinnerUtils.color;
    private spBdColor: string = SpinnerUtils.bdColor;
    private spFullScreen: boolean = SpinnerUtils.fullScreen;
    private spSize: string = SpinnerUtils.size;
    private _pager;

    constructor(
    private http: CmHttpClient,
    private dataService: DataService,
    private router: Router,
    public translate: CmTranslateService,
    private modal:Modal,
    private componentTracker: CmComponentTrackerService,
    private spinner: NgxSpinnerService,
    private pagerService: PagerService
  ) {
    super("menu.InventoryLabel", translate);
  }

  ngOnInit() {
      this._pager = this.pagerService.getPager(this.total, 1, this.pageLimit,true);
    this.csvFileName = this.translate.get('chooseFileToImport.label') || 'chooseFileToImport.label' ;
    this.dataService.getStationList().subscribe(data => {
        this.stationList = this.simpleSort(data, "text");
        this.stationCodes= this.stationList===null? null : this.stationList.map(function(station){return station.name});
        let lastPage = this.componentTracker.readLastParentPageForCurrentComponentIfExist()
        if(lastPage)
            this.page = lastPage;
        // this.loadData();
            this.setPage(this._pager.currentPage);
       },
        err => {
            console.log("err!!!" + err)
        }
    );

    this.reloadCategories(true);

  }

    private simpleSort(list: any[], sortProperty: string): any[] {
    if (list) {
      return list.sort((a, b) => {
        if (a[sortProperty]) return a[sortProperty].localeCompare(b[sortProperty]);
        return 0;
      });
    }
    return list;
  }

  private  reloadCategories(init: boolean){
      var go : boolean = false;
      if(init==true){
          go=this.filters.showHidden;
      }else{
          go=!this.filters.showHidden;
      }

      this.dataService.getSparePartsCategoriesList(go).subscribe(data => {
          this.categories = this.simpleSort(data, "text");
          if (this.filters.category) this.loadInventoryPart();
      }
      , err => {
          console.log("error downloading category list!  " + err);
          }
      );
  }

  private loadData() {
    this.setPage(this.page);
  }

  private loadInventoryPart() {
    this.dataService
      .getSparePartsByCategory(this.filters.category)
      .subscribe(data =>
          (this.parts = this.simpleSort(data, "text")));
  }

  private openSparePart(sparePart: CmSparePart) {
      this.componentTracker.goToDetailsFromParentPage(this.page);
      this.router.navigate([`/sec/sparePart/${sparePart.id}`]);
  }

    onPager(pager: any){

    }

  private setPage(page: number) {
      this.spinner.show();
      this.page = page;
      let stationsVar ="";
      if( !this.filters.showAllCities){
          stationsVar = stationsVar + `&stations=${this.stationCodes}`;
      }

      this.http
      .get<PageAdapter<CmSparePart>>(
        `/bikes-sql/api/v1/availability/availability/parts?page=${page}&limit=${
          this.pageLimit
        }${this.filters.toString()}${stationsVar}`)
      // .map(result => result.json())
      .subscribe(data => {
        this.spareParts = data.content;
        this.total = data.total;
        this.spinner.hide();
            if(this.total == 0){
              this.modal.alert().message(this.translate.get("msg.NoResult")).open();
             }
        }
      ,
      error => {
               this.spinner.hide();
               console.error(error);
               let msg = error.message;
               if(!msg) {
                   msg = "Error receiving data";
               }
               this.modal.alert().message(msg).size("lg").title("Error").open();

           }
      );
  }
  private filter() {
    this.page = 1;
    this.loadData();
    this.filters.save();
  }

  private clearFilters() {
    this.filters.clear();
  }

  private addGroup() {
    this.router.navigate(["/sec/sparePart/addGroup"]);
  }

  private addPart() {
    this.router.navigate(["/sec/sparePart/-1"]);
  }

  private createMovement() {
    this.router.navigate(["/sec/sparePart/movePart"]);
  }

  private exportData() {
      if(this.total > this.totalExport ){
          this.modal.alert().message(this.translate.get('troubleTickets.reportFilterMessage')).open();
          return;
      }

      let stationsVar ="";
      if( !this.filters.showAllCities){
          stationsVar = stationsVar + `&stations=${this.stationCodes}`;
      }

     window.open(this.http.getUrlPrefix() + `/bikes-sql/api/v1/availability/availability/partsXls?${this.filters.toString()}${stationsVar}`, "blank");
  }

  private sort(field) {
    this.filters.sort(field);
    this.reloadData();
  }

  public reloadData() {
    this.spareParts = [];
    this.loadData();
  }

     loadReportCsv(){
         if(this.reportsListLen >= this.maxReportsListSize){
             this.archivedChild.removeLastReportFromList();
         }

        let urlParams = this.filters.toString();
        if(urlParams.length > 0) {
            urlParams = '?' + urlParams.substring(1);
        }

        let pathVar = `/bikes-sql/api/v1/availability/availability/parts-report?${this.filters.toString()}`;

        if( !this.filters.showAllCities){
            pathVar = pathVar + `&stations=${this.stationCodes}`;
        }

        let substitutionsQuantityType = {
            "Pieces": this.translate.get("report.inventory.cell.Pieces"),
            "Box": this.translate.get("report.inventory.cell.Box")
        };
        let substitutionsType = {
            "USAGE": this.translate.get("report.inventory.cell.USAGE"),
            "TRANSFER": this.translate.get("report.inventory.cell.TRANSFER"),
            "INCOME": this.translate.get("report.inventory.cell.INCOME"),
            "WASTING": this.translate.get("report.inventory.cell.WASTING")
        };

         let stationSubstitution = {};
         this.stationList.forEach(s => stationSubstitution[s.name] = s.text);

         let reportParams = new ReportExtractParam();
         reportParams.method = "GET";
         reportParams.path = pathVar;
         reportParams.fields =
             [
             new ReportExtractParamField("id"         , this.translate.get("report.inventory.header.id")),
             new ReportExtractParamField("category"   , this.translate.get("report.inventory.header.category")),
             new ReportExtractParamField("part"       , this.translate.get("report.inventory.header.part")),
             new ReportExtractParamField("quantity"   , this.translate.get("report.inventory.header.quantity")),
             new ReportExtractParamField("barcode"    , this.translate.get("report.inventory.header.barcode")),
             new ReportExtractParamField("supplier"   , this.translate.get("report.inventory.header.supplier")),
             new ReportExtractParamField("description", this.translate.get("report.inventory.header.description")),
             new ReportExtractParamField("articleNumber", this.translate.get("report.inventory.header.articleNumber")),
             new ReportExtractParamField("supplierArticleNumber", this.translate.get("report.inventory.header.supplierArticleNumber")),
             new ReportExtractParamField("lastMovements.beforeMoving", this.translate.get("report.inventory.header.before")),
             new ReportExtractParamField("lastMovements.quantity", this.translate.get("report.inventory.header.lastMovements.quantity")),
             new ReportExtractParamField("lastMovements.afterMoving",  this.translate.get("report.inventory.header.lastMovements.after")),
             new ReportExtractParamField("lastMovements.quantityType", this.translate.get("report.inventory.header.lastMovements.quantityType"), null, null, substitutionsQuantityType),
             new ReportExtractParamField("lastMovements.when", this.translate.get("report.inventory.header.lastMovements.when"), "dd.MM.yyyy HH:mm",  "CET"),
             new ReportExtractParamField("lastMovements.from", this.translate.get("report.inventory.header.lastMovements.from"), null, null, stationSubstitution),
             new ReportExtractParamField("lastMovements.to", this.translate.get("report.inventory.header.lastMovements.to"), null, null, stationSubstitution),
             new ReportExtractParamField("lastMovements.who", this.translate.get("report.inventory.header.lastMovements.who")),
             new ReportExtractParamField("lastMovements.type", this.translate.get("report.inventory.header.lastMovements.type"), null, null, substitutionsType),
             new ReportExtractParamField("lastMovements.comment", this.translate.get("report.inventory.header.lastMovements.comment")),
             new ReportExtractParamField("lastMovements.bikeNumber", this.translate.get("report.inventory.header.lastMovements.bikeNumber")),
             new ReportExtractParamField("lastMovements.availableQuantity", this.translate.get("report.inventory.header.lastMovements.availableQuantity")),
             new ReportExtractParamField("lastMovements.availableQuantityTo", this.translate.get("report.inventory.header.lastMovements.availableQuantityTo")),
             new ReportExtractParamField("usedParts", this.translate.get("report.inventory.header.usedParts"))
             ];

         let reportFileName = this.translate.get("report.inventory.fileName") ;

         this.dataService.getReportExtract2(reportParams, this.reportType ,reportFileName)
             .subscribe(
                 data => {
                     this.childProcessing = true;
                     this.archivedChild.startProcessingProcess();

                 },
                 error => {
                     console.log("Error downloading the file.");
                 });



     };


    private getStationName(testId) : string {
        try {
            for (let station of this.stationList) {
                if (parseInt(testId) === parseInt(station.name)) {
                    return station.text;
                }
            }
        } catch (e) {
            //ignore the parse error and return testId
        }
        return testId;
    }

    uploadFileChoose(event){
        let file = event.target.files[0];
        if (!file) return;

        this.csvFileName = file.name;
        let reader = new FileReader();
        let thisContext = this;

        reader.addEventListener("load",
            function () {
                thisContext.csvFile = reader.result;
            }, false);

        reader.readAsText(file);

    };

    uploadCSVasJSON() {

        var newArr = CmUtil.CSVToArray(this.csvFile, ',');
        let result = [];
        for (let i = 1; i< newArr.length ;i++) {
            let obj = {};
            for (let j = 0; j < newArr[0].length; j++) {
                obj[newArr[0][j]] = newArr[i][j];
            }
            let isNotEmptyObject=false;
            for(var key in obj){
                if(obj[key]){
                    isNotEmptyObject=true;
                    break;
                }
            }
            if(!isNotEmptyObject)
            {continue;
            }
            result.push(obj);
        }

        this.dataService.sendJson('/bikes-sql/api/v1/parts/saveall', result)
            .subscribe(
                reps => {
                    const msgArr = reps;//.json();
                    if ( msgArr.length > 0 ) {
                        let msg = "Results: Bikes did not uploaded!<br/>";
                        for (var i = 0; i < msgArr.length; i++) {
                            msg += "<br/> err no: " + i+1 + "<br/>" + msgArr[i];
                        }
                        this.showMessage(msg)
                    } else {
                        this.showMessage(this.translate.get("successLabel"))
                    }
                }
                ,    err => this.showMessage(this.translate.get(err))
            );

    };

    private canAddSparePart() {
        let params = CmGuardService.getTokenParam("parameters");
        return params["canAddSparePart"] == true
    }

    private canAddStockMovement() {
        let params = CmGuardService.getTokenParam("parameters");
        return params["canAddStockMovement"] == true
    }

    private allowedToEditSettings(): boolean {
        return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
    }
    private showMessage(message:any) {
        this.modal.alert()
            .message(message)
            .open();
    }

    canLoadXls():boolean {
        return CmGuardService.isOrganizationAdmin();
    }


    processingMessage(event: any) {
        this.childProcessing = event;
    }

    onListSize($event: Number) {
        this.reportsListLen = $event
        let i=0;
    }


}

class Filters extends TableFilter {
  id: number;
  articleNumber: string;
  station: number;
  category: string;
  part: string;
  freeText: string;
  showHidden: boolean;
  showAllCities: boolean; // don't put to toString() !!!
  dateMovingStarted: string;
  dateMovingFinished: string;

  constructor() {
    super("sparePartListFilters");
  }

  public toString(): string {
    let url: string = super.toString();
    if (this.id) url += "&id=" + this.id;
    if (this.articleNumber) url += "&articleNumber=" + this.articleNumber;
    if (this.station) url += "&station=" + this.station;
    if (this.category) url += "&category=" + this.category;
    if (this.part) url += "&part=" + this.part;
    if (this.freeText) url += "&freeText=" + encodeURIComponent(this.freeText);
    if (this.showHidden) url += "&showHidden=" + this.showHidden;
    if (this.dateMovingStarted) {
          url += '&dateMovingStarted=' + encodeURIComponent(this.dateMovingStarted);
    }
    if (this.dateMovingFinished) {
          url += '&dateMovingFinished=' + encodeURIComponent(this.dateMovingFinished);
    }

    return url;
  }

  public clear() {
    super.clear();
    this.id = null;
    this.articleNumber  = null;
    this.station = null;
    this.category = null;
    this.part = null;
    this.freeText = null;
    this.showHidden = false;
    this.showAllCities = false;
    this.dateMovingFinished = null;
    this.dateMovingStarted = null;
    this.save();
  }
}
