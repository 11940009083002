import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CmHttpClient } from "../../../services/http_client";
import { DataService } from "../../../services/cm-data.service";
import { BikeJobListItem } from "../cm-bike-job-list.model";
import { CmTranslateService } from "../../../services/localization/cm-translate.service";
import { TabTitle } from "../../../shared/tabTitle";
import   "rxjs/add/operator/map";
import {BSModalContext} from "ngx-modialog/plugins/bootstrap";
import {DialogRef, ModalComponent} from "ngx-modialog";

@Component({
  selector: "cm-bike-job-item",
  template: require("./cm-bike-job-item.component.html"),
  styles: [require('./cm-bike-job-item.component.scss').toString()]
})
export class CmBikeJobItemComponent extends TabTitle implements ModalComponent<CmBikeJobItemModalDialog>, OnInit {
  private model: BikeJobListItem = new BikeJobListItem();
  private typeList = [];

  constructor(
    private http: CmHttpClient,
    public dialog: DialogRef<CmBikeJobItemModalDialog>,
    private router: Router,
    private r: ActivatedRoute,
    private dataService: DataService,
    public translate: CmTranslateService
  ) {
    super("bikeJobList.Title", translate);
    this.model.id = dialog.context.id;
  }

  ngOnInit() {
    this.dataService.returnLocalizedMap("/bikesJobListType").subscribe((data:any) => {
      this.typeList = data.bikesJobListType;
      this.loadData();
    });
  }

  private loadData() {
    this.http
      .get<BikeJobListItem>("/bikes-sql/api/v1/tasks/" + this.model.id)
      .subscribe(data => {
        this.model = data;
      });
  }

  private close() {
    this.dialog.close();
  }

  private getTaskName(taskId): string {
    if (this.typeList) {
      for (let item of this.typeList) {
        if (item.name === taskId) {
          return item.text;
        }
      }
    }

    return taskId;
  }
}

export class CmBikeJobItemModalDialog extends BSModalContext {
  public id: number;
}
