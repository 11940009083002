import { CmUtil, SORT_DIRECTION } from './../../../shared/cm-util';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CmHttpClient } from "../../../services/http_client";
import { Employee } from "../../cm-assign/cm-assign.component";
import {HttpHeaders} from "@angular/common/http";

@Component({
    selector: 'cm-users-filter',
    template: require('./cm-users-filter.component.html'),
    styles: [require('./cm-users-filter.component.scss').toString()]
})
export class CmUsersFilterComponent implements OnInit, OnChanges {

    @Input() selectedEmployee: Array<string> = [];
    @Output() changeEmployee: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    private employeeList: Array<EmployeeListItem> = [];

    constructor(private cmHttp: CmHttpClient) {
    }

    ngOnChanges() {
        this.employeeList.forEach(el => el.selected = false);
        if (this.selectedEmployee) {
            this.selectedEmployee.forEach(el => {
                this.employeeList.forEach(el1 => {
                    if (el1.employee.login === el) {
                        el1.selected = true;
                    }
                })
            });
        }
    }

    ngOnInit() {
        let headers = new HttpHeaders().set('Authorization', 'Basic ZW1wbG95ZWUtd2ViLXBvcnRhbDo=');
        let requestOptions = {
            headers: headers,
            observe: "body"
        };

        this.cmHttp.get<Employee[]>('/user-information/api/v1/users',requestOptions).subscribe(
            ( data:Employee[] ) => {
                const employees: Employee[] = CmUtil.simpleSort(data, "login", SORT_DIRECTION.ASC);
                employees.forEach(employee => this.employeeList.push(new EmployeeListItem(employee)));
                this.ngOnChanges();
            }
        );
    }

    private getName(item: EmployeeListItem): string {
        let employee = item.employee;
        return employee.login;
    }

    private _changeEmployee() {
        let outList = [];
        this.employeeList.forEach(el => {
            if (el.selected) {
                outList.push(el.employee.login);
            }
        });
        this.changeEmployee.next(outList);
    }
}

class EmployeeListItem {
    employee: Employee;
    selected: boolean;

    constructor(employee: Employee) {
        this.employee = employee;
        this.selected = false;
    }
}
