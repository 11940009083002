import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'cm-table-total',
    template: require('./cm-table-total.component.html'),
    styles: [require('./cm-table-total.component.scss').toString()]
})
export class CmTableTotalComponent implements OnInit {

    @Input() total : number = 0;

    constructor() { }

    ngOnInit() { }

}