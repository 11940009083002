import { CmUtil } from './../../../shared/cm-util';
import { CmBikeBlockingService } from './../../../services/cm-bike-blocking.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmLocation } from '../../../services/cm-location.service';
import { BSModalContext, Modal } from 'ngx-modialog/plugins/bootstrap';
import { overlayConfigFactory } from 'ngx-modialog';
import { CmModalLocationListComponent } from '../../cm-location/modal-window/cm-modal-location-list.component';
import { Subscription } from 'rxjs/Subscription';
import { CmTranslateService } from '../../../services/localization/cm-translate.service';
import { CmLocationData } from '../../../shared/location';
import { CmCoordinates } from '../../../shared/coordinates';
import { CmHttpClient } from '../../../services/http_client';
import { DataService } from '../../../services/cm-data.service';
import { CmBikeDispatchModel } from '../cm-bike-dispatch.model';
import { CmTroubleTicketFilters } from '../../cm-trouble-ticket/list/cm-trouble-tiket-list.component';
import { CmBikeRepairFilters } from '../../cm-repair/list/cm-repair-list.component';
import { TabTitle } from '../../../shared/tabTitle';
import { Observable } from 'rxjs/Observable';
import { CmOrganizationService } from '../../../services/cm-organization.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import {SPERR_HAL_STATUS, SPERR_MSP_STATUS} from "../../cm-map/cm-map.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'cm-bike-dispatch-item',
  template: require('./cm-bike-dispatch-item.component.html')
})
export class CmBikeDispatchItemComponent extends TabTitle implements OnInit, OnDestroy {
  private model: CmBikeDispatchModel = new CmBikeDispatchModel();
  private stationList = [];
  private statusList = [];
  private initialState;

  private mapCenter: Observable<CmCoordinates>;
  private latitude: number;
  private longitude: number;

  private _showMap: boolean = false;

  private statusChanged: boolean = false;
  private subList: Array<Subscription> = [];

  private bikePath: string = '/master-data/api/v1/bin/clientFiles/bike.png';

  private allowedLocations: Array<CmLocation>;
  private whoIsCalledLocationPopup;
  private lastAllowedLocationSortedProperty = null;

  constructor(
    private http: CmHttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private modal: Modal,
    private translationService: CmTranslateService,
    private translationServiceNative: TranslateService,
    private orgaService: CmOrganizationService,
    private bikeBlockingService: CmBikeBlockingService
  ) {
    super('bikeDispatch.Label', translationService);
  }

  ngOnInit(): void {
    let stationSub = this.dataService.getStationList().subscribe(data => {
      this.stationList = data;
    });
    this.subList.push(stationSub);

    this.activatedRoute.params.map(p => p).subscribe(data => {
      this.model.bikeId = data['id'];
        this.loadData();
    });

    this.mapCenter = this.orgaService.loadCoordinatesByLoginContext();
    this.mapCenter.subscribe(data => {
      this.longitude = data.longitude;
      this.latitude = data.latitude;
    });

    this.dataService.getBikeAllowedLocations(this.model.bikeId)
        .subscribe( data => {
          this.allowedLocations = data
        });
  }

  ngOnDestroy() {
    for (let sub of this.subList) {
      sub.unsubscribe();
    }
  }

  private loadData() {
    this.http.get<CmBikeDispatchModel>('/bikes-sql/api/v1/bicycles/' + this.model.bikeId).subscribe(data => {
      let model = data;
      model.customerCare = model.customerCare || false;
      this.model = model;
      // Store the state separately
      this.initialState = this.model.status;

        this.http.get('/bikes-sql/api/v1/bike-statuses?changeableByDispatcher=true').subscribe(data => {
          let list = <Array<any>>data;
          if (!list.some(item => item['name'] === this.model.status)) {
              list.push({
                  name: this.model.status,
                  backendName: list.length ? list[0].backendName : null,
                  disabled: true
              })
          }

          this.translationServiceNative.get(list.map(item => 'bikeStatus.' + item['name'].toLowerCase()))
              .subscribe(localization => {
              list.forEach(status => {
                  status['text'] = localization['bikeStatus.' + status['name'].toLowerCase()];
                  this.statusList.push(status);
              });

              this.statusList.sort((a, b) => a['text'].localeCompare(b['text']));
          });
      });
    });
  }

  private close() {
    this.router.navigate(['/sec/bikeDispatch']);
  }

  private openLocations() {
    this.modal.open(CmModalLocationListComponent, overlayConfigFactory({ parent: this }, BSModalContext));
  }

  public selectLocation(location: CmLocation) {
    if(this.whoIsCalledLocationPopup === "ALLOWED_LOCATIONS") {
      this.allowedLocations.push(location);
    } else {
      this.model.location = new CmLocationData();
      this.model.location.locationId = location.locationId;
      this.model.location.locationName = location.fullName;
      this.model.coordinates = location.coordinates;
    }

    this.whoIsCalledLocationPopup = null;
  }

  private showMap() {
    this._showMap = !this._showMap;
  }

  private selectCoordinates(data) {
    this.model.coordinates = new CmCoordinates();
    this.model.coordinates.latitude = Math.round(data.coords.lat * 10000) / 10000;
    this.model.coordinates.longitude = Math.round(data.coords.lng * 10000) / 10000;
    this.model.location = null;
  }

  private getMarker() {
    return this.http.get(this.bikePath);
  }

  private save() {
    let model = {
      bike: this.model.bikeId,
      coordinates: this.model.coordinates,
      location: this.model.location ? this.model.location.locationId : null,
      newStatus: this.model.status,
      customerCare: this.model.customerCare
    };

    // Block or unblock bike on DB-Rent side if necessary
    let blockUnblockRequest = this.blockUnblockBike();
    let saveRequest = this.http.post('/script-support/api/v2/rules/run/acceptStatus', model);

    if(this.statusChanged){
      this.statusChanged = false;
      this.sendStatus();
    }

    let allowedIds = this.allowedLocations.map(l => l.locationId);
    this.dataService.saveBikeAllowed(this.model.bikeId, allowedIds)
        .subscribe(
            d => {
              if (blockUnblockRequest) {
                forkJoin([saveRequest, blockUnblockRequest]).subscribe(
                    data => {
                      this.modal
                          .alert()
                          .message(this.translationService.get('message.SaveMsg'))
                          .open();
                      // Store the new state as initial for next time
                      this.initialState = this.model.status;
                    },
                    error => {
                      console.log(error);
                      this.modal
                          .alert()
                          .message(error)
                          .open();
                    }
                );
              } else {
                saveRequest.subscribe(
                    data => {
                      this.modal
                          .alert()
                          .message(this.translationService.get('message.SaveMsg'))
                          .open();
                      // Store the new state as initial for next time
                      this.initialState = this.model.status;
                    },
                    error => {
                      console.log(error);
                      this.modal
                          .alert()
                          .message(error)
                          .open();
                    }
                );
              }
            },
            error => {
              this.modal
                  .alert()
                  .message(error)
                  .open();
            });
  }

  private blockUnblockBike(): Observable<any> {
    if (this.initialState !== this.model.status && this.statusList.length > 0 && this.statusList[0].backendName === 'HAL') {
      // DBREN-482: If a user sets a bike to sperr, we should block the bike in HAL via interface
      if (SPERR_HAL_STATUS === this.model.status) {
        return this.bikeBlockingService.blockBike(this.model.bikeId);
      }
      // DBREN-482: If a user sets a bike from sperr to ready or open, we should unblock the bike in HAL via interface
      if (SPERR_HAL_STATUS === this.initialState) {
        if (this.model.status && ('open' === this.model.status.toLowerCase() || 'ready' === this.model.status.toLowerCase())) {
          return this.bikeBlockingService.unblockBike(this.model.bikeId);
        }
      }
    }
    return null;
  }

  private getStation(station: string) {
    return this.dataService.getStationFromList(station, this.stationList);
  }

  private goToTT() {
    let ttFilters = new CmTroubleTicketFilters();
    ttFilters.bike = this.model.bikeNumber;
    ttFilters.save();
    this.router.navigate(['/sec/troubleTicket']);
  }

  private repairs() {
      let repairFilters = new CmBikeRepairFilters();
      repairFilters.bikeNumber = this.model.bikeNumber;
      repairFilters.save();
      this.router.navigate(['/sec/repair']);
  }

  private activate() {
    this.http
      .post('/bikes-sql/api/v1/bicycles/activate?bike=' + this.model.bikeId + '&active=' + !this.model.active, {})
      .subscribe(data => {
        this.modal
          .alert()
          .message(this.translationService.get('message.SaveMsg'))
          .open();
        this.loadData();
      });
  }

  private formatDate(date): string {
    return CmUtil.formatDate(date)
  }

  private sort(sortField) {
    if(this.lastAllowedLocationSortedProperty && this.lastAllowedLocationSortedProperty === sortField) {
      sortField = '-' + sortField;
      this.lastAllowedLocationSortedProperty = null;
    } else {
      this.lastAllowedLocationSortedProperty = sortField;
    }
    this.allowedLocations.sort(this.dynamicSort(sortField));
  }

  private dynamicSort(property) {
    let sortOrder = 1;
    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a,b) {
      let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  sendStatus() {
      if (this.model.status) {
          this.http
              .post('/bikes-sql/api/v1/bicycles/sendStatusChange', {bikeId: this.model.bikeId, status: this.model.status})
              .subscribe(data => {
                if(this.statusChanged) {
                  this.modal
                      .alert()
                      .message(this.translationService.get('message.SaveMsg'))
                      .open();
                }
              });
      }
  }


  private removeAllowedLocation(location: CmLocation) {
    this.allowedLocations = this.allowedLocations.filter(l => l.locationId !== location.locationId);
  }

  private addAllowedLocation() {
    this.whoIsCalledLocationPopup = "ALLOWED_LOCATIONS";
    this.modal.open(CmModalLocationListComponent, overlayConfigFactory({ parent: this }, BSModalContext));
  }

}
