import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmLocation, CmLocationsService} from "../../../services/cm-location.service";
import {CmHttpClient} from "../../../services/http_client";

@Component({
    selector: 'cm-location-filter',
    template: require('./cm-location-filter.component.html')
})
export class CmLocationFilterComponent implements OnInit {

    @Input() location: number = null;
    @Output() changeLocation: EventEmitter<number> = new EventEmitter<number>();

    private locationList: Array<CmLocation> = [];

    constructor(private locationService : CmLocationsService,
                private http: CmHttpClient) {
        locationService.masterDataLoadedNew$.subscribe(
            data => {
                let content = data.content;
                content.sort((a,b)=>a.fullName.localeCompare(b.fullName));
                this.locationList = content;
            }
        );
    }

    ngOnInit() {
        this.locationService.loadStationMDNew(1, 1000000); // also clearFilters() in CmJobListComponent
    }

    private _changeLocation() {
        this.changeLocation.next(this.location);
    }

    readRelatedLocations(district) {
        if(district) {
            this.http.get<Array<CmLocation>>('/bikes-sql/api/v1/locations/byDistrict?district='+district).subscribe(data => {
                let content = data;
                if (content) {
                    content.forEach(el => {
                        el.fullName = this.locationService.getFullName(el);
                    });
                }
                content.sort((a,b)=>a.fullName.localeCompare(b.fullName));
                this.locationList = content;
            });
        }
    }

}