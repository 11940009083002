import {Component, HostListener} from '@angular/core';
import {DialogRef} from "ngx-modialog";
import { BSModalContext, Modal } from 'ngx-modialog/plugins/bootstrap';
import {CmGlobalErrorHandler} from "../../services/cm-global-handler-service";

@Component({
    selector: 'cm-modal-message',
    template: require('./cm-modal-message-component.html'),
    styles: [require('./cm-modal-message-component.scss').toString()]
})
export class CmModalMessageComponent  {

    private showMore = false;
    private btnNameLong = "Extended info";
    private btnNameShort = "Short info";
    private btnName = this.btnNameLong;

    private msgName: string;
    private shortMsg: string;
    private extMsg: string;

    constructor(
        public dialog: DialogRef<CmModalMessageComponentContext>,
        private modal: Modal
    ) {
        this.msgName = dialog.context.msgName;
        this.shortMsg = dialog.context.shortMsg;
        this.extMsg = dialog.context.extMsg;

    }

    showAdditionalInfo(){
        this.showMore = !this.showMore;
        if(this.btnName === this.btnNameLong) {
            this.btnName = this.btnNameShort
        }else{
            this.btnName = this.btnNameLong
        }
    }

    private close() {
        this.dialog.close();
        CmGlobalErrorHandler.isShownCmModalMessageComponent = false;
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.key === "Escape") {
            CmGlobalErrorHandler.isShownCmModalMessageComponent = false;
            this.dialog.close();
        }
    }

}

export class CmModalMessageComponentContext extends BSModalContext {
    public msgName : string;
    public shortMsg: string;
    public extMsg  : string;
}
