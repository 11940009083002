import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmValidatable} from "../cm-validatable";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";

@Component({
    selector: 'cm-valid-number',
    template: require('./cm-validatable-numberfield.component.html')
})
export class CmValidatableNumberfieldComponent implements CmValidatable {

    @Input() validatableList: Array<CmValidatable>;
    @Input() value: number;
    @Input() max: number;
    @Input() minShow: number;

    @Input() readonly: boolean;


    @Output() setVal: EventEmitter<number> = new EventEmitter<number>();

    private valid: boolean = true;
    private errorText: Array<string> = [];

    constructor(private translate: CmTranslateService) {
    }

    ngOnChanges() {
        if (this.validatableList) {
            this.validatableList.push(this);
        }
    }

    public validate(): boolean {
        this.valid = true;
        this.errorText = [];
        if ((this.max || this.max === 0) && this.max < this.value) {
            this.valid = false;
            this.errorText.push(this.translate.get('message.ValidationMaxValuedMsg') + ' ' + this.max);
        }
        return this.valid;
    }

    private setValue() {
        this.setVal.next(this.value);
    }
}
