import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CmHttpClient} from "../../services/http_client";
import {CmBikeListItem} from "./cm-bike-list.model";

@Component({
    selector: 'cm-bikes',
    template: require('./cm-bike-list.component.html')
})
export class CmBikeListComponent implements OnInit {

    @Output() selectBike: EventEmitter<CmBikeListItem> = new EventEmitter<CmBikeListItem>();

    private rows: Array<CmBikeListItem> = [];

    private filterValue: string;
    private limit: number = 15;

    constructor(private http: CmHttpClient) {
    }

    ngOnInit() {
        this.http.get<Array<CmBikeListItem>>('/bikes-sql/api/v1/bicycles-short?page=1&limit=' + this.limit +
            (this.filterValue ? '&number=' + this.filterValue : '')).subscribe(
            (data:Array<CmBikeListItem>) => this.rows = data
        )
    }

    filterList() {
        if (this.filterValue.length > 1) {
            this.ngOnInit();
        }
    }

}