import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { map } from 'rxjs/operators';

@Injectable()
export class CmTranslateService {
  constructor(private translate: TranslateService) {}

  /*
   * Interpolated translations example:
   * 1) In translation file: 
   *   "say.hello": "Hello {{name}}, welcome to {{place}}"
   * 2) Call like this:
   *   this.translateService.get("say.hello", {name: "my friend", place: "Ireland"});
   */
  public get(key: string, interpolateParams?: Object): string {
    try {
      let ansv: string;
      this.translate.get(key).subscribe(data => { ansv=data;      });
      return ansv;
    } catch (e) {
      console.log(JSON.stringify(e));
      return key;
    }
  }
}
