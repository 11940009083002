import { CmEmailServiceService } from './../services/cm-email-service.service';
import {NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService } from "../services/cm-data.service";
import { CmGuardService } from "../services/guards/cm-guard.service";
import { CmHttpClient } from "../services/http_client";
import { CmAmazonPicturesService } from "../services/cm-amazon-picture.service";
import { CmLocalizationService } from "../services/localization/cm-localization.service";
import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService
} from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule} from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ModalModule } from "ngx-modialog";
import { BootstrapModalModule, Modal , bootstrap3Mode} from "ngx-modialog/plugins/bootstrap";
// import {  bootstrap3Mode } from "ngx-modialog/plugins/bootstrap/lib/modal";
// import { Ng2BootstrapModule } from "ng2-bootstrap";
import { NouisliderComponent } from "ng2-nouislider";
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import { DateTimePickerComponent } from "../components/datetimepicker/datetimepicker.component";
import { CmStationService } from "../services/cm-station.service";
import { CommonModule } from "@angular/common";
import { CmAssignComponent } from "../components/cm-assign/cm-assign.component";
import { PagerService } from "../services/cm-pager.service";
import { CmLocationsService } from "../services/cm-location.service";
import { CmTranslateService } from "../services/localization/cm-translate.service";
import { CmModalLocationListComponent } from "../components/cm-location/modal-window/cm-modal-location-list.component";
import { CmLocationListComponent } from "../components/cm-location/list/cm-location-list.component";
import { PagerComponent } from "../components/pager/pager.component";
import { CmBikeListComponent } from "../components/cm-bike-short-list/cm-bike-list.component";
import { CmModalBikeListComponent } from "../components/cm-bike-short-list/modal-window/cm-modal-bike-list.component";
import { CmSortableThComponent } from "../components/cm-sortable-th/cm-sortable-th.component";
import { CmLocationFilterComponent } from "../components/cm-filters/cm-location-filter/cm-location-filter.component";
import { CmBikeBrandingFilterComponent} from "../components/cm-filters/cm-bike-branding-filter/cm-bike-branding-filter.component";
import { CmDistrictFilterComponent } from "../components/cm-filters/cm-district-filter/cm-district-filter.component";
import { CmUsersFilterComponent } from "../components/cm-filters/cm-users-filter/cm-users-filter.component";
import { CmStatusFilterComponent } from "../components/cm-filters/cm-job-status-filter/cm-job-status-filter.component";
import { CmValidatableNumberfieldComponent } from "../components/cm-validatable/numberfield/cm-validatable-numberfield.component";
import { CmTableTotalComponent } from "../components/cm-table-total/cm-table-total.component";
import { CmTtCategoryFilterComponent } from "../components/cm-filters/cm-tt-category-filter/cm-tt-category-filter.component";
import { CmShowOnMapComponent } from "../components/cm-show-on-map/cm-show-on-map.component";
import { CmModalSparePartCatWindow } from "../components/cm-spare-parts-category/rename/cm-spare-part-cat-window.component";
import { CmAddSparePartCategoryComponent } from "../components/cm-spare-parts-category/add/cm-spare-part-category.component";
import { CmOrganizationService } from "../services/cm-organization.service";
import {CmSparePartListModalComponent} from "../components/cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import { CmUserWorkloadService } from "../services/cm-user-workload.service";
import { CmBikeBlockingService } from '../services/cm-bike-blocking.service';
import {CmAliveService} from "../services/cm-alive-service";
import {CmHourMinuteComponent} from "../components/cm-validatable/hour-minute/cm-hour-minute.component";
import {CmHourMinuteCountdownComponent} from "../components/cm-validatable/hour-minute-countdown/cm-hour-minute-countdown.component";
import {CmModalMessageComponent} from "../components/cm-modal-message/cm-modal-message-component";
import {CmGlobalErrorHandler} from "../services/cm-global-handler-service";
import {CmComponentTrackerService} from "../services/cm-component-tracker-service";
import {CmBikeStatusFilterComponent} from "../components/cm-filters/cm-bike-status-filter/cm-bike-status-filter.component";
import {NgxSpinnerModule} from "ngx-spinner";
import {CmBikeTypeFilterComponent} from "../components/cm-filters/cm-bike-type-filter/cm-bike-type-filter.component";
import {CmReportComponent} from "../components/cm-report/cm-report.component";

bootstrap3Mode();
@NgModule({
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot(
        {
          loader: {
            provide: TranslateLoader,
            useFactory: (http: CmHttpClient) => new CmLocalizationService(http),
            deps: [CmHttpClient]
          }
        }
    ),
    ModalModule.forRoot(),
    BootstrapModalModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ProgressbarModule.forRoot(),
    // Ng2BootstrapModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DataService,
    CmGuardService,
    CmHttpClient,
    CmAmazonPicturesService,
    CmLocalizationService,
    TranslateService,
    CmStationService,
    PagerService,
    CmLocationsService,
    CmTranslateService,
    CmOrganizationService,
    CmUserWorkloadService,
    CmBikeBlockingService,
    CmEmailServiceService,
    CmAliveService,
    CmComponentTrackerService,
    {
      provide: ErrorHandler,
      useClass: CmGlobalErrorHandler
    }

  ],
  declarations: [
    NouisliderComponent,
    DateTimePickerComponent,
    CmAssignComponent,
    CmLocationListComponent,
    CmModalLocationListComponent,
    CmBikeListComponent,
    CmModalBikeListComponent,
    CmSparePartListModalComponent,
    PagerComponent,
    CmSortableThComponent,
    CmLocationFilterComponent,
    CmBikeBrandingFilterComponent,
    CmDistrictFilterComponent,
    CmUsersFilterComponent,
    CmStatusFilterComponent,
    CmValidatableNumberfieldComponent,
    CmHourMinuteComponent,
    CmHourMinuteCountdownComponent,
    CmTableTotalComponent,
    CmTtCategoryFilterComponent,
    CmShowOnMapComponent,
    CmAddSparePartCategoryComponent,
    CmModalSparePartCatWindow,
    CmModalMessageComponent,
    CmBikeStatusFilterComponent,
    CmBikeTypeFilterComponent,
    CmReportComponent
  ],
  entryComponents: [
    CmAssignComponent,
    CmModalLocationListComponent,
    CmSparePartListModalComponent,
    CmModalBikeListComponent,
    CmModalSparePartCatWindow,
    CmModalMessageComponent
  ],
    exports: [
        TranslatePipe,
        TranslateModule,
        BsDatepickerModule,
        // BrowserAnimationsModule,
        TimepickerModule,
        RouterModule,
        BrowserModule,
        FormsModule,
        // HttpClientModule,
        // ModalModule,
        // BootstrapModalModule,
        // Ng2BootstrapModule,
        NouisliderComponent,
        DateTimePickerComponent,
        PagerComponent,
        CmSortableThComponent,
        CmLocationFilterComponent,
        CmDistrictFilterComponent,
        CmUsersFilterComponent,
        CmStatusFilterComponent,
        CmValidatableNumberfieldComponent,
        CmHourMinuteComponent,
        CmHourMinuteCountdownComponent,
        CmTableTotalComponent,
        CmTtCategoryFilterComponent,
        CmShowOnMapComponent,
        CmModalSparePartCatWindow,
        CmBikeStatusFilterComponent,
        CmBikeTypeFilterComponent,
        NgxSpinnerModule,
        CmReportComponent,
        CmBikeBrandingFilterComponent
    ]
})
export class CmSharedModule {}