import {Injectable} from "@angular/core";
import {CmAuthService} from "./cm-auth.service";
import {CmGuardService} from "./guards/cm-guard.service";
import {CmHttpClient} from "./http_client";

@Injectable()
export class CmAliveService {
    private loggedInValue: boolean;
    private aliveUrl = '/device-management/login/api/v1/alive';

    constructor(private authService: CmAuthService,
                private cmGuardService: CmGuardService,
                private http: CmHttpClient,
                private auth: CmAuthService) {
        let subAuth = this.authService.successLogin$.subscribe(
            data => {
                this.changeLoggedStatus(data);
            }
        );

        let thisSaved = this;
        setTimeout(function alive() {
            if(thisSaved.loggedInValue) {
                thisSaved.http.post(thisSaved.aliveUrl, {}).subscribe(
                    () => {},
                    error => {
                        console.log(error);
                        thisSaved.auth.logout();
                    });
            }
            setTimeout(alive, thisSaved.auth.alivePeriod);
        }, thisSaved.auth.alivePeriod);
    }

    private changeLoggedStatus(isLogged: boolean) {
        this.loggedInValue = isLogged;
    }

    init() {
        this.loggedInValue = this.cmGuardService.isUserTokenAlive();
        this.changeLoggedStatus(this.loggedInValue);
    }
}