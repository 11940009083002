import {Component} from "@angular/core";
import {DialogRef} from "ngx-modialog";
import {CustomModalContext} from "../../cm-assign/cm-assign.component";
import {CmBikeListItem} from "../cm-bike-list.model";

@Component({
    selector: 'cm-modal-bike-list',
    template: require('./cm-modal-bike-list.component.html'),
    styles: [require('./cm-modal-bike-list.component.scss').toString()]
})
export class CmModalBikeListComponent {
    parent = null;

    constructor(public dialog: DialogRef<CustomModalContext>) {
        this.parent = dialog.context.parent;
    }

    private close() {
        this.dialog.close();
    }

    private selectBike(bike: CmBikeListItem) {
        this.parent.selectBike(bike);
        this.close();
    }
}