import {Component, Input} from "@angular/core";
import {Router} from "@angular/router";
import {CmHttpClient} from "../../../services/http_client";
import {BSModalContext, Modal} from "ngx-modialog/plugins/bootstrap";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";
import {CmSparePartCategory} from "../cm-spare-part-category.model";
import {DialogRef} from "ngx-modialog";
import {TabTitle} from "../../../shared/tabTitle";

@Component({
    selector: 'cm-add-spare-part-category',
    template: require('./cm-spare-part-category.component.html'),
    styles: ['.cm-warn{border-color:red;}']
})
export class CmAddSparePartCategoryComponent extends TabTitle {

    @Input() modalDialog: DialogRef<RenameSparePartContext>;

    private category: CmSparePartCategory = new CmSparePartCategory(-1, null);
    private isSavePressed = false;

    constructor(private router: Router,
                private http: CmHttpClient,
                private modal: Modal,
                private translationService: CmTranslateService) {
        super('inventory.AddGroupBtn', translationService);
    }

    ngOnChanges() {
        if (this.modalDialog) {
            this.category = this.modalDialog.context.category;
        }
    }

    private save(isValid: boolean) {
        this.isSavePressed = true;
        if(isValid == false){
            // this.modal.alert().message(this.translationService.get('damageCategoryConfig.EmptyFieldLabel')).open();
            return;
        }
        let saveData = {
            number: this.category.number > 0 ? this.category.number : null,
            code: this.category.code
        };
        this.http.put('/bikes-sql/api/v1/category', saveData).subscribe(
            data => this.modal.alert()
                .message(this.translationService.get('message.SaveMsg'))
                .open()
                .result
                .then(dialog => dialog.result)
                .then(result => this.close())
                .catch(err => {
                }),
            error => this.modal.alert()
                .message(
                    this.translationService.get(
                        error.error?(error.error.errors?error.error.errors[0].defaultMessage:error.error.message):
                        error.message
                    )
                )
                .open()
        )
    }

    private close() {
        if (this.modalDialog) {
            this.modalDialog.context.parent.loadCategories();
            this.modalDialog.close();
        } else {
            this.router.navigate(['/sec/sparePart']);
        }
    }

    onNameChange(){
        this.category.code = this.category.code.trimLeft();
    }
}

export class RenameSparePartContext extends BSModalContext {
    public parent: any;
    public category: CmSparePartCategory;
}
