import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MarkerType } from '../../shared/marker';

@Component({
  selector: 'cm-show-on-map',
  template: require('./cm-show-on-map.component.html')
})
export class CmShowOnMapComponent {
  @Input()
  type: MarkerType;
  @Input()
  entityId: number;
  @Input()
  bikeId: number;

  constructor(private router: Router) {}

  private openMap() {
    this.router.navigate(['/sec/home'], this.createParams());
  }

  private createParams() {
    return {
      queryParams: {
        type: this.type,
        entity: this.entityId,
        bikeId: this.bikeId
      }
    };
  }
}
