import { Observable } from "rxjs/Observable";
import { CmHttpClient } from "./http_client";
import { Employee } from "./../components/cm-assign/cm-assign.component";
import { BikeJobListItem } from "./../components/cm-bike-job/cm-bike-job-list.model";
import { Injectable } from "@angular/core";

@Injectable()
export class CmUserWorkloadService {
  constructor(private http: CmHttpClient) {}

  public loadEmployeeWorkLoad(employee: Employee): Observable<number> {
    return this.http.get(`/bikes-sql/api/v1/tasks/repairTimeForUsers?user=${employee.login}`);
  }

  public calculateJobsWorkload(jobs: BikeJobListItem[]): number {
    jobs = jobs.filter(job => job.estimatedRepairTime);
    if (jobs && jobs.length > 0) return jobs.map(job => job.estimatedRepairTime).reduce((sum, repairTime) => sum + repairTime);
    return 0;
  }
}

export const EMPLOYEE_WORKLOAD_THRESHOLD: number = 600;
