import {Serializable} from "../../shared/serializable";
import {CmCoordinates} from "../../shared/coordinates";
import {CmLocationData} from "../../shared/location";

export class CmBikeDispatchModel extends Serializable {
    bikeId: number;
    bikeNumber: string;
    location: CmLocationData;
    status: string;
    lockType: string;
    type: string;
    coordinates: CmCoordinates;
    customerCare: boolean;
    lostBike: boolean;
    lock: string;
    lockVersion: string;
    lockVoltage: number;
    frame: string;
    bikeType: string;
    branding: string;
    //loadedTo is instead of station - see DBREN-241
    loadedTo: string;
    active : boolean;
}