import {Component, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs/Subscription";
import {TroubleTicket} from "../cm-trouble-ticket.model";
import {CmHttpClient} from "../../../services/http_client";
import {DataService} from "../../../services/cm-data.service";
// import {HttpResponse} from "@angular/common/http";
import {TableFilter} from "../../../shared/tableFilter";
import {overlayConfigFactory} from "ngx-modialog";
import {BSModalContext, Modal} from "ngx-modialog/plugins/bootstrap";
import {CmTroubleTicketComponent} from "../item/cm-trouble-ticket.component";
import * as moment from "moment/moment";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";
import {TabTitle} from "../../../shared/tabTitle";
import {BIKE_AND_LOCATION_TYPES, BIKE_TYPE, LOCATION_TYPE} from "../../../shared/const/missionType";
import {PagerComponent} from "../../pager/pager.component";
import {PageAdapter} from "../../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import {ReportExtractParam, ReportExtractParamField} from "../../../shared/reportExtractParam";
import {NgxSpinnerService} from "ngx-spinner";
import { SpinnerUtils } from '../../../shared/spinnerUtils';
import {Bike} from "../../../shared/bike";
import {CmReportComponent} from "../../cm-report/cm-report.component";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
    selector: 'cm-trouble-ticket-list',
    template: require('./cm-trouble-ticket-list.component.html'),
    styles: [require('../../cm-main/main.css').toString()]
})
export class CmTroubleTicketListComponent extends TabTitle implements OnInit {
    private tickets: TroubleTicket[] = [];

    private pageLimit: number = 15;
    private total: number;
    private totalExport = 10000;

    private subs: Array<Subscription> = [];

    //filters
    private filters: CmTroubleTicketFilters = new CmTroubleTicketFilters();
    private statusList = [];

    private missionTypes = [
        {key:BIKE_TYPE,              label: 'mission.bikeType'},
        {key:LOCATION_TYPE,          label: 'mission.locationType'},
        {key:BIKE_AND_LOCATION_TYPES,label: 'mission.bothType'}
    ];
    private currentPage: number = 1;

    private spType: string = SpinnerUtils.type;
    private spText: string = SpinnerUtils.text;
    private spColor: string = SpinnerUtils.color;
    private spBdColor: string = SpinnerUtils.bdColor;
    private spFullScreen: boolean = SpinnerUtils.fullScreen;
    private spSize: string = SpinnerUtils.size;

    private childProcessing: boolean = false;
    private reportType: string = "TT";
    private reportsListLen: Number = 0;
    private maxReportsListSize = 5;

    @ViewChild('archivedrep', {static: false}) archivedChild: CmReportComponent;

    @ViewChild(PagerComponent, {static: false})
    private pager: PagerComponent;

    constructor(private http: CmHttpClient,
                private dataService: DataService,
                private modal: Modal,
                public translate: CmTranslateService,
                private spinner: NgxSpinnerService
    ) {
        super('troubleTickets.Title', translate);
    }

    ngOnInit() {
        this.dataService.returnLocalizedMap('troubleTicketsStatus').subscribe(
            (data:any) => this.statusList = data.troubleTicketsStatus
        );

        this.missionTypes = this.missionTypes.map(e => {
            e.label = this.translate.get(e.label) ? this.translate.get(e.label) : e.label;
            return e;
        });

        this.loadData();
    }

    ngOnDestroy() {
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }

    private loadData() {
        this.spinner.show();
        this.http.get<PageAdapter<TroubleTicket>>('/bikes-sql/api/v1/tickets?page=' + (this.currentPage ? this.currentPage : 1) + '&limit=' + this.pageLimit +
            this.filters.toString()).subscribe(
            data => {
                this.spinner.hide();
                this.deserializeTickets(data);
                this.total = data.total || 0;
                if(this.total == 0){
                    this.modal.alert().message(this.translate.get("msg.NoResult")).open();
                }
            },
            error => {
                this.spinner.hide();
                console.log("Error: " + error);
                this.modal.alert().message("Error: " + error).open()
            }
        )
    }

    private deserializeTickets(data) {
        this.tickets = [];
        if (data.content) {
            data.content.forEach(el => {
                let ticket = new TroubleTicket().fromJSON(el);
                this.tickets.push(ticket);
            });
        }
    }

    private openTicket(ticket: TroubleTicket) {
        this.openTTModal({ticketId: ticket.id, missionType:ticket.type, parent: this});
    }

    private setPage(page: number) {
        this.currentPage = page;
        this.loadData();
    }

    private addNewBikeTicket(){
        if (this.filters.bike) {
            this.http.get<Bike>("/bikes-sql/api/v1/bicycle/number/" + this.filters.bike).subscribe(data => {
                this.openTTModal({ticketId: -1, bike: data, missionType:BIKE_TYPE, parent: this});
            }, error => {
                this.openTTModal({ticketId: -1, missionType:BIKE_TYPE, parent: this});
            })
        } else {
            this.openTTModal({ticketId: -1, missionType:BIKE_TYPE, parent: this});
        }
    }

    private addNewLocationTicket(){
        this.openTTModal({ticketId: -1, missionType:LOCATION_TYPE, parent: this});
    }

    private openTTModal(data) {
        this.modal.open(CmTroubleTicketComponent, overlayConfigFactory(data, BSModalContext));
    }

    private filter() {
        this.currentPage = 1;
        this.loadData();

        this.filters.save();
    }

    private clearFilters() {
        this.filters.clear();
    }

    private sort(field) {
        this.filters.sort(field);
        this.loadData();
    }

    private formatTimestamp(dateStr) {
        if (dateStr) {
            return moment(dateStr).format('DD.MM.YYYY HH:mm');
        }
        return '';
    }

    private substrText(text: string) {
        if (text) {
            if (text.length > 40) {
                return text.substr(0, 40) + '...';
            } else {
                return text;
            }
        }

        return '';
    }

    private reloadData() {
        this.loadData();
    }

    exportTickets() {
        if(this.reportsListLen >= this.maxReportsListSize){
            this.archivedChild.removeLastReportFromList();
        }

        let urlParams = this.filters.toString();
        if(urlParams.length > 0) {
            urlParams = '?' + urlParams.substring(1);
        }

        let reportFileName = this.translate.get("report.troubleTicket.fileName") ;
        let reportParams = new ReportExtractParam();
        reportParams.method = "GET";
        reportParams.path = '/bikes-sql/api/v1/tickets/export/withTask' + urlParams;

        let statusSubstitutions = {
            "Open": this.translate.get("report.troubleTicket.cell.Open"),
            "Closed": this.translate.get("report.troubleTicket.cell.Closed"),
            "InProgress": this.translate.get("report.troubleTicket.cell.InProgress")
        };
        reportParams.fields =
            [
                new ReportExtractParamField("id", this.translate.get("report.troubleTicket.header.id")),
                new ReportExtractParamField("complainTime", this.translate.get("report.troubleTicket.header.complainTime"), "dd.MM.yyyy HH:mm", "CET"),
                new ReportExtractParamField("ticketCreatedBy", this.translate.get("report.troubleTicket.header.ticketCreatedBy")),
                new ReportExtractParamField("dateCloseTicket", this.translate.get("report.troubleTicket.header.dateCloseTicket"), "dd.MM.yyyy HH:mm", "CET"),
                new ReportExtractParamField("ticketClosedBy", this.translate.get("report.troubleTicket.header.ticketClosedBy")),
                new ReportExtractParamField("dateCreatedTask", this.translate.get("report.troubleTicket.header.dateCreatedTask"), "dd.MM.yyyy HH:mm", "CET"),
                new ReportExtractParamField("whoCreatedTask", this.translate.get("report.troubleTicket.header.whoCreatedTask")),
                new ReportExtractParamField("dateFinishedTask", this.translate.get("report.troubleTicket.header.dateFinishedTask"), "dd.MM.yyyy HH:mm", "CET"),
                new ReportExtractParamField("whoClosedTask", this.translate.get("report.troubleTicket.header.whoClosedTask")),
                new ReportExtractParamField("reporter", this.translate.get("report.troubleTicket.reporter")),
                new ReportExtractParamField("ticketWhoCaused", this.translate.get("report.troubleTicket.header.ticketWhoCaused")),
                new ReportExtractParamField("ticketBooking", this.translate.get("report.troubleTicket.header.ticketBooking")),
                new ReportExtractParamField("priority", this.translate.get("report.troubleTicket.header.priority")),
                new ReportExtractParamField("bikeId", this.translate.get("report.troubleTicket.header.bikeId")),
                new ReportExtractParamField("bikeNumber", this.translate.get("report.troubleTicket.header.bikeNumber")),
                new ReportExtractParamField("bikeBranding", this.translate.get("report.troubleTicket.header.bikeBranding")),
                new ReportExtractParamField("ticketCoordinates", this.translate.get("report.troubleTicket.header.ticketCoordinates")),
                new ReportExtractParamField("category", this.translate.get("report.troubleTicket.header.category")),
                new ReportExtractParamField("issue", this.translate.get("report.troubleTicket.header.issue")),
                new ReportExtractParamField("locationId", this.translate.get("report.troubleTicket.header.locationId")),
                new ReportExtractParamField("locationName", this.translate.get("report.troubleTicket.header.locationName")),
                new ReportExtractParamField("status", this.translate.get("report.troubleTicket.header.status"), null, null, statusSubstitutions)
            ];

        this.dataService.getReportExtract2(reportParams, this.reportType ,reportFileName)
            .subscribe(
                data => {
                    this.childProcessing = true;
                    this.archivedChild.startProcessingProcess();

                },
                error => {
                            console.log("Error downloading the file.");
                });
    }



    userFormatTrip(firstTripInMinutes: number) {
        if(!firstTripInMinutes) return "";
        if(firstTripInMinutes == -1) return this.translate.get("report.troubleTicket.cell.Open");
        return CmTroubleTicketListComponent.convertMinutesToHhMmFormat(firstTripInMinutes);
    }

    public static convertMinutesToHhMmFormat(minutes: number): string {
        var h = Math.floor(minutes/60);
        var m = minutes - h*60;
        return (h > 0) ? h + 'h ' + m + 'm' : m + 'm';
    }

    canLoadXls():boolean {
        return CmGuardService.isOrganizationAdmin();
    }

    processingMessage(event: any) {
        this.childProcessing = event;
    }

    onListSize($event: Number) {
        this.reportsListLen = $event
        let i=0;
    }
}

export class CmTroubleTicketFilters extends TableFilter {
    id: number;
    bike: string;
    location: string;
    category: string;
    statuses: string[];
    type: string;
    dateFrom: string;
    dateTo: string;
    bikeTypes: string[];
    branding: string[];

    constructor() {
        super('ttList');
    }

    public toString(): string {
        let types = [];
        if(this.type == BIKE_AND_LOCATION_TYPES) {
            types.push(BIKE_TYPE);
            types.push(LOCATION_TYPE);
        } else if (this.type) {
            types.push(this.type);
        }

        let url: string = super.toString();
        if (this.id) {
            url += '&id=' + this.id;
        }
        if (this.bike) {
            url += '&bikeNumber=' + this.bike;
        }
        if (this.location) {
            url += '&location=' + this.location;
        }
        if (this.category) {
            url += `&category=${encodeURIComponent(this.category)}`;
        }
        if (this.statuses && this.statuses.length > 0) {
            url += '&statuses=' + this.statuses;
        }
        if (types && types.length > 0) {
            url += '&types=' + types;
        }
        if (this.branding && this.branding.length > 0) {
            url += '&bikeBranding=' + this.branding;
        }
        if (this.dateFrom) {
            url += `&dateFrom=${encodeURIComponent(this.dateFrom)}`;
        }
        if (this.dateTo) {
            url += `&dateTo=${encodeURIComponent(this.dateTo)}`;
        }
        if (this.bikeTypes && this.bikeTypes.length > 0) {
            url += '&bikeTypes=' + this.bikeTypes;
        }
        return url;
    }

    public clear() {
        super.clear();
        this.id = null;
        this.bike = null;
        this.location = null;
        this.category = null;
        this.statuses = [];
        this.type = null;
        this.bikeTypes = [];
        this.branding = [];
        this.resetDates();
        this.save();
    }

    private resetDates() {
        let currDate = new Date();
        this.dateTo = moment(currDate).format();
        this.dateFrom = moment(currDate.setMonth(currDate.getMonth() - 1)).format();
        this.dateFrom = null;
        this.dateTo = null;
    }
}
