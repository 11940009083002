import { CmOrganizationService } from './../../../services/cm-organization.service';
import { CmEmailServiceService } from './../../../services/cm-email-service.service';
import { Observable } from 'rxjs/Observable';
import { CmLocation } from '../../../services/cm-location.service';
import { CmUtil } from '../../../shared/cm-util';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TroubleTicket, TroubleTicketBike } from '../cm-trouble-ticket.model';
import { Router } from '@angular/router';
import { CmHttpClient } from '../../../services/http_client';
import { DialogRef, ModalComponent, overlayConfigFactory } from 'ngx-modialog';
import { BSModalContext, Modal } from 'ngx-modialog/plugins/bootstrap';
import { CmModalLocationListComponent } from '../../cm-location/modal-window/cm-modal-location-list.component';
import { CmTranslateService } from '../../../services/localization/cm-translate.service';
import { CmModalBikeListComponent } from '../../cm-bike-short-list/modal-window/cm-modal-bike-list.component';
import { CmBikeListItem } from '../../cm-bike-short-list/cm-bike-list.model';
import { CmGuardService } from '../../../services/guards/cm-guard.service';
import { CmCoordinates } from '../../../shared/coordinates';
import { BikesJobListFilters } from '../../cm-bike-job/list/cm-bike-job-list.component';
import * as moment from 'moment/moment';
import { DataService } from '../../../services/cm-data.service';
import { TabTitle } from '../../../shared/tabTitle';
import { BIKE_TYPE, LOCATION_TYPE } from '../../../shared/const/missionType';
import { CmAmazonPicturesService } from '../../../services/cm-amazon-picture.service';
import { CmTroubleTicketListComponent } from '../list/cm-trouble-tiket-list.component';
import {zip} from 'rxjs';
import {CmTripModel} from "./cm-trip-model";

@Component({
  selector: 'cm-trouble-ticket',
  template: require('./cm-trouble-ticket.component.html'),
  styles: [require('./cm-trouble-ticket.component.scss').toString()]
})
export class CmTroubleTicketComponent extends TabTitle implements OnInit, OnDestroy, ModalComponent<TTModalDialog> {
  private ticket: TroubleTicket = new TroubleTicket();
  private ticketId: number;
  private missionType: string;
  private types = [];
  private priorityList = [1, 2, 3];

  private isDataValid: boolean;

  private emailToCustomer: boolean;

  private emailToDispatcher: boolean;

  private trip: CmTripModel = new CmTripModel();

  private modelChanged: boolean = false;
  private isSavePressed = false;

  constructor(
    public dialog: DialogRef<TTModalDialog>,
    private http: CmHttpClient,
    private dataService: DataService,
    private router: Router,
    private translate: CmTranslateService,
    private modal: Modal,
    private emailService: CmEmailServiceService,
    private orgaService: CmOrganizationService,
    private amazonPictures: CmAmazonPicturesService
  ) {
    super('troubleTickets.Title', translate);

    this.ticketId = dialog.context.ticketId;
    this.missionType = dialog.context.missionType;

    if (dialog.context.bike) this.selectBike(dialog.context.bike);
    if (dialog.context.location) this.selectLocation(dialog.context.location);
  }

  ngOnInit() {
    this.dataService.returnLocalizedMap('troubleTicketsType').subscribe(
        (data:any) => (this.types = data.troubleTicketsType)
    );

    if (this.ticketId > 0) {
      this.http.get('/bikes-sql/api/v1/tickets/' + this.ticketId).subscribe(data => {
        let ticket = data;
        this.ticket = new TroubleTicket().fromJSON(ticket);
        if(this.ticket.bike) {
          this.ticket.bikes.push(
              new TroubleTicketBike(this.ticket.bike.bikeId, this.ticket.bike.bikeNumber, this.ticket.coordinates)
          );
        }
        this.loadTrip();
      });
    } else {
      this.ticket.id = -1;
    }

    // DBREN-485: Prefill "reporter" with "login" of user
    if (!this.ticket.reporter) this.ticket.reporter = CmGuardService.getUserName();
  }


  loadTrip():Observable<CmTripModel> {
    if(!this.ticket || !this.ticket.bike || !this.ticket.bike.bikeId || !this.ticket.time ) return;
    let url = '/bikes-sql/api/v1/bicycles/trip?bikeId='+this.ticket.bike.bikeId+'&after='+this.ticket.time;
    this.http.get(url).subscribe(t => {
      this.trip = new CmTripModel().fromJSON(t);
    });
  }

  ngOnDestroy() {
    // if (!this.dialog.destroyed) this.dialog.bailOut();
    try {
      this.dialog.bailOut();
    } catch (error) {
      console.error(error);
    }
  }

  public selectLocation(location: CmLocation) {
    this.ticket.location = new CmLocation();
    this.ticket.location.locationId = location.locationId;
    this.ticket.location.locationName = location.fullName;
  }

  public selectBike(bike: CmBikeListItem) {
    this.ticket.bike = bike;

    if(bike.bikeId) {
      this.http.get<TroubleTicket>('/bikes-sql/api/v1/bicycles/' + bike.bikeId).subscribe(data => {
        this.ticket.coordinates = new CmCoordinates();
        this.ticket.coordinates = data.coordinates;
        this.ticket.bikes.push(new TroubleTicketBike(bike.bikeId, bike.bikeNumber, data.coordinates));
      });
    }else if(bike.bikeNumber){
      this.http.get<TroubleTicket>('/bikes-sql/api/v1/bicycle/number/' + bike.bikeNumber).subscribe(data => {
        if(!data){
          return
        }
        this.ticket.coordinates = new CmCoordinates();
        this.ticket.coordinates = data.coordinates;
        this.ticket.bikes.push(new TroubleTicketBike(bike.bikeId, bike.bikeNumber, data.coordinates));
      });
    }

  }

  private setNoticeDate(value) {
    setTimeout( () => this.ticket.time = value );
  }

  private closeAndRefreshParent() {
    try {
      this.dialog.context.parent.reloadData.call(this.dialog.context.parent);
      this.dialog.close();
    } catch (error) {
      console.error(error);
    }
  }

  private close() {
    try {
      this.dialog.close();
    } catch (error) {
      console.error(error);
    }
  }

  private removeBike(row) {
    let index = this.ticket.bikes.indexOf(row, 0);
    if (index > -1) {
      this.ticket.bikes.splice(index, 1);
    }
  }

  submitPictures() {
    let pictureLinks = [];
    if (!this.ticket || !this.ticket.pictureNames || this.ticket.pictureNames.length < 1) return;
    let pictures = this.ticket.pictureNames;

    for (let i = 0; i < pictures.length; i++) {
      if (pictures[i]) {
        pictureLinks.push(this.amazonPictures.submitPicture(pictures[i]));
      }
    }

    return pictureLinks;
  }

  private prepareSaveRequest() {
    const url = '/script-support/api/v2/rules/run/saveComplain';
    // Save pics remotely and store the links
    // Why copy this.ticket?
    let troubleTicket: TroubleTicket = JSON.parse(JSON.stringify(this.ticket));

    // Allow changes on not closed tickets only
    if (this.isExisting(troubleTicket) && !['Open', 'InProgress'].some(status => status === troubleTicket.status)) {
      this.modal
          .alert()
          .message(this.translate.get('message.EditOnlyOpenTT'))
          .open();
      return;
    }
    troubleTicket.pictureNames = this.submitPictures();

    const saveRequest: Observable<any> = this.http.post(this.isBikeTicketType() ? `${url}/bike` : `${url}/location`, {
      complain: troubleTicket,
      emailToCustomer: this.emailToCustomer,
      emailToDispatcher: this.emailToDispatcher
    });

    return saveRequest;
  }

  private saveAndClose(isValid: boolean): any {
    this.isSavePressed = true;
    if(isValid == false){
      return;
    }
    let saveRequest = this.prepareSaveRequest();

    if (saveRequest) {
      let dialogRef = this.modal.alert()
          .message(this.translate.get('android.message.upload'))
          .okBtn(this.translate.get('android.button.cancel'))
          .open();
      saveRequest.subscribe(
        data => {
          dialogRef.close();

          this.modal
            .alert()
            .message(this.translate.get('message.SaveMsg'))
            .open()
              .result
            .then(result => this.closeAndRefreshParent())
            .catch(error => console.error(error))
        },
        error => {
          dialogRef.close();

          this.modal
              .alert()
              .message(error)
              .open()
        }
      );
    }
  }

  private isExisting(troubleTicket: TroubleTicket) {
    return troubleTicket.id != -1;
  }

  private openLocations() {
    this.modal.open(CmModalLocationListComponent, overlayConfigFactory({ parent: this }, BSModalContext));
  }

  private openBikes() {
    this.modal.open(CmModalBikeListComponent, overlayConfigFactory({ parent: this }, BSModalContext));
  }

  private linkToHal() {
    let linkToHal = () => {
      let baseUrl = localStorage.getItem('halUrl') || 'https://hal-prod.service.dbrent.net';
      let keyToHal = CmGuardService.getTokenParam('halKey') || '';
      let url =
          baseUrl +
          '/buze/auto_buch_vergangen.php?auto_id=' +
          (this.ticket.bike ? this.ticket.bike.bikeId : '') +
          '&goto_buchung=' +
          this.ticket.booking +
          '&key=' +
          keyToHal;
      window.open(url, 'blank');
    };
    if (this.modelChanged) {
      this.modelChangedConfirmDialog(linkToHal);
      return;
    } else {
      linkToHal();
    }
  }

  private modelChangedConfirmDialog(onYesListener) {
    this.modal.confirm()
        .okBtn(this.translate.get('common.yes')).okBtnClass('')
        .cancelBtn(this.translate.get('common.no')).cancelBtnClass('')
        .message(this.translate.get("troubleTickets.modelChanged"))
        .open().result.then(() => onYesListener(), () => {});
  }

  private turnIntoJob() {
    let turnIntoJob = () => {
      let url = '';
      // Predefined filter for the following page
      const bikeFilters: BikesJobListFilters = new BikesJobListFilters();
      bikeFilters.clear();
      switch (this.missionType) {
        case BIKE_TYPE:
          if (this.ticket.bike.bikeId) {
            url = `/bikes-sql/api/v1/tasks/check/${this.ticket.bike.bikeId}`;
            bikeFilters.bikeNumber = this.ticket.bike.bikeNumber;
          }
          break;
        case LOCATION_TYPE:
          if (this.ticket.location.locationId) {
            url = `/bikes-sql/api/v1/tasks/location/${this.ticket.location.locationId}`;
            bikeFilters.location = this.ticket.location.locationId.toString();
          }
          break;
        default:
          throw new Error(`Undefined missionType ${this.missionType}`);
      }

      // DBREN-481: First save ticket then turn into job
      // Execute later
      let saveRequest = this.prepareSaveRequest();
      let turnIntoJobRequest = this.http.post(url, {});

      zip(saveRequest, turnIntoJobRequest).subscribe(res => {
        turnIntoJobRequest.subscribe(res => {
          this.closeAndRefreshParent();
          this.modal
              .alert()
              .message(this.translate.get('taskCreation.Label'))
              .open()
              .result
              .then(dialog => dialog.result)
              .then(result => {
                if (this.dialog.context.parent instanceof CmTroubleTicketListComponent) {
                  const now = new Date(bikeFilters.dateCreatedTo);
                  bikeFilters.dateCreatedTo = moment(now.setMinutes(now.getMinutes() + 1)).format();
                  bikeFilters.save();
                  this.router.navigate(['/sec/bikeJobList']);
                }
              })
              .catch(error => console.error(error));
        });
      });
    };
    if (this.modelChanged) {
      this.modelChangedConfirmDialog(turnIntoJob);
      return;
    } else {
      turnIntoJob();
    }
  }

  private closeTicket() {
    let closeTicket = () => {
      this.http.put('/bikes-sql/api/v1/tickets/close/' + this.ticket.id, {}).subscribe(data => {
        this.modal
          .alert()
          .message(this.translate.get('message.CloseTicket'))
          .open()
            .result
          .then(dialog => dialog.result)
          .then(result => this.closeAndRefreshParent())
          .catch(error => console.error(error));
      });
    };
    if (this.modelChanged) {
      this.modelChangedConfirmDialog(closeTicket);
      return;
    } else {
      closeTicket();
    }
  }

  private formatTimestamp(dateStr) {
    return CmUtil.formatDate(dateStr);
  }

  private isBikeTicketType() {
    return (this.missionType && this.missionType == BIKE_TYPE) || this.ticket.type == BIKE_TYPE;
  }

  private isLocationTicketType() {
    return (this.missionType && this.missionType == LOCATION_TYPE) || this.ticket.type == LOCATION_TYPE;
  }

  private getMissionType(): string {
    return this.isLocationTicketType() ? LOCATION_TYPE : BIKE_TYPE;
  }

  private validate(): boolean {
    // Validate Bike-Ticket
    if (this.isBikeTicketType()) {
      if (this.ticket.bikes && this.ticket.bikes.length <= 0) return false;
      return true;
    }

    // Validate Location-Ticket
    if (this.isLocationTicketType()) {
      if (!this.ticket.location) return false;
      return true;
    }

    return true;
  }

  userFormatTrip(firstTripInMinutes: number) {
    if(!firstTripInMinutes) return "";
    if(firstTripInMinutes == -1) return this.translate.get("report.troubleTicket.cell.Open");
    return CmTroubleTicketListComponent.convertMinutesToHhMmFormat(firstTripInMinutes);
  }

  getTripStart() {
    if(!this.trip || (!this.trip.fromLocation && !this.trip.fromCoordinates)) return;
    return this.trip.fromLocation ? this.trip.fromLocation.locationName
        : this.trip.fromCoordinates.latitude + ' ' + this.trip.fromCoordinates.longitude;
  }

  getTripStop() {
    if(!this.trip || (!this.trip.toLocation && !this.trip.toCoordinates)) return;
    return this.trip.toLocation ? this.trip.toLocation.locationName
        : this.trip.toCoordinates.latitude + ' ' + this.trip.toCoordinates.longitude;
  }

  private categoryChanged(category) {
    this.ticket.category = category;
    this.modelChanged = true;
  }

  getAdditionalBikeCategory() {
    return this.ticket.category == 'Fahrrad kommuniziert nicht' ? this.ticket.category : null;
  }
}

export class TTModalDialog extends BSModalContext {
  public ticketId: number;
  public bike: CmBikeListItem;
  public missionType: string;
  public location: CmLocation;
  public parent: any;
}
