import {CmTranslateService} from "../services/localization/cm-translate.service";

export class TabTitle {

    constructor(public tabTitle: string,
                public trans: CmTranslateService) {
    }

    ngAfterViewChecked() {
        let title = this.trans.get(this.tabTitle);

        if (title && document.title !== title) {
            document.title = title;
        }
    }
}

export const CM_WEB_PORTAL = 'CM Web Portal';