import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {OutsideJob} from '../cm-job-list/cm-job.model';
import {CmHttpClient} from '../../services/http_client';
import {MarkerType} from '../../shared/marker';
import {Bike} from '../../shared/bike';
import {HttpHeaders as Headers} from '@angular/common/http';
import {READY_HAL_STATUS, READY_MSP_STATUS, SPERR_HAL_STATUS, SPERR_MSP_STATUS} from '../cm-map/cm-map.component';
import {CmTranslateService} from '../../services/localization/cm-translate.service';
import {BikeCluster} from '../../shared/bikeCluster';
import {ActivatedRoute} from '@angular/router';
import {EmployeeMap} from '../../shared/employee';
import {TabTitle} from '../../shared/tabTitle';

/**
 * Created by anikinma on 01.08.16.
 */
@Component({
  selector: 'cm-root-page',
  template: require('./cm-map-with-filters.component.html'),
  styles: [require('./cm-map-with-filters.component.scss').toString()]
})
export class CmRootPageComponent extends TabTitle {
  private filterModel: CmMapFiltersModel = new CmMapFiltersModel();
  private markerList = [];
  private jobList: Array<OutsideJob> = [];
  private bikesList = [];
  private employeeList = [];
  private bikesTotal: number = 0;

  private maxPointsCount: number = 100000;
  private bikeLimit: number = 100000;
  private stationLimits: number = 10000;

  private boundingBox: string;

  //data from URL
  private center: CmMapCenter;

  private scrHeight;

  @ViewChild('filtersContainer', {static: false})
  filtersContainer: ElementRef;

  private mapHeight: number = 500;

  private chargeSliderConfig = {
    start: [0, 100],
    step: 1,
    tooltips: true,
    range: {
      'min': 0,
      'max': 100
    }
  };

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.calculateMapHeight();
  }

  constructor(
    private http: CmHttpClient,
    private translateService: CmTranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super('dispatching.Title', translateService);

    this.activatedRoute.queryParams.subscribe(params => {
      // If entity is null, and bikeId is definded in parameters - show this bike.
      if (
        (!params['entity'] || params['entity'].toLowerCase() == 'null') &&
        (params['bikeId'] && params['bikeId'].toLowerCase() != 'null')
      ) {
        this.center = new CmMapCenter(params['bikeId'], MarkerType.BIKE);
      } else {
        this.center = new CmMapCenter(params['entity'], params['type']);
      }

      if (this.center.isFull()) this.openOnCenter();
    });

    this.getScreenSize();
  }

  ngOnInit() {
    setTimeout(() => {
      this.calculateMapHeight();
    }, 2000);
  }
  
  private calculateMapHeight(){
    if(!this.filtersContainer) return;
    let filtersHeight = (<HTMLElement>this.filtersContainer.nativeElement).getBoundingClientRect().height;

    let calculatedHeight = this.scrHeight - 120 - filtersHeight;
    if(calculatedHeight) this.mapHeight = calculatedHeight;
  }

  private reloadMarkers() {
    this.markerList = [];
    this.markerList = this.markerList
      .concat(this.jobList)
      .concat(this.bikesList)
      .concat(this.employeeList);
  }

  private updateMarkers($event){
    this.filterModel.stationFilters.assignments = $event;
    this.filterModel.bikeFilters.assignments = $event;
    this.updateBikeAndLocations();
  }

  //locations
  private loadLocations(filter, locationId?) {
    this.jobList = [];
    this.filterModel.showStation = filter;
    if (filter || locationId > 0) {
      let url = '/bikes-sql/api/v1/tasks/list?' +
          'page=1&limit=' +
          this.stationLimits +
          (this.filterModel.bikeFilters.branding.length > 0 ?
              '&bikeBranding=' + this.filterModel.bikeFilters.branding.join(',') : '') +
          (locationId ? '&location=' + locationId : '');
      this.http
        .get(url,
          { headers: this.createBoundHeader() }
        )
        .subscribe((data:any) => {
          let locationList = data.content;

          if (locationList.length !== this.jobList.length) {
            data.content.forEach(el => {
              let item = new OutsideJob().fromJSON(el);
              item.type = MarkerType.JOB;


              if(this.filterModel && this.filterModel.stationFilters && this.filterModel.stationFilters.assignments && this.filterModel.stationFilters.assignments.length > 0){
                const filterEmployees = this.filterModel.stationFilters.assignments;
                item.highlighted = false;
                for (let i = 0; i < item.assignedTo.length; i++) {
                  for (let j = 0; j < filterEmployees.length; j++) {
                    if(item.assignedTo[i] == filterEmployees[j]){
                      item.highlighted = true;
                      item.bordered = true;
                      break;
                    }
                  }
                }
              }


              if (item.coordinates) {
                this.jobList.push(item);
              }
            });
            this.reloadMarkers();
          }
        });
    } else {
      this.reloadMarkers();
    }
  }

  //Without additional clearing of jobList and reloadMarkers() invocation
  //markers can be shown incorrect with slow connection to DB
  private updateBikeAndLocations(){
    this.jobList = [];
    this.loadBikes();
    this.loadLocations(this.filterModel.showStation);
    this.reloadMarkers();
  }

  //bikes
  private loadBikes() {
    this.bikesList = [];
    this.filterModel.singleBike = null;
    let bikeFilters = this.filterModel.bikeFilters;
    if (bikeFilters.mustLoad()) {
      let url =
        '/bikes-sql/api/v1/bikeCluster?page=1&limit=' +
        this.bikeLimit +
        '&statuses=' +
        bikeFilters.statuses.join(',') +
          (this.filterModel.bikeFilters.branding.length > 0 ? '&bikeBranding='
              + this.filterModel.bikeFilters.branding.join(',') : '') +
        '&types=' +
        bikeFilters.types.join(',') +
        '&loaded=false';
      if (bikeFilters.filterGoal === CmBikeFilterGoal.bikesWithCheckTasks) {
        url += '&check=true';
      } else if (bikeFilters.filterGoal === CmBikeFilterGoal.bikesWithLogisticTasks) {
        url += '&logistic=true';
      } else if (bikeFilters.filterGoal == CmBikeFilterGoal.bikesWithTasks) {
        url += '&check=true&logistic=true';
      }
      if (bikeFilters.statusChangedHoursAgo) {
        url += '&statusChangedHoursAgo=' + bikeFilters.statusChangedHoursAgo;
      }
      if (bikeFilters.chargeInPercentFrom != null && bikeFilters.chargeInPercentTo != null) {
        url += '&chargeInPercentFrom=' + bikeFilters.chargeInPercentFrom + '&chargeInPercentTo=' + bikeFilters.chargeInPercentTo;
      }
      if (bikeFilters.isCharging() != null) {
        url += "&charging=" + bikeFilters.isCharging();
      }
      this.http
        .get<Array<BikeCluster>>(url, { headers: this.createBoundHeader() })
        .subscribe(data => {
          this.bikesList = [];
          if (data) {
            data.forEach(el => {
              let item;
              if (el.cnt) {
                item = new BikeCluster().fromJSON(el);
                item.type = MarkerType.BIKE_CLUSTER;
              } else {
                item = new Bike().fromJSON(el);
                item.type = MarkerType.BIKE;
              }
              if (item.coordinates) {
                this.bikesList.push(item);
              }
            });
          }
          this.performAdditionalFiltering();
          this.reloadMarkers();
        });
    } else {
      this.performAdditionalFiltering();
      this.reloadMarkers();
    }
  }

  private performAdditionalFiltering(){
    let filters = this.filterModel.bikeFilters;
    this.bikesList.forEach(bike => {
      if(filters && filters.assignments.length > 0){
        bike.highlighted = filters.assignments.indexOf(bike.user) > -1;
        bike.bordered = bike.highlighted;
      }
    });
  }

  private changeChargeLevel(value: number[]) {
    let [from, to] = value;
    if (from == 0 && to == 100) {
      from = null;
      to = null;
    }
    let bikeFilters = this.filterModel.bikeFilters;
    bikeFilters.chargeInPercentFrom = from;
    bikeFilters.chargeInPercentTo = to;
    this.loadBikes();
  }

  private showTotalBikes(data: any) {
    this.bikesTotal = data['total'];
  }

  //employees
  private loadEmployees(show) {
    this.employeeList = [];

    if (show) {
      this.http
        .get('/bikes-sql/api/v1/employee-coordinates')
        .subscribe((data:any) => {
          if (data.content) {
            data.content.forEach(el => {
              let item = new EmployeeMap().fromJSON(el);
              item.type = MarkerType.EMPLOYEE;
              this.employeeList.push(item);
            });
          }
          this.reloadMarkers();
        });
    } else {
      this.reloadMarkers();
    }
  }

  private changeBound(bound: string) {
    this.boundingBox = bound;
    if (this.filterModel.showStation) {
      this.loadLocations(true);
    }
    if (this.filterModel.bikeFilters.mustLoad() && !this.filterModel.singleBike) {
      this.loadBikes();
    }
  }

  private createBoundHeader() {
    let headers: Headers = new Headers();
    if (this.boundingBox) {
      headers=headers.append('X-GPS-BBox', this.boundingBox);
    }
    return headers;
  }

  private getEnterBikeNumberText() {
    return this.translateService.get('bikeNumber.hint');
  }

  private searchSignleBike() {
    this.bikesList = [];
    this.http
      .get('/bikes-sql/api/v1/bicycles-short?page=1&limit=1&number=' + this.filterModel.singleBike + '&loaded=false')
      .subscribe(data => {
        let dataParsed = data;
        if (dataParsed && dataParsed[0]) {
          this.http.get('/bikes-sql/api/v1/bicycles/' + dataParsed[0].bikeId).subscribe((data:any) => {
            let item = data;
            item.type = MarkerType.BIKE;
            this.http
                .get('/bikes-sql/api/v1/tasks/bikesJobList?bikeNumber='+this.filterModel.singleBike + '&status=InProgress,Open')
                .subscribe(
                    (data:any) => {
                      let tmpContent = data.content;
                      if(tmpContent && tmpContent[0]) {
                        item.priority = tmpContent[0].priority;
                      }
                      if (item.coordinates) {
                          this.bikesList.push(item);
                      }
                      this.reloadMarkers();
                    },
                    error => {
                      console.error(error);
                    }
                );
          });
        }
      });
  }

  private openOnCenter() {
    switch (this.center.type.toString()) {
      case MarkerType.BIKE.toString(): {
        this.bikesList = [];
        this.http.get<Bike>('/bikes-sql/api/v1/bicycles/' + this.center.id).subscribe(data => {
          let item = new Bike().fromJSON(data);
          item.type = MarkerType.BIKE;
          if (item.coordinates) {
            this.bikesList.push(item);
          }
          this.reloadMarkers();
        });
        break;
      }
      case MarkerType.JOB.toString(): {
        this.loadLocations(false, this.center.id);
        break;
      }
    }
  }


  private bikeFilterGoals() {
    return Object.keys(CmBikeFilterGoal);
  }

  private bikeFilterGoalLabel(filterGoalStr: string) {
    let filterGoal = CmBikeFilterGoal[filterGoalStr];
    switch (filterGoal) {
      case CmBikeFilterGoal.allBikes:
        return 'dispatching.ShowBikesLabel';
      case CmBikeFilterGoal.bikesWithTasks:
        return 'bikesWithJobs.Label';
      case CmBikeFilterGoal.bikesWithLogisticTasks:
        return 'bikesWithLogisticTasks.Label';
      case CmBikeFilterGoal.bikesWithCheckTasks:
        return 'dispatching.BikeWithCheckTasks';
      case CmBikeFilterGoal.noBikes:
        return 'dispatching.NoBikesLabel';
    }
  }
}

class CmMapFiltersModel {
  showStation: boolean;
  showServiceAgents: boolean;
  bikeFilters: CmBikeFilter;
  singleBike: string;
  stationFilters: CmLocationFilter;

  constructor() {
    this.showStation = false;
    this.showServiceAgents = false;
    this.bikeFilters = new CmBikeFilter(CmBikeFilterGoal.noBikes);
    this.stationFilters = new CmLocationFilter();
  }
}

export enum CmBikeFilterGoal {
  allBikes = 'allBikes',
  bikesWithTasks = 'bikesWithTasks',
  bikesWithLogisticTasks = 'bikesWithLogisticTasks',
  bikesWithCheckTasks = 'bikesWithCheckTasks',
  noBikes = 'noBikes'
}

export class CmLocationFilter {
  assignments : Array<string>;
  constructor() {
    this.assignments = [];
  }
}

export class CmBikeFilter {

  filterGoal: CmBikeFilterGoal;
  statuses: Array<string>;
  types: string[] = [];
  branding: Array<string> = new Array<string>();
  statusChangedHoursAgo: number;
  chargeInPercentFrom: number;
  chargeInPercentTo: number;
  charging: boolean;
  notCharging: boolean;
  assignments : Array<string> = [];

  constructor(filterGoal: CmBikeFilterGoal) {
    this.statuses = [SPERR_HAL_STATUS, SPERR_MSP_STATUS, READY_HAL_STATUS, READY_MSP_STATUS];
    this.filterGoal = filterGoal;
  }

  public mustLoad(): boolean {
    return this.filterGoal !== CmBikeFilterGoal.noBikes;
  }

  public isCharging(): boolean {
    return this.charging && !this.notCharging ? true : !this.charging && this.notCharging ? false : null;
  }
}

export class CmMapCenter {
  id: number;
  type: number;

  constructor(id: number, type: MarkerType) {
    this.id = id;
    this.type = type;
  }

  public isFull(): boolean {
    return this && this.id > 0 && this.type >= 0;
  }
}

class BikesTotals {
  status: string;
  count: number;

  constructor(status: string, cnt: number) {
    this.status = status;
    this.count = cnt;
  }
}
