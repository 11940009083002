import {Routes, RouterModule} from "@angular/router";
import {CmHome} from "./components/cm-main/cm-home.component";
import {CmLogin} from "./components/cm-login/cm-login.component";
import {mainRoutes} from "./components/cm-main/cm-home.routes";
import {CmGuardService} from "./services/guards/cm-guard.service";
import {ModuleWithProviders} from "@angular/core";
import {CmPageNotFoundComponent} from "./components/cm-error-page/cm-page-not-found.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sec',
    pathMatch: 'full'
  },
  {path : 'login', component: CmLogin},
  {path: 'sec',
    component: CmHome,
    children: [
      ...mainRoutes
    ],
    canActivate : [
      CmGuardService
    ]
  },
  { path: '**', component: CmPageNotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
