import {Component, OnInit, ViewChild} from '@angular/core';
import {OutsideJob} from '../cm-job.model';
import {Router} from '@angular/router';
import {CmHttpClient} from '../../../services/http_client';
import {Employee} from '../../cm-assign/cm-assign.component';
import {TableFilter} from '../../../shared/tableFilter';
import {TabTitle} from '../../../shared/tabTitle';
import {CmTranslateService} from '../../../services/localization/cm-translate.service';
import {CmLocationFilterComponent} from "../../cm-filters/cm-location-filter/cm-location-filter.component";
import {CmComponentTrackerService} from "../../../services/cm-component-tracker-service";
import {Modal, overlayConfigFactory} from "ngx-modialog";
import {BSModalContext, Modal as ModalDialog} from "ngx-modialog/plugins/bootstrap";
import {CmJobItemComponent} from "../item/cm-job-item.component";
import {NgxSpinnerService} from "ngx-spinner";
import {SpinnerUtils} from '../../../shared/spinnerUtils';
import {CmLocationsService} from "../../../services/cm-location.service";
import {BikeType} from "../../../shared/bike";
import * as moment from "moment";

@Component({
  selector: 'cm-job-list',
  template: require('./cm-job-list.component.html'),
  styles: [require('../../cm-main/main.css').toString(), require('./cm-job-list.component.scss').toString()]
})
export class CmJobListComponent extends TabTitle implements OnInit {
  private jobList: Array<OutsideJob> = [];
  private userList: Array<Employee> = [];

  private currentTimeStr = moment().format();

  private tableSummary;

  private pageLimit: number = 15;
  private total: number;
  private page: number = 1;

  private filtersSorters: JobListFiltersAndSorters = new JobListFiltersAndSorters();

  private spType: string = SpinnerUtils.type;
  private spText: string = SpinnerUtils.text;
  private spColor: string = SpinnerUtils.color;
  private spBdColor: string = SpinnerUtils.bdColor;
  private spFullScreen: boolean = SpinnerUtils.fullScreen;
  private spSize: string = SpinnerUtils.size;


  @ViewChild(CmLocationFilterComponent,{static:false})
  private locationFilter: CmLocationFilterComponent;

  constructor(private router: Router,
              private http: CmHttpClient,
              public translate: CmTranslateService,
              private modal: Modal,
              private componentTracker: CmComponentTrackerService,
              private spinner: NgxSpinnerService,
              private modalDialog: ModalDialog,
              private locationsService: CmLocationsService
) {
    super('outsideJob.Title', translate);

    this.http.get <Array<Employee>>('/user-information/api/v1/users').subscribe(data => {
      this.userList = Employee.sortUsers(data);
    });
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.spinner.show();
    this.http
      .get(
        '/bikes-sql/api/v1/tasks/list?' +
          'page=' +
          this.page +
          '&limit=' +
          this.pageLimit +
          this.filtersSorters.toString()
      )
      .subscribe((data:any) => {
        this.spinner.hide();
        this.jobList = data.content;
        this.total = data.total;
        this.tableSummary = data.sum;
        if(this.total == 0){
          this.modalDialog.alert().message(this.translate.get("msg.NoResult")).open();
        }
      }, error => {
        console.log('Error', error);
        this.spinner.hide();
        this.modalDialog.alert().message("Error: " + error);
      }  );
  }

  private openLocation(outsideJob: OutsideJob) {
    this.modal.open(CmJobItemComponent, overlayConfigFactory({jobId: outsideJob.location.locationId, branding: this.filtersSorters.branding}, BSModalContext))
  }

  private setPage(page: number) {
    this.page = page;
    this.loadData();
  }

  private sort(field) {
    this.filtersSorters.sort(field);
    this.loadData();
  }

  private filter() {
    this.loadData();
    this.filtersSorters.save();
  }

  private clearFilters() {
    this.filtersSorters.clear();
    this.locationsService.loadStationMDNew(1, 1000000 , this.filtersSorters.toString())  // like  CmLocationFilterComponent ngOnInit
  }

  changeDistrict($event) {
    this.filtersSorters.district = $event;
    this.filtersSorters.locations = null;
    this.locationFilter.readRelatedLocations($event);
  }

  private getBikeTypes() {
    return Object.keys(BikeType)
  }

  private getQuantity(item: OutsideJob, bikeType: string) {
    return item == null || item.quantities == null ? null : item.quantities[bikeType];
  }

}

class JobListFiltersAndSorters extends TableFilter {
  district: string;
  locations: string;
  priority: number;
  users: Array<string>;
  branding: Array<string>;

  constructor() {
    super('jobListFilters');
  }

  public clear() {
    super.clear();
    this.district = null;
    this.locations = null;
    this.priority = null;
    this.users = [];
    this.branding = [];
    this.save();
  }

  public toString() {
    let result = super.toString();
    if (this.priority) {
      result += '&priority=' + this.priority;
    }
    if (this.locations) {
      result += '&location=' + this.locations;
    }
    if (this.district) {
      result += '&district=' + this.district;
    }
    if (this.branding && this.branding.length > 0) {
      result += '&bikeBranding=' + this.branding;
    }
    if (this.users && this.users.length > 0) {
      result += '&users=' + this.users;
    }

    return result;
  }
}
