import { CmLocationsService } from './../../../services/cm-location.service';
import { Component, EventEmitter, Input, OnChanges, Output, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import {DialogRef, ModalComponent, overlayConfigFactory} from 'ngx-modialog';
import { BSModalContext, Modal } from 'ngx-modialog/plugins/bootstrap';
import { CmAssignComponent } from '../../cm-assign/cm-assign.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CmTranslateService } from '../../../services/localization/cm-translate.service';
import { CmHttpClient } from '../../../services/http_client';
import {BikeJobListItem, CmJobType} from '../../cm-bike-job/cm-bike-job-list.model';
import { CmTroubleTicketComponent } from '../../cm-trouble-ticket/item/cm-trouble-ticket.component';
import { TabTitle } from '../../../shared/tabTitle';
import { BIKE_TYPE, LOCATION_TYPE } from '../../../shared/const/missionType';
import { CmLocation } from '../../../services/cm-location.service';
import {PageAdapter} from "../../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import * as moment from "moment";
import {BikeType} from "../../../shared/bike";

@Component({
  selector: 'cm-job-item',
  template: require('./cm-job-item.component.html'),
  styles: [require('./cm-job-item.component.scss').toString()]
})
export class CmJobItemComponent extends TabTitle implements OnChanges, OnInit, OnDestroy, ModalComponent<CmJobListModalDialog> {
  @Input()
  locationName: string;
  @Input()
  locationId: number;
  @Input()
  bikeNumber: string;
  @Input()
  bikeId: number;
  @Input()
  bikeStatus: string;
  @Input()
  showClose: boolean = true;
  @Input()
  public branding: string[];

  @Output()
  closeJob: EventEmitter<any> = new EventEmitter();

  private jobItemList: Array<CmJobListItem> = [];
  private routeFrom: string;

  private checkAll: boolean = true;

  private inProgressOpenStatuses: string = 'InProgress,Open';

  private currentTimeStr = moment().format();

  constructor(
    private modal: Modal,
    private vcRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: CmHttpClient,
    public translate: CmTranslateService,
    public dialog: DialogRef<CmJobListModalDialog>,
    private locationService: CmLocationsService
  ) {
    super('outsideJob.Title', translate);
    // todo: 'defaultViewContainer' does not exist on type 'Overlay'
    // modal.overlay.defaultViewContainer = vcRef;

    this.branding = dialog.context.branding;
    this.locationId = dialog.context.jobId;
    this.reloadData();

    setInterval(() => {
      this.currentTimeStr = moment().format();
    }, 1000);
  }

  ngOnInit() {
    this.reloadData();
  }
  ngOnChanges() {
    this.reloadData();
  }

  ngOnDestroy() {
    try {
      // todo - 'defaultViewContainer' does not exist on type 'Overlay'
      //this.modal.overlay.defaultViewContainer.clear();
    } catch (error) {
      console.error(error);
    }
  }

  public reloadData() {
    let url = '/bikes-sql/api/v1/tasks/bikesJobList';
    if (this.locationId) url += `?location=${this.locationId}`;
    else if (this.bikeNumber) url += `?bikeNumber=${this.bikeNumber}`;
    if(this.branding && this.branding.length > 0) url += `&bikeBranding=${this.branding.join(',')}`;


    if (this.locationId || this.bikeNumber) {
      this.http.get<PageAdapter<CmJobListItem>>(`${url}&status=${this.inProgressOpenStatuses}`).subscribe(data => {
        let tempList = data.content;
        tempList.forEach(el => (el.checked = true));
        this.jobItemList = tempList;
      });
    }

    /*
     * DBREN-484: Display Station name in Details of Joblist
     * NOTE:
     * 1. All @Inputs are never filled because this component is only called by routes. This is a bug or it was changed.
     * 2. All routes so far call this component only with the locationId. So we can load the complete station data by its id.
     */
    this.loadLocation();
  }

  //DBREN-484: Display Station name in Details of Joblist
  private loadLocation() {
    if (this.locationId)
      this.http.get<CmLocation>(`/bikes-sql/api/v1/locations/${this.locationId}`).subscribe(data => {
        let location = data;
        if (location) this.locationName = this.locationService.getFullName(location);
      });
  }

  private closeJobItem() {
    this.dialog.close();
    this.closeJob.next(true);
  }

  private assign() {
    this.modal.open(CmAssignComponent, overlayConfigFactory({ parent: this }, BSModalContext));
  }

  private unassign() {
    let idArray = [];
    this.jobItemList.filter(el => el.checked === true).forEach(el => idArray.push(el.id));
    this.http.post('/bikes-sql/api/v1/tasks/unbooking', idArray).subscribe(data => this.reloadData());
  }

  private getText(jobItem: CmJobListItem): string {
    switch (jobItem.task) {
      case CmJobType[CmJobType.TO_MOVE]:
        return `${this.translate.get('bikesJobListType.list.TO_MOVE')}`;
      case CmJobType[CmJobType.TO_CHECK]:
        return `${this.translate.get('outsideJob.CheckLabel')} ${jobItem.bike.bikeNumber}`;
      case CmJobType[CmJobType.TO_CHARGE]:
        return `${this.translate.get('bikesJobListType.list.TO_CHARGE')} ${jobItem.bike.bikeNumber}`;
      case CmJobType[CmJobType.TO_WORKSHOP]:
        return `${jobItem.bike.bikeNumber} ${this.translate.get('outsideJob.ToWsLabel')}`;
      case CmJobType[CmJobType.FOR_LOCATION]:
        return `${this.translate.get('bikesJobListType.list.FOR_LOCATION')} ${this.locationName} (${this.locationId})`;
      case CmJobType[CmJobType.LOCATION]:
        return `${this.translate.get('bikesJobListType.list.LOCATION')}`;
    }
  }

  private getBikeTypes() {
    return Object.keys(BikeType)
  }

  private getQuantity(item: BikeJobListItem, bikeType: string) {
    return item == null || item.quantities == null ? null : item.quantities[bikeType];
  }

  private selectAll() {
    this.jobItemList.forEach(item => (item.checked = this.checkAll));
  }

  private isBikeValid(): boolean {
    return this.bikeId && this.bikeId > 0;
  }

  private isLocationValid(): boolean {
    return this.locationId && this.locationId > 0;
  }

  private addNewBikeTicket() {
    let bike = { bikeId: this.bikeId, bikeNumber: this.bikeNumber };
    this.modal.open(
      CmTroubleTicketComponent,
      overlayConfigFactory({ ticketId: -1, bike: bike, missionType: BIKE_TYPE, parent: this }, BSModalContext)
    );
  }

  private addNewLocationTicket() {
    let location = { locationId: this.locationId, fullName: this.locationName };
    this.modal.open(
      CmTroubleTicketComponent,
      overlayConfigFactory({ ticketId: -1, location: location, missionType: LOCATION_TYPE, parent: this }, BSModalContext)
    );
  }
}

class CmJobListItem extends BikeJobListItem {
  checked: boolean;
}

export class CmJobListModalDialog extends BSModalContext {
  public jobId: number;
  public branding: string[];
}
