export class ReportExtractParam {
    method: string;
    path: string;
    body: any;
    fields: Array<ReportExtractParamField>;
    paging: boolean;
}

export class ReportExtractParamField {
    name: string;
    label: string;
    pattern: string;
    timezone: string;
    substitutions: any;

    constructor(name: string, label?: string, pattern?: string, timezone?: string, substitutions?: any) {
        this.name = name;
        this.label = label;
        this.pattern = pattern;
        this.timezone = timezone;
        this.substitutions = substitutions;
    }
}

