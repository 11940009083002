import { CmAutoTicketListComponent } from "../cm-auto-ticket-config/list/cm-auto-ticket-config-list.component";
import { CmAutoTicketConfigComponent } from "../cm-auto-ticket-config/item/cm-auto-ticket-config.component";
import { Routes } from "@angular/router";
import { CmSettingsComponent } from "../cm-profile/settings/cm-settings.component";
import { CmRootPageComponent } from "../cm-main-map/cm-map-with-filters.component";
import { CmNoRolesComponent } from "../cm-no-roles/cm-no-role.component";
import { CmGuardService } from "../../services/guards/cm-guard.service";
import { CmJobListComponent } from "../cm-job-list/list/cm-job-list.component";
import { CmJobItemComponent } from "../cm-job-list/item/cm-job-item.component";
import { CmLocationListComponent } from "../cm-location/list/cm-location-list.component";
import { CmLocationItemComponent } from "../cm-location/item/cm-location-item.component";
import { CmTroubleTicketListComponent } from "../cm-trouble-ticket/list/cm-trouble-tiket-list.component";
import { CmTroubleTicketComponent } from "../cm-trouble-ticket/item/cm-trouble-ticket.component";
import { CmSparePartListComponent } from "../cm-spare-part/list/cm-spare-part-list.component";
import { CmMoveSparePartComponent } from "../cm-spare-parts-move/cm-move-spare-part.component";
import { CmAddSparePartCategoryComponent } from "../cm-spare-parts-category/add/cm-spare-part-category.component";
import { CmSparePartDetailComponent } from "../cm-spare-part/item/cm-spare-part-detail.component";
import { CmBikeJobListComponent } from "../cm-bike-job/list/cm-bike-job-list.component";
import { CmBikeJobItemComponent } from "../cm-bike-job/item/cm-bike-job-item.component";
import { CmJobListByDistrictComponent } from "../cm-job-list-disctrict-group/cm-job-list-by-district.component";
import { CmBikeDispatchComponent } from "../cm-bike-dispatch/list/cm-bike-dispatch.component";
import { CmBikeDispatchItemComponent } from "../cm-bike-dispatch/item/cm-bike-dispatch-item.component";
import { CmNewsComponent } from "../cm-news/cm-news.component";
import { CmRepairListComponent } from "../cm-repair/list/cm-repair-list.component";
import { CmRepairItemComponent } from "../cm-repair/item/cm-repair-item.component";
import {CmDamageBikeCatConfigComponent} from "../cm-damage-cat-bike-config/list/cm-damage-cat-bike-config-list.component";
import {CmDamageBikeCatConfigItemComponent} from "../cm-damage-cat-bike-config/item/cm-damage-cat-bike-config-item.component";
import {CmDamageLocationCatConfigComponent} from "../cm-damage-cat-location-config/list/cm-damage-cat-location-config-list.component";
import {CmDamageLocationCatConfigItemComponent} from "../cm-damage-cat-location-config/item/cm-damage-cat-location-config-item.component";

export const mainRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    component: CmRootPageComponent
  },
  {
    path: "joblist",
    children: [
      {
        path: "",
        component: CmJobListComponent
      },
      {
        path: ":id",
        component: CmJobItemComponent
      }
    ]
  },
  {
    path: "jobsDistrict",
    children: [
      {
        path: "",
        component: CmJobListByDistrictComponent
      }
    ]
  },
  {
    path: "bikeJobList",
    children: [
      {
        path: "",
        component: CmBikeJobListComponent
      },
      {
        path: ":id",
        component: CmBikeJobItemComponent
      }
    ]
  },
  {
    path: "locations",
    children: [
      {
        path: "",
        component: CmLocationListComponent
      },
      {
        path: ":id",
        component: CmLocationItemComponent
      }
    ]
  },
  {
    path: "troubleTicket",
    children: [
      {
        path: "",
        component: CmTroubleTicketListComponent
      },
      {
        path: ":id",
        component: CmTroubleTicketComponent
      }
    ]
  },
  {
    path: "autoTicket",
    children: [
      {
        path: "",
        component: CmAutoTicketListComponent
      },
      {
        path: ":id",
        component: CmAutoTicketConfigComponent
      }
    ]
  },
  {
    path: "sparePart",
    children: [
      {
        path: "",
        component: CmSparePartListComponent
      },
      {
        path: "addGroup",
        component: CmAddSparePartCategoryComponent
      },
      {
        path: "movePart",
        component: CmMoveSparePartComponent
      },
      {
        path: ":id",
        component: CmSparePartDetailComponent
      }
    ]
  },
  {
    path: "damageBikeCatConfig",
    children: [
      {
        path: "",
        component: CmDamageBikeCatConfigComponent
      },
      {
        path: ":id",
        component: CmDamageBikeCatConfigItemComponent
      }
    ]
  },
  {
    path: "damageLocationCatConfig",
    children: [
      {
        path: "",
        component: CmDamageLocationCatConfigComponent
      },
      {
        path: ":id",
        component: CmDamageLocationCatConfigItemComponent
      }
    ]
  },
  {
    path: "bikeDispatch",
    children: [
      {
        path: "",
        component: CmBikeDispatchComponent
      },
      {
        path: ":id",
        component: CmBikeDispatchItemComponent
      }
    ]
  },
  {
    path: "news",
    component: CmNewsComponent
  },
  {
    path: "no-roles",
    component: CmNoRolesComponent,
    canActivate: [CmGuardService]
  },
  {
    path: "settings",
    component: CmSettingsComponent,
    canActivate: [CmGuardService]
  },
  {
    path: "repair",
    children: [
      {
        path: "",
        component: CmRepairListComponent
      },
      {
        path: ":id",
        component: CmRepairItemComponent
      }
    ]
  }
];
