import { Component, OnInit } from "@angular/core";
import { overlayConfigFactory } from "ngx-modialog";
import { BSModalContext, Modal } from "ngx-modialog/plugins/bootstrap";
import { Subscription } from "rxjs/Subscription";
import { CmHttpClient } from "../../../services/http_client";
import { DataService } from "../../../services/cm-data.service";
import { CmTranslateService } from "../../../services/localization/cm-translate.service";
import { TableFilter } from "../../../shared/tableFilter";
import { CmAutoTicketConfig } from "../cm-auto-ticket-conifg.model";
import { CmAutoTicketConfigComponent } from "../item/cm-auto-ticket-config.component";
import { CmBaseAutoTicketConfigComponent } from "../cm-base-auto-ticket-config.component";
import {BIKE_AND_LOCATION_TYPES, BIKE_TYPE, LOCATION_TYPE} from "../../../shared/const/missionType";
import {PageAdapter} from "../../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import {SpinnerUtils} from "../../../shared/spinnerUtils";
import {NgxSpinnerService} from "ngx-spinner";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
  selector: "cm-auto-ticket-config-list",
  template: require("./cm-auto-ticket-config-list.component.html"),
  styles: [require("../../cm-main/main.css").toString()]
})
export class CmAutoTicketListComponent extends CmBaseAutoTicketConfigComponent implements OnInit {
  private pageLimit: number = 15;
  private total: number;
  private initPage: number = 1;

  private configs: CmAutoTicketConfig[] = [];
  private filters: CmAutoTicketConfigFilters = new CmAutoTicketConfigFilters();
  private prios = [1, 2, 3];
  private subs: Array<Subscription> = [];

  private missionTypes = [
    {key:BIKE_TYPE,              label: 'mission.bikeType'},
    {key:LOCATION_TYPE,          label: 'mission.locationType'},
    {key:BIKE_AND_LOCATION_TYPES,label: 'mission.bothType'}
  ];

  private spType: string = SpinnerUtils.type;
  private spText: string = SpinnerUtils.text;
  private spColor: string = SpinnerUtils.color;
  private spBdColor: string = SpinnerUtils.bdColor;
  private spFullScreen: boolean = SpinnerUtils.fullScreen;
  private spSize: string = SpinnerUtils.size;

  constructor(
    private http: CmHttpClient,
    private dataService: DataService,
    protected modal: Modal,
    protected translate: CmTranslateService,
    private spinner: NgxSpinnerService,
  ) {
    super("autoTicketsConfig.Title", modal, translate);
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  private loadData(showPage?: number) {
    this.spinner.show();
    let page = showPage || this.initPage;
    this.http
      .get<PageAdapter<CmAutoTicketConfig>>(
        `/bikes-sql/api/v1/auto-tickets-info?page=${page}&limit=${this.pageLimit}${this.filters.toHttpGet()}`
      )
      .subscribe(
        response => {
          this.configs = response.content;
          this.total = response.total || this.configs.length;
          this.spinner.hide();
          if(this.total == 0){
            this.modal.alert().message(this.translate.get("msg.NoResult")).open();
          }
        },
        error => {
          console.error(error);
          this.spinner.hide();
          this.showMessage(this.translationService.get(error.message));
        }
      );
  }

  private openConfig(config: CmAutoTicketConfig) {
    this.modal.open(CmAutoTicketConfigComponent, overlayConfigFactory({ config: config, parent: this }, BSModalContext));
  }

  private setPage(page: number) {
    this.loadData(page);
  }

  private addConfigForBike() {
    this.addConfig('BIKE');
  }

  private addConfigForLocation() {
    this.addConfig('LOCATION');
  }

  private addConfig(type: string) {
    let config = new CmAutoTicketConfig();
    config.bikes = [];
    config.type = type;
    this.modal.open(CmAutoTicketConfigComponent, overlayConfigFactory({ config: config, parent: this }, BSModalContext));
  }

  private deleteConfig(config: CmAutoTicketConfig) {
    this.http
      .delete(`/bikes-sql/api/v1/auto-tickets-info/${config.id}`)
      .subscribe(
        data => {
          this.showMessage(this.translationService.get("message.DeleteMsg"));
          this.loadData();
        },
        error => {
          console.error(JSON.stringify(error));
          this.showMessage(`Error: ${this.translationService.get(error.message)}`);
        }
      );
  }

  private reloadData() {
    this.loadData();
  }

  private filter() {
    this.filters.save();
    this.loadData();
  }

  private clearFilters() {
    this.filters.clear();
  }

  private sort(field) {
    this.filters.sort(field);
    this.loadData();
  }

  private substrText(text: string) {
    if (text) return text.length > 40 ? text.substr(0, 40) + "..." : text;
    return "";
  }

  private isLocationAutoTroubleTicket() {
    return this.filters.type === LOCATION_TYPE;
  }

  private isBikeAutoTroubleTicket() {
    return this.filters.type === BIKE_TYPE;
  }

  private changeType(type) {
    if (type == BIKE_TYPE) {
      this.filters.locationId = null;
    } else if (type == LOCATION_TYPE) {
      this.filters.bikeId = null;
      this.filters.bikeNumber = null;
    }
    this.filters.category = null;
    this.filters.type = type;
  }

  private allowedToEditSettings(): boolean {
    return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
  }

}

export class CmAutoTicketConfigFilters extends TableFilter {
  type: string;
  category: string;
  locationId: number;
  bikeId: number;
  bikeNumber: number;

  constructor() {
    super("autoTicketConfigList");
  }

  public toHttpGet(): string {
    let types = [];
    if(this.type == BIKE_AND_LOCATION_TYPES) {
      types.push(BIKE_TYPE);
      types.push(LOCATION_TYPE);
    } else if (this.type) {
      types.push(this.type);
    }

    let url: string = super.toString();
    if (this.bikeId) {
      url += '&bikeId=' + this.bikeId;
    }
    if (this.bikeNumber) {
      url += '&bikeNumber=' + this.bikeNumber;
    }
    if (this.locationId) {
      url += '&locationId=' + this.locationId;
    }
    if (this.category) {
      url += `&category=${encodeURIComponent(this.category)}`;
    }
    if (types && types.length > 0) {
      url += '&types=' + types;
    }
    return url;
  }

  public clear() {
    super.clear();
    this.type = null;
    this.category = null;
    this.locationId = null;
    this.bikeId = null;
    this.bikeNumber = null;
    this.save();
  }
}
