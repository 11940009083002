import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PagerService} from "../../services/cm-pager.service";

@Component({
    selector: 'pager',
    template: require('./pager.component.html'),
    styles: ['.cursCm{cursor:pointer}']
})
export class PagerComponent {

    @Input() total = 15;
    @Input() pageLimit = 15;
    @Input() pageNo : number = 1;
    @Output() page: EventEmitter<number> = new EventEmitter<number>();
    @Output() pager: EventEmitter<any> = new EventEmitter<any>();

    private _pager;

    constructor(private pagerService: PagerService) {
    }

    ngOnChanges() {
        this._pager = this.pagerService.getPager(this.total, 1, this.pageLimit,true);
        this.setPage(this.pageNo)
    }

    private setPage(page: number) {
        if (page >= 1 && (page -1) < this._pager.totalPages && page != this._pager.currentPage) {
            this._pager = this.pagerService.getPager(this.total, page, this.pageLimit, false);
            this.page.next(page);
            this.pager.next(this._pager);
        }
    }

    public skipPage(): void {
        this.setPage(1);
    }

    private setLastPage( current:number) {
        if (current  != this._pager.totalPages) {
            this.setPage(this._pager.totalPages);
        }
    }
    private setFirstPage( current:number) {
        if (current  != 1) {
            this.setPage(1);
        }
    }
    private setNextPage( current:number , next : number) {
        if (current  != next) {
            this.setPage(next);
        }
    }

}