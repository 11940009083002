import {Component} from '@angular/core';
import {DialogRef} from "ngx-modialog";
import {RenameSparePartContext} from "../add/cm-spare-part-category.component";

@Component({
    selector: 'cm-spare-part-cat-window',
    template: require('./cm-spare-part-cat-window.component.html'),
    styles : [require('./cm-spare-part-cat-window.component.scss').toString()]
})
export class CmModalSparePartCatWindow {
    constructor(public dialog: DialogRef<RenameSparePartContext>) {
    }
}