import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CmAuthService} from "../../services/cm-auth.service";
import {Subscription} from "rxjs/Subscription";
import {CM_WEB_PORTAL} from "../../shared/tabTitle";

@Component({
    selector: 'login',
    template: require('./cm-login.component.html'),
    styles: [require('./cm-login.component.scss').toString()],
})
export class CmLogin implements OnDestroy {

    private errorText: string;

    private subs: Array<Subscription> = [];

    constructor(public router: Router, public http: HttpClient, private authService: CmAuthService) {
        document.title = CM_WEB_PORTAL;
        let subAuth = this.authService.errorTextChanges$.subscribe(
            data => {
                this.errorText = data;
            }
        );

        this.subs.push(subAuth);
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    login(event, username, password) {
        event.preventDefault();
        this.authService.login(username, password);
    }
}
