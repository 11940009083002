import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { CmLocation, CmLocationsService } from '../../../services/cm-location.service';
import { TableFilter } from '../../../shared/tableFilter';
import { TabTitle } from '../../../shared/tabTitle';
import { CmTranslateService } from '../../../services/localization/cm-translate.service';
import {CmGuardService} from "../../../services/guards/cm-guard.service";
import {DataService} from "../../../services/cm-data.service";
import { Modal } from 'ngx-modialog/plugins/bootstrap';
import {CmUtil} from "../../../shared/cm-util";
import * as moment from "moment";
import {CmHttpClient} from "../../../services/http_client";
import {CmComponentTrackerService} from "../../../services/cm-component-tracker-service";
import {NgxSpinnerService} from "ngx-spinner";
import { SpinnerUtils } from '../../../shared/spinnerUtils';

@Component({
  selector: 'cm-locations',
  template: require('./cm-location-list.component.html'),
  styles: [require('../../cm-main/main.css').toString()]
})
export class CmLocationListComponent extends TabTitle implements OnInit, OnDestroy {
  private locationList: Array<CmLocation> = [];
  private csvFile;
  private csvFileName: string;
  private totalExport = 10000;


    private filter: LocationFilters = new LocationFilters();

  private pageLimit: number = 15;
  private total: number;
  private page: number = 1;

  private sub: Subscription;

  private spType: string = SpinnerUtils.type;
  private spText: string = SpinnerUtils.text;
  private spColor: string = SpinnerUtils.color;
  private spBdColor: string = SpinnerUtils.bdColor;
  private spFullScreen: boolean = SpinnerUtils.fullScreen;
  private spSize: string = SpinnerUtils.size;

    @Input() parent = null;
  @Output() selectLocation: EventEmitter<CmLocation> = new EventEmitter<CmLocation>();

  constructor(
    private modal:Modal,
    private http: CmHttpClient,
    private router: Router,
    private locationsService: CmLocationsService,
    public translate: CmTranslateService,
    private dataService: DataService,
    private componentTracker: CmComponentTrackerService,
    private spinner: NgxSpinnerService,
  ) {
    super('jobList.LocationsLabel', translate);

  }

    ngOnDestroy(): void {
        if(this.sub) {
            this.sub.unsubscribe();
        }
    }

  ngOnInit() {
      if(!this.sub) {
          this.sub = this.locationsService.masterDataLoadedNew$.subscribe(data => {
                  this.spinner.hide();
                  this.locationList = data.content;
                  this.total = data.total;
                  if (this.total == 0) {
                      this.modal.alert().message(this.translate.get("msg.NoResult")).open();
                  }
              },
              error => {
                  this.spinner.hide();
                  console.log("error:  " + +error)
              }
          );
      }
      let lastPage = this.componentTracker.readLastParentPageForCurrentComponentIfExist()
      if(lastPage)
          this.page = lastPage;
      this.loadData();
    this.csvFileName = this.translate.get('chooseFileToImport.label') || 'chooseFileToImport.label' ;
  }

  private loadData() {
          setTimeout(() => this.spinner.show());

          if(this.allowedToEditSettings() && this.filter.statuses.length != 2){
              this.filter.statuses.push(this.locationsService.STATUS_INACTIVE, this.locationsService.STATUS_ACTIVE);
          }
          this.locationsService.loadStationMDNew(this.page, this.pageLimit, this.filter.toString())
  }

  private openLocation(location: CmLocation) {
    if (this.parent) {
      this.selectLocation.emit(location);
    } else {
        this.componentTracker.goToDetailsFromParentPage(this.page);
        this.router.navigate(['/sec/locations', location.locationId]);
    }
  }

  private setPage(page: number) {
    this.page = page;
    this.loadData();
  }

  private sort(field) {
    this.filter.sort(field);
    this.loadData();
  }

  private allowedToEditSettings(): boolean {
      return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
  }

  private uploadFileChoose(event) {
        let file = event.target.files[0];
        if (!file) return;

        this.csvFileName = file.name;
        let reader = new FileReader();
        let thisContext = this;

        reader.addEventListener("load",
            function () {
                thisContext.csvFile = reader.result;
            }, false);

        reader.readAsText(file);
  }

  private showMessage(message:any) {
      this.modal.alert()
          .message(
              message.error? (message.error.message ? (message.error.message) : message.error): message
          )
          .open();
  }

}

class LocationFilters extends TableFilter {
  like: string;
  statuses: Array<string>;

  constructor() {
    super('locationListFilters');
    this.statuses = new Array<string>();
  }

  public clear() {
    super.clear();
    this.like = null;
    this.save();
  }

  public toString() {
    let result = super.toString();
    if (this.like) result += '&like=' + this.like;
    if (this.statuses) result += '&statuses=' + this.statuses;

    return result;
  }

}
