import {Component, Input} from '@angular/core';

@Component({
    selector: 'cm-sortable',
    template: require('./cm-sortable-th.component.html'),
    styles: [require('./cm-sortable-th.component.scss').toString()]
})
export class CmSortableThComponent {

    @Input() label: string;
    @Input() sort: boolean = false;
    @Input() state: string;

    constructor() {
    }

    private isSortAsc() {
        return this.sort && this.state === ASC_STRING;
    }

    private isSortDesc() {
        return this.sort && this.state === DESC_STRING;
    }

    private isNoSort() {
        return !this.sort || this.state !== ASC_STRING && this.state !== DESC_STRING;
    }
}

export const ASC_STRING = 'ASC';
export const DESC_STRING = 'DESC';