import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import { CmHttpClient } from "../../../services/http_client";
import {DAMAGE_CAT_BIKE_GLOBAL_KEY} from "../../cm-damage-cat-bike-config/list/cm-damage-cat-bike-config-list.component";
import {BIKE_AND_LOCATION_TYPES, BIKE_TYPE, LOCATION_TYPE} from "../../../shared/const/missionType";
import {DAMAGE_CAT_LOCATION_GLOBAL_KEY} from "../../cm-damage-cat-location-config/list/cm-damage-cat-location-config-list.component";
import {CmDamageLocationCategoryModel} from "../../cm-damage-cat-location-config/cm-damage-cat-location.model";
import {CmDamageBikeCategoryModel} from "../../cm-damage-cat-bike-config/cm-damage-cat-bike.model";

@Component({
  selector: "cm-tt-category-filter",
  template: require("./cm-tt-category-filter.component.html")
})
export class CmTtCategoryFilterComponent implements OnInit, OnChanges {
  @Input() category: string = null;
  @Input() showLabel: boolean = true;
  @Input() type: string;
  @Input() additionalBikeCategory: string;
  @Input() readonly : boolean;
  @Output() changeCategory: EventEmitter<string> = new EventEmitter<string>();

  private list: Array<string> = [];
  private currentType:string;

  private bikeTypeList: Array<string>;
  private locationTypeList: Array<string>;
  private bothTypeList: Array<string>;

  constructor(private http: CmHttpClient) {}

  ngOnInit() {
    this.loadValues();
  }

  private loadValues():void {
    this.http.get<Array<CmDamageLocationCategoryModel>>('/bikes-sql/api/v1/damage-categories/for_location').subscribe(data => {
      let list = [];
      let elements = data;
      for (let key in elements) {
        if (key !== DAMAGE_CAT_BIKE_GLOBAL_KEY && key !== DAMAGE_CAT_LOCATION_GLOBAL_KEY) list.push(key);
      }

      list.sort((a: string, b: string) => a.localeCompare(b));
      this.locationTypeList = list;
      this.createBothList();
    });

    this.http.get<Array<CmDamageBikeCategoryModel>>('/bikes-sql/api/v1/damage-categories/for_bike').subscribe(data => {
      let list = [];
      let elements = data;
      for (let key in elements) {
        if (key !== DAMAGE_CAT_BIKE_GLOBAL_KEY && key !== DAMAGE_CAT_LOCATION_GLOBAL_KEY) list.push(key);
      }

      list.sort((a: string, b: string) => a.localeCompare(b));
      this.bikeTypeList = list;
      this.createBothList();
    });

    this.changeType();
  }

  ngOnChanges() {
    this.changeType();
  }

  private _changeCategory() {
    this.changeCategory.next(this.category);
  }

  private createBothList() {
    if(this.additionalBikeCategory && this.bikeTypeList) this.bikeTypeList.push(this.additionalBikeCategory);
    if(!this.locationTypeList || !this.bikeTypeList) return;
    let list = this.locationTypeList.concat(this.bikeTypeList);
    list.sort((a:string, b:string) => a.localeCompare(b));
    this.bothTypeList = list;
    this.changeType();
  }

  private changeType() {
    if(!this.type) this.type = BIKE_AND_LOCATION_TYPES;
    if(this.type == this.currentType) return;
    if(!this.locationTypeList || !this.bikeTypeList || !this.bothTypeList) return;

    switch(this.type) {
      case LOCATION_TYPE: this.list = this.locationTypeList; break;
      case BIKE_TYPE: this.list = this.bikeTypeList; break;
      default: this.list = this.bothTypeList;
    }

    this.currentType = this.type;
  }
}
