import { CmTranslateService } from './../../services/localization/cm-translate.service';
import { CmHttpClient } from './../../services/http_client';
import { Component, EventEmitter, Output } from '@angular/core';
import { CmAuthService } from '../../services/cm-auth.service';
import { CmGuardService } from '../../services/guards/cm-guard.service';
import { Modal } from 'ngx-modialog/plugins/bootstrap';

@Component({
  selector: 'cm-profile',
  template: require('./cm-profile.component.html'),
  styles: [require('./cm-profile.component.scss').toString()]
})
export class CmProfileComponent {
  isDropdown: boolean;
  @Output()
  notifyUrl: EventEmitter<any> = new EventEmitter();

  private readonly htmlTemplateUrl = '/master-data/api/v1/bin/clientFiles/privacy-statement-de.html';

  private userName: string = 'iCheck';

  constructor(
    private authService: CmAuthService,
    private modal: Modal,
    private http: CmHttpClient,
    private translate: CmTranslateService
  ) {
    this.isDropdown = false;
    this.userName = CmGuardService.getUserName();
  }

  displayPrivacyStatement() {
    // The content is stored in a html template remotely
    this.http.get(this.htmlTemplateUrl,{responseType: "text"}).subscribe(
        (template:any) =>
        this.modal
          .alert()
          .title(this.translate.get('menu.PrivacyStatementLabel'))
          .body(template)
          .open(),
      error =>
        this.modal
          .alert()
          .title('Error')
          .body(`${JSON.stringify(error)}`)
          .open()
    );

    this.isDropdown = false;
  }

  clickSettings() {
    this.notifyUrl.emit(['CmSettingsComponent']);
    this.isDropdown = false;
  }

  clickProfile() {
    this.isDropdown = !this.isDropdown;
  }

  logout() {
    this.authService.logout();
  }
}
