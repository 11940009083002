import {forwardRef, Inject, Injectable} from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CmAuthService } from "../cm-auth.service";

@Injectable()
export class CmGuardService implements CanActivate {
  jwtHelper: JwtHelperService = new JwtHelperService();

  public static delimiter: string = "::";

  constructor(public router: Router,
              @Inject(forwardRef(() => CmAuthService)) private authService: CmAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token !== null && token.access_token) {
      if (this.jwtHelper.isTokenExpired(token.access_token)) {
        //if access token is expired, try to refresh it with refresh token
        if (token.refresh_token) {
          this.authService.refreshToken(token.refresh_token, state.url);
          return false;
        }

        //we can't refresh so go to login page
        this.router.navigate(["/login"]);
        return false;
      }
      return true;
    }

    //if no return then we don't have valid token, so redirect to login page
    this.router.navigate(["/login"]);

    return false;
  }

  public isUserTokenAlive() {
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token !== null && token.access_token) {
      if (this.jwtHelper.isTokenExpired(token.access_token)) {
        //if access token is expired, try to refresh it with refresh token
        if (token.refresh_token) {
          this.authService.refreshToken(token.refresh_token, null);
          return false;
        }

        //we can't refresh so go to login page
        this.router.navigate(["/login"]);
        return false;
      }
      return true;
    }

    //if no return then we don't have valid token, so redirect to login page
    this.router.navigate(["/login"]);

    return false;
  }

  public static isUserInRole(role: string): boolean {
    let token = JSON.parse(localStorage.getItem("authToken"));

    if (token && token.access_token) {
      let jwtHelper: JwtHelperService = new JwtHelperService();
      let decodedToken = jwtHelper.decodeToken(token.access_token);

      if (decodedToken.authorities) {
        return decodedToken.authorities.some(roleItem => roleItem === role);
      }
    }

    return false;
  }

  public static isOrganizationAdmin(): boolean {
    return this.isUserInRole(ORGANIZATION_ADMIN_ROLE);
  }

  public static isStationManager(): boolean {
    return this.isUserInRole(STATION_MANAGEMENT_ROLE);
  }

  public static getUserName(): string {
    let token = JSON.parse(localStorage.getItem("authToken"));

    if (token && token.access_token) {
      let jwtHelper: JwtHelperService = new JwtHelperService();
      let decodedToken = jwtHelper.decodeToken(token.access_token);

      if (decodedToken.user_name) {
        return decodedToken.user_name;
      }
    }

    return "anonymous";
  }

  public static getUserOrganizationFromToken() {
    let decodedToken = this.getDecodedToken();
    if (decodedToken && decodedToken.org) {
      return decodedToken.org;
    }
    return null;
  }

  public static getStationList(): Array<string> {
    let result = ["Organization"];
    let roles = this.getAuthorities();
    for (let role of roles) {
      if (role.indexOf(this.delimiter) > 0) {
        let station = role.split(this.delimiter)[0];
        if (result.indexOf(station) < 0) {
          result.push(station);
        }
      }
    }
    return result;
  }

  public static getTokenParam(param: string) {
    let token = JSON.parse(localStorage.getItem("authToken"));

    if (token) {
      return token[param];
    } else {
      return null;
    }
  }

  private static getAuthorities(): Array<string> {
    let result = [];

    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token && token.access_token) {
      let jwtHelper: JwtHelperService = new JwtHelperService();
      let decodedToken = jwtHelper.decodeToken(token.access_token);

      if (decodedToken.authorities) {
        return decodedToken.authorities;
      }
    }

    return result;
  }

  private static getDecodedToken(): any {
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token && token.access_token) {
      let jwtHelper: JwtHelperService = new JwtHelperService();
      return jwtHelper.decodeToken(token.access_token);
    }
    return null;
  }
}

export const ORGANIZATION_ADMIN_ROLE = 'ORGANIZATION_ADMIN_ROLE';
export const STATION_MANAGEMENT_ROLE = 'STATION_MANAGEMENT_ROLE';
