import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Modal } from "ngx-modialog/plugins/bootstrap";
import { CmHttpClient } from "../../../services/http_client";
import { CmTranslateService } from "../../../services/localization/cm-translate.service";
import { ASC_STRING, DESC_STRING } from "../../cm-sortable-th/cm-sortable-th.component";
import { TabTitle } from "../../../shared/tabTitle";
import { CmGuardService } from "../../../services/guards/cm-guard.service";
import {CmDamageBikeCategoryBasic, CmDamageBikeCategoryModel} from "../cm-damage-cat-bike.model";
import {DataService} from "../../../services/cm-data.service";
import * as moment from "moment";
import {CmUtil} from "../../../shared/cm-util";
import {NgxSpinnerService} from "ngx-spinner";
import { SpinnerUtils } from '../../../shared/spinnerUtils';

@Component({
  selector: "cm-damage-cat-config",
  template: require("./cm-damage-cat-bike-config-list.component.html"),
  styles: [require("../../cm-main/main.css").toString(), require('./cm-damage-cat-bike-config-list.component.css').toString()]
})
export class CmDamageBikeCatConfigComponent extends TabTitle implements OnInit {
  private globalSettings: CmDamageCatBikeConfigGlobalModel = new CmDamageCatBikeConfigGlobalModel(0, 0, 0, 0);
  private categoryConfigList: Array<CmDamageBikeCategoryModel> = [];

  private sortField: string;
  private sortDirection: string;

  private csvFile;
  private csvFileName: string;

    private spType: string = SpinnerUtils.type;
    private spText: string = SpinnerUtils.text;
    private spColor: string = SpinnerUtils.color;
    private spBdColor: string = SpinnerUtils.bdColor;
    private spFullScreen: boolean = SpinnerUtils.fullScreen;
    private spSize: string = SpinnerUtils.size;

    constructor(
    private router: Router,
    private modal: Modal,
    private http: CmHttpClient,
    private translationService: CmTranslateService,
    private dataService: DataService,
    private spinner: NgxSpinnerService
    ) {
    super("damageCategoryConfigBike.Label", translationService);
  }

  ngOnInit() {
      this.spinner.show();
    this.http.get<Array<CmDamageBikeCategoryModel>>(`/bikes-sql/api/v1/damage-categories/for_bike`).subscribe(data => {
        this.spinner.hide();
      this.categoryConfigList = [];
      let elements = data;

      let tempArray: CmDamageBikeCategoryModel[] = [];
      for (let key in elements) {
        if (key === DAMAGE_CAT_BIKE_GLOBAL_KEY) {
          let element = elements[key];
          this.globalSettings = new CmDamageCatBikeConfigGlobalModel(
            element.number,
            element.check,
            element.toBSC,
            element.block,
            element.checkTime,
            element.estimatedRepairTime,
            element.closeComplainDirectly,
            element.closeWhenBikeMoves,
            element.internal,
            element.withoutReportDaysLimit
          );
        } else {
          let element = elements[key];
          tempArray.push(
            new CmDamageBikeCategoryModel(element.number, key, element.mappingText, element.check, element.toBSC, element.block, element.estimatedRepairTime)
          );
        }
      }

      tempArray.sort((el1, el2) => el1.name.localeCompare(el2.name));
      this.categoryConfigList = tempArray;
            if(this.categoryConfigList.length == 0){
                this.modal.alert().message(this.translationService.get("msg.NoResult")).open();
            }

        },
        error =>{
            this.spinner.hide();
            console.log(error);
            this.modal.alert().message("Error: " + error).open()
        }
    );
    this.csvFileName = this.translationService.get('chooseFileToImport.label') || 'chooseFileToImport.label' ;
  }

  saveGlobal() {
    this.http
      .post(`/bikes-sql/api/v1/damage-categories/for_bike`, this.globalSettings)
      .subscribe(data => {
        this.modal
          .alert()
          .message(this.translationService.get("message.SaveMsg"))
          .open();
      });
  }

  openCategory(id) {
    this.router.navigate(["/sec/damageBikeCatConfig", id]);
  }

  removeCategory(row) {
    this.http.delete(`/bikes-sql/api/v1/damage-categories/${row.number}`).subscribe(data => {
      this.modal
        .alert()
        .message(this.translationService.get("message.SaveMsg"))
        .open();
      this.ngOnInit();
    });
  }

  private sort(field) {
    this.sortField = field;
    if (this.sortDirection === ASC_STRING) {
      this.sortDirection = DESC_STRING;
      this.categoryConfigList.sort((a, b) => {
        try {
          return b[field].localeCompare(a[field]);
        } catch (ex) {
          return (b[field] ? b[field] : -100) >= (a[field] ? a[field] : -100) ? 1 : -1;
        }
      });
    } else {
      this.sortDirection = ASC_STRING;
      this.categoryConfigList.sort((a, b) => {
        try {
          return a[field].localeCompare(b[field]);
        } catch (ex) {
          return (a[field] ? a[field] : -100) >= (b[field] ? b[field] : -100) ? 1 : -1;
        }
      });
    }
  }

  private importCSV() {

        var newArr = CmUtil.CSVToArray(this.csvFile, ',');
        let result = [];
        for (let i = 1; i< newArr.length ;i++) {
            let obj = {};
            for (let j = 0; j < newArr[0].length; j++) { // headers
                if ( newArr[0][j] === 'mappingText' ) {

                    if ( newArr[i][j] !== null && newArr[i][j] !== '' ) {
                        obj['mappingText'] = [];
                        var str = newArr[i][j] + '';
                        var set = str.split(',');
                        set.forEach(function (item, i, arr) {
                            obj['mappingText'].push(item);
                        });
                    } else {
                        obj['mappingText'] = null;
                    }
                    continue;
                }
                obj[newArr[0][j]] = newArr[i][j];
            }
            // obj['suggestedParts'] = null;

            let isNotEmptyObject=false;
            for(var key in obj){
                if(obj[key]){
                    isNotEmptyObject=true;
                    break;
                }
            }
            if(!isNotEmptyObject)
            {continue;
            }
            result.push(obj);
        }

        this.dataService.sendJson('/bikes-sql/api/v1/damage-categories/for_bike/importJson', result)
            .subscribe(
                reps => this.showMessage(this.translationService.get("successLabel")),
                err => this.showMessage(this.translationService.get(err))
            );

    }

  private showMessage(message:any) {
    this.modal.alert()
        .message(message)
        .open();
  }

  private loadCsvFile(event) {
    let file = event.target.files[0];
    if (!file) return;

    this.csvFileName = file.name;
    let reader = new FileReader();
    let thisContext = this;

    reader.addEventListener("load",
        function () {
          thisContext.csvFile = reader.result;
        }, false);

    reader.readAsText(file);
  }
    private loadDataForCsv() {
        this.http.get<Array<CmDamageBikeCategoryModel>>(`/bikes-sql/api/v1/damage-categories/for_bike/findAll`).subscribe(data => {
                let elements = data;
                let headers = Object.keys(elements[0]);
                i=headers.indexOf('suggestedParts');
                if (i>=0){
                    headers.splice(i,1);
                }
                i=headers.indexOf('number');
                if (i>=0){
                    headers.splice(i,1);
                }
                elements.sort((el1, el2) => el1.name.localeCompare(el2.name));

                var CSV = '';
                CSV+=headers.join(',')+ '\r\n';
                for (var i = 0; i < elements.length; i++) {
                    var row = "";
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var j=0; j< headers.length; j++) {
                        if (elements[i][headers[j]] === null) {
                            elements[i][headers[j]] = '';
                        }
                        row += '"' + elements[i][headers[j]] + '",';
                    }
                    row.slice(0, row.length - 1);
                    //add a line break after each row
                    CSV += row + '\r\n';
                }

                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
                var csv = CSV;
                let fileDate = moment(new  Date()).format('YYYYMMDD_HH-mm-ss');
                const fileName = 'Damage_Bikes_export_'+fileDate+'.csv';
                CmUtil.writeDownFile(csv, 'text/csv;charset=UTF-8', fileName)

            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.');
            }

        );

    }


    private allowedToEditSettings(): boolean {
    return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
  }
}

class CmDamageCatBikeConfigGlobalModel extends CmDamageBikeCategoryBasic {
  number: number;
  checkTime: number;
  withoutReportDaysLimit: number;
  city: string;
  name: string = DAMAGE_CAT_BIKE_GLOBAL_KEY;

  constructor(
    id: number,
    check?: number,
    toBSC?: number,
    block?: number,
    checkTime?: number,
    estimatedRepairTime?: number,
    closeComplainDirectly?: boolean,
    closeWhenBikeMoves?: boolean,
    internal?: boolean,
    withoutReportDaysLimit?: number
  ) {
    super(check, toBSC, block, estimatedRepairTime, closeComplainDirectly, closeWhenBikeMoves, internal, withoutReportDaysLimit);
    this.checkTime = checkTime;
    this.number = id;

    this.city = CmGuardService.getUserOrganizationFromToken();
  }
}

export const DAMAGE_CAT_BIKE_GLOBAL_KEY: string = "-";
