import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {Observable} from 'rxjs';
import * as moment from "moment";
import {interval} from "rxjs/observable/interval";

@Component({
    selector: 'cm-hour-minute-countdown',
    template: require('./cm-hour-minute-countdown.component.html'),
    styles: [require('./cm-hour-minute-countdown.component.scss').toString()]
})
export class CmHourMinuteCountdownComponent implements OnInit {

    @Input() start: string;
    @Input() finish: string;
    @Input() static: boolean;

    counterString$: Observable<String>;
    counter: String;
    activeIcon: ActiveIcon;
    ActiveIcon = ActiveIcon;

    constructor(private cdRef:ChangeDetectorRef) {}

    ngOnInit(): void {
        if (!this.start || !this.finish)
            return;

        let diffInMs = moment(this.finish).diff(moment(this.start));

        if (!this.static) {
            this.activeIcon = diffInMs > 0 ? ActiveIcon.RUNNING : ActiveIcon.MISSED;
            this.counter = this.formatDiff(diffInMs);
            setInterval(() => {
                this.activeIcon = diffInMs > 0 ? ActiveIcon.RUNNING : ActiveIcon.MISSED;
                this.counter = this.formatDiff(diffInMs);
                diffInMs -= 1000;
            }, 1000);
        } else {
            this.activeIcon = diffInMs > 0 ? ActiveIcon.DONE : ActiveIcon.MISSED;
            this.counter = this.formatDiff(diffInMs);
        }
        this.cdRef.detectChanges();
    }

    private formatDiff(diffInMs: number): string {
        let diffInMsUnsigned = Math.abs(diffInMs);
        let hours = Math.floor(diffInMsUnsigned / (60 * 60 * 1000));
        let minutes = Math.floor((diffInMsUnsigned - hours * 60 * 60 * 1000) / (1000 * 60));
        let seconds = Math.floor((diffInMsUnsigned - hours * 60 * 60 * 1000 - minutes * 60 * 1000) / 1000);
        let sign = diffInMs < 0 ? '-' : '';

        let formatTimePart = (timePart) => {
            let timePartStr = Math.abs(timePart).toString();
            return timePartStr.length == 1 ? '0' + timePartStr : timePartStr;
        };
        return `${sign}${formatTimePart(hours)}:${formatTimePart(minutes)}:${formatTimePart(seconds)}`;
    }


}

export enum ActiveIcon {
    RUNNING,
    DONE,
    MISSED
}