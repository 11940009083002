import {Marker, MarkerType} from "./marker";

export class Bike extends Marker {
    bike: number;
    bikeId: number;
    bikeNumber : string;
    status : string;
    pedelec: boolean;
    battery: Battery;
}

export class Battery {
    charging: boolean;
    chargeInPercent: number;
}

export enum BikeType {
    BIKE = "BIKE",
    PEDELEC = "PEDELEC",
    CARGOPEDELEC = 'CARGOPEDELEC'
}