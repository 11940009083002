import { Injectable } from '@angular/core';
import { CmHttpClient } from './http_client';

/**
 * Maybe one day we are smart enough to realize that we want to send any kind of email to any recipient. Then we can use this service.
 */
@Injectable()
export class CmEmailServiceService {

  private readonly url = '/script-support/api/v2/rules/run/saveComplain';

  constructor(private http: CmHttpClient) {}

}
