import { CmTranslateService } from "./../../services/localization/cm-translate.service";
import { TabTitle } from "./../../shared/tabTitle";
import { Modal } from "ngx-modialog/plugins/bootstrap";
import * as moment from "moment/moment";

export abstract class CmBaseAutoTicketConfigComponent extends TabTitle {
  constructor(protected title: string, protected modal: Modal, protected translationService: CmTranslateService) {
    super(title, translationService);
  }

  protected showMessage(message: string) {
    if (message)
      this.modal
        .alert()
        .message(message)
        .open();
  }

  protected formatTimestamp(dateString) {
    return dateString ? moment(dateString).format("DD.MM.YYYY HH:mm") : "";
  }
}
