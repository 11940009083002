import {Serializable} from "../../../shared/serializable";

export class CmTripModel extends Serializable{
    started: string;
    finished: string;
    fromLocation: ShortLocation;

    fromCoordinates: Coordinates;
    toLocation: ShortLocation;
    toCoordinates: Coordinates;
    duration: string;
    minutes: number;
    toFound: boolean;
    fromFound: boolean;

}

class ShortLocation {
    locationId: number;
    locationNumber: number;
    locationName: string;
}

class Coordinates {
    latitude: string;
    longitude: string;
}

