import { Injectable } from '@angular/core';
import { CmHttpClient } from './http_client';
import {Observable, Subject} from 'rxjs';
import { OutsideJob } from '../components/cm-job-list/cm-job.model';
import { JobInLocationType, CmJobItem } from '../components/cm-job-list/item/cm-job-item.model';
import { CmTranslateService } from './localization/cm-translate.service';
import { CmGuardService } from './guards/cm-guard.service';
import {PageAdapter} from "../components/cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import {EventType} from "./cm-bike-blocking.service";

@Injectable()
export class CmLocationsService {
  public STATUS_ACTIVE = 'ACTIVE';
  public STATUS_INACTIVE = 'INACTIVE';

  private masterDataKey: string;
  /*
     Master data.
     */
  private masterDataLocations: Array<CmLocation> = [];
  private masterDataMap: any = {};

  private masterDataLoaded = new Subject<any>();
  public masterDataLoaded$ = this.masterDataLoaded.asObservable();

  private masterDataLoadedNew = new Subject<any>();
  public masterDataLoadedNew$ = this.masterDataLoadedNew.asObservable();

  /*
     Database data.
     */
  private dataBaseData: CmDBJobList;

  private dataBaseDataLoaded = new Subject<CmDBJobList>();
  public dataBaseDataLoaded$ = this.dataBaseDataLoaded.asObservable();

  //event for change MD
  private changeMdSuccess = new Subject();
  public changeMdSuccess$ = this.changeMdSuccess.asObservable();

  //event for load data inside location
  private locationItemLoaded = new Subject<Array<any>>();
  public locationItemLoaded$ = this.locationItemLoaded.asObservable();

  private retJson: any;

  constructor(private http: CmHttpClient, private translate: CmTranslateService) {
    this.masterDataKey = CmGuardService.getUserOrganizationFromToken();
  }

  public loadStationMD(callback?) {
    if (this.masterDataLocations.length === 0) {
      this.http.get('/master-data/api/v1/locations/' + this.masterDataKey).subscribe((data:any) => {
        this.masterDataLocations = [];
        let markers = data;
        for (let marker in markers) {
          let value = markers[marker];
          value.fullName = this.getFullName(value);
          value.ani = parseInt(marker);
          if (!value.interval) {
            value.interval = new CmLocationInterval();
          }
          this.masterDataLocations.push(value);
          this.masterDataMap[marker] = value;
        }
        this.masterDataLoaded.next(this.masterDataLocations);
        if (callback) {
          callback.call(this);
        }
      });
    } else {
      this.masterDataLoaded.next(this.masterDataLocations);
      if (callback) {
        callback.call(this);
      }
    }
  }

    public loadStationMDNew(page, limit, filter?: string) {
        this.http
            .get<PageAdapter<CmLocation>>('/bikes-sql/api/v1/locations/list/?page=' + page + '&limit=' + limit + (filter ? filter : ''))
            .subscribe(data => {
                let val = data;
                if (val.content) {
                    val.content.forEach(el => {
                        el.fullName = this.getFullName(el);
                    });
                }
                this.masterDataLoadedNew.next(val);
            });
    }

  public getById(id: number): CmLocation {
    return this.masterDataMap[id] || { fullName: '' };
  }

  public getNameById(id: number): string {
    let location = this.masterDataMap[id];
    return location ? this.getFullName(location) : 'name';
  }

  public loadFullData(page: number, pageLimit: number, options?: any, filters?: string) {
    this.http
      .get<CmDBJobList>('/bikes/api/v1/tasks/list?page=' + page + '&limit=' + pageLimit + (filters ? filters : ''), options)
      .subscribe(data => {
        let dataParsed = data;
        if (dataParsed.hasOwnProperty('total') && dataParsed.hasOwnProperty('list')) {
          this.dataBaseData = dataParsed;
        } else {
          this.dataBaseData = {
            list: [],
            total: 0
          };
        }
        this.loadStationMD(this.mergeDataBaseAndMasterData);
      });
  }

  public submitData(key: number, value: CmLocation) {
    this.http.put('/master-data/api/v1/locations/' + this.masterDataKey + '/' + key, value).subscribe(data => {
      this.masterDataLocations = [];
      this.masterDataMap = {};
      this.loadStationMD(this.fireChangeSuccessEvent);
    });
  }

  public loadDataByLocation(location) {
    let jobItemList = [];
    this.http.get<Array<JobItemServerData>>('/bikes/api/v1/tasks/list/location/' + location).subscribe(data => {
      let serverData: Array<JobItemServerData> = data;
      serverData.forEach(el => {
        if (el.mv) this.addJobs(jobItemList, JobInLocationType.move, el.id, el.assignedTo, [el.mv]);
        this.addJobs(jobItemList, JobInLocationType.check, el.id, el.assignedTo, this.convertCheck(el.check));
        this.addJobs(jobItemList, JobInLocationType.moveToWs, el.id, el.assignedTo, this.convertCheck(el.toWs));
      });
      this.locationItemLoaded.next(jobItemList);
    });
  }

  public locationStatusUpdate(locationId, locationStatus: string): Observable<any> {
    return this.http.put(`/communication/api/v1/cm2dbrent/location/${locationId}`, { "currentStatus": locationStatus });
  }

  public getJobItemText(item: CmJobItem): string {
    switch (item.taskType) {
      case JobInLocationType.move:
        let msg = '';
        if (item.qty < 0) {
          msg = this.translate.get('jobItem.UnloadLabel') + ' ' + -1 * item.qty;
        } else {
          msg = this.translate.get('jobItem.LoadLabel') + ' ' + item.qty;
        }
        return msg;
      case JobInLocationType.check:
        return this.translate.get('outsideJob.CheckLabel') + ' ' + item.bike;
      case JobInLocationType.moveToWs:
        return item.bike + ' ' + this.translate.get('outsideJob.ToWsLabel');
    }
  }

  private addJobs(jobItemList: Array<any>, type: JobInLocationType, id: number, user: string, list: Array<number>) {
    if (list) list.forEach(el => jobItemList.push(new CmJobItem(id, type, el, user, true)));
  }

  public getFullName(location) {
    if (location) return location.locationName ? location.locationName : `${location.street1} / ${location.street2}`;

    return '';
  }

  private mergeDataBaseAndMasterData() {
    this.dataBaseData.list.forEach(job => {
      let masterData = this.getById(job.location.locationId);
      job.name = masterData ? this.getFullName(masterData) : '-';
    });
    this.dataBaseDataLoaded.next(this.dataBaseData);
  }

  private fireChangeSuccessEvent() {
    this.changeMdSuccess.next(true);
  }

  private convertCheck(fromArray) {
    let toArray = [];
    for (let el of fromArray) {
      toArray.push(el.bikeNumber);
    }
    return toArray;
  }

  public isLocationActive(location) {
    return location.currentStatus !== this.STATUS_INACTIVE;
  }


}

export enum BikeType  {BIKE , PEDELEC , CARGOPEDELEC }

export class CmLocationInterval  {
  startTime   : number = 0;
  finishTime  : number = 0;
  type : string;
  min: number;
  minFixTime: number;
  thresholdMin: number;
  max: number;
  maxFixTime: number;
  thresholdMax: number;
  optimum: number;
  locationId: number;
}

export class CmLocation  {
  locationNumber: number;
  locationId: number;
  locationName: string;
  street1: string;
  street2: string;
  fullName: string;
  postalCode: string;
  district: string;
  comment: string;
  coordinates: any;
  currentStatus: string;
  type: string;
}

class JobItemServerData {
  id: number;
  check: Array<number>;
  mv: number;
  location: number;
  toWs: Array<number>;
  assignedTo: string;
}

export class CmDBJobList {
  list: Array<OutsideJob> = [];
  total: number;
}
