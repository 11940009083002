import { Observable } from 'rxjs/Observable';
import { CmHttpClient } from './http_client';
import { Injectable } from '@angular/core';

@Injectable()
export class CmBikeBlockingService {
  constructor(private http: CmHttpClient) {}

  public blockBike(bikeId): Observable<any> {
    return this.blockUnblockBike(bikeId, EventType.SPERRUNG);
  }

  public unblockBike(bikeId): Observable<any> {
    return this.blockUnblockBike(bikeId, EventType.ENTSPERRUNG);
  }

  private blockUnblockBike(bikeId, eventType: EventType): Observable<any> {
    return this.http.post(`/communication/api/v1/cm2dbrent/bikes/${bikeId}/status`, { eventType: EventType[eventType] });
  }
}

export enum EventType {
    SPERRUNG, ENTSPERRUNG
}
