import {Injectable} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";

@Injectable()
export class CmComponentTrackerService {
    private isDetailedComponent: boolean;
    private component: string;
    private goFromParentComponentPage: number;
    private componentLastPageMapping = {};
    private isBackFromDetailedToParent: boolean;

    readLastParentPageForCurrentComponentIfExist() {
        return this.isBackFromDetailedToParent ? this.componentLastPageMapping[this.component] : null;
    }

    constructor(private router:Router){
        router.events.subscribe(event => {
            if(event instanceof NavigationStart) {
                let parts: string[] = event.url.split("/");
                let isDetailedComponent: boolean = this.isNumeric(parts[parts.length-1].charAt(0));
                let component = isDetailedComponent ? parts[parts.length -2] : parts[parts.length-1];

                if(this.goFromParentComponentPage) {
                    this.componentLastPageMapping[component] = this.goFromParentComponentPage;
                    this.goFromParentComponentPage = null;
                }

                //calculate if we are back from details to main page
                this.isBackFromDetailedToParent = this.isDetailedComponent && !isDetailedComponent && this.component == component;

                this.isDetailedComponent = isDetailedComponent;
                this.component = component;
            }
        });
    }

    private isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    public goToDetailsFromParentPage(page: number) {
        this.goFromParentComponentPage = page;
    }
}