import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmHttpClient } from '../../services/http_client';
import { DataService } from '../../services/cm-data.service';
import { Modal } from 'ngx-modialog/plugins/bootstrap';
import { CmTranslateService } from '../../services/localization/cm-translate.service';
import { TabTitle } from '../../shared/tabTitle';
import { CmUtil, SORT_DIRECTION } from '../../shared/cm-util';

@Component({
  selector: 'cm-move-spare-part',
  template: require('./cm-move-spare-part.component.html'),
  styles: ['.cm-warn{  border-color:red;}']
})
export class CmMoveSparePartComponent extends TabTitle implements OnInit {
  private model: CmSparePartMovement = new CmSparePartMovement();

  private stationFromList = [];
  private stationToList = [];
  private categories = [];
  private parts = [];
  private quantityTypeList = [];

  private newFrom = 'new';
  private wasteTo = 'waste';
  private isSavePressed = false;

  constructor(
    private router: Router,
    private http: CmHttpClient,
    private dataService: DataService,
    private modal: Modal,
    private translationService: CmTranslateService
  ) {
    super('inventory.CreateMovementLabel', translationService);
  }

  ngOnInit() {
    this.dataService.getStationList().subscribe(data => {
      this.stationFromList = CmUtil.simpleSort(data.slice(0), 'text', SORT_DIRECTION.ASC);
      this.stationFromList.push({ name: this.newFrom, text: 'new.Label' });
      this.stationToList = CmUtil.simpleSort(data.slice(0), 'text', SORT_DIRECTION.ASC);
      this.stationToList.push({ name: this.wasteTo, text: 'waste.Label' });
    });

    this.dataService
      .getSparePartsCategoriesList()
      .subscribe(
        data => (this.categories = CmUtil.simpleSort(data.filter(d => d.text !== null), 'text', SORT_DIRECTION.ASC))
      );

    this.dataService.returnLocalizedMap('quantityType').subscribe((data:any) => {
      this.quantityTypeList = data.quantityType;
      this.model.quantityType = 'Pieces';
    });
  }

  private save(isValid: boolean) {
    this.isSavePressed = true;
    if(isValid == false){
      return;
    }
    let url: string = '/bikes-sql/api/v1/transfer';
    if (this.model.from === this.newFrom) {
      url = '/bikes-sql/api/v1/bring';
    } else if (this.model.to === this.wasteTo) {
      url = '/bikes-sql/api/v1/waste';
    }

    this.http.post(url, this.model).subscribe(
      () =>
        this.modal
          .alert()
          .message(this.translationService.get('message.SaveMsg'))
          .open()
            .result
          .then(dialog => dialog.result)
          .then(result => this.close())
          .catch(err => {}),
      error =>
        this.modal
          .alert()
          .message(this.translationService.get(
              error.error? (error.error.message ? (error.error.message) : error.error): error
          ))
          .open()
    );
  }

  private close() {
    this.router.navigate(['/sec/sparePart']);
  }

  private loadInventoryPart() {
    this.dataService
      .getSparePartsByCategory(this.model.category)
      .subscribe(
        data => (this.parts = CmUtil.simpleSort(data.filter(d => d.text !== null), 'text', SORT_DIRECTION.ASC))
      );
  }
}

class CmSparePartMovement {
  category: string;
  part: number;
  from: string;
  to: string;
  quantity: number;
  quantityType: string;
  comment: string;
}
