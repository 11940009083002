import { ASC_STRING, DESC_STRING } from "../components/cm-sortable-th/cm-sortable-th.component";

export class TableFilter {
  public sortDirection: string;
  public sortField: string;

  private key: string;

  constructor(localStorageKey) {
    this.key = localStorageKey;
    this.clone(JSON.parse(localStorage.getItem(this.key)));
  }

  public save() {
    localStorage.setItem(this.key, JSON.stringify(this));
  }

  public sort(field) {
    this.sortField = field;
    if (this.sortDirection === ASC_STRING) {
      this.sortDirection = DESC_STRING;
    } else {
      this.sortDirection = ASC_STRING;
    }
  }

  public clear() {
    this.sortDirection = null;
    this.sortField = null;
  }

  public toString(): string {
    if (this.sortField && this.sortDirection) return `&sortField=${this.sortField}&sortDirection=${this.sortDirection}`;
    return "";
  }

  private clone(from) {
    for (let objProperty in from) {
      this[objProperty] = from[objProperty];
    }
  }
}
