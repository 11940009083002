import { Injectable } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { CmHttpClient } from "../http_client";
import { Subject, Observable } from "rxjs";
import { DEFAULT_LANGUAGE } from "../../app.component";

@Injectable()
export class CmLocalizationService implements TranslateLoader {
  private selectedLang: string;
  private data: any;

  private dataSubject = new Subject<any>();
  public data$ = this.dataSubject.asObservable();

  constructor(private http: CmHttpClient) {
    this.selectedLang = localStorage.getItem("customerLang") || DEFAULT_LANGUAGE;
    localStorage.setItem("customerLang", this.selectedLang);
  }

  getTranslation(lang: string): Observable<any> {
    return this.http.getWithoutAuth("/master-data/api/v1/localization/" + lang).map(res => {
      let result = res;
      this.dataSubject.next(result);
      return result;
    });
  }
}
