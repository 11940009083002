import {Component} from "@angular/core";
import {Modal} from "ngx-modialog/plugins/bootstrap";
import {Router} from "@angular/router";
import {CmStationService} from "../../services/cm-station.service";
import {CmGuardService} from "../../services/guards/cm-guard.service";
import {TabTitle} from "../../shared/tabTitle";
import {CmTranslateService} from "../../services/localization/cm-translate.service";


@Component({
  selector: 'cm-home',
  template: require('./cm-home.component.html'),
  styles: [require('./cm-home.component.scss').toString(), require('./color_theme.css').toString(),
    require('./main.css').toString()],
})

export class CmHome {

  mobileView: number = 992;
  toggle: boolean = false;
  menuList: any;
  selectedMenu: string;

  path: string[];

  private rolesMapping: MainMenuRoles = new MainMenuRoles();

  notifyUrl(item) {
    this.path = [];
    this.path.push(item);
  }

  constructor(private stationService: CmStationService,
              private router: Router,
              private modal: Modal,
              public translate: CmTranslateService) {
    this.attachEvents();
    this.path = [];

    stationService.stationChange$.subscribe(
      station => this.processMenu(station)
    )
  }

  selectMenu(menu) {
    this.selectedMenu = menu;
    this.notifyUrl(menu.name);
  }

  attachEvents() {
    window.onresize = ()=> {
      if (this.getWidth() >= this.mobileView) {
        if (localStorage.getItem('toggle')) {
          this.toggle = !!localStorage.getItem('toggle');
        } else {
          this.toggle = true;
        }
      } else {
        this.toggle = false;
      }
    }
  }

  getWidth() {
    return window.innerWidth;
  }

  toggleSidebar() {
    this.toggle = !this.toggle;
    localStorage.setItem('toggle', this.toggle.toString());
  }

  checkStationSelected(route: string) {

    let message = 'Select station for this process!';
    try {
      message = this.translate.get('mainMenu.StationSelectedMsg');
    } catch (e) {
      console.log('Error: translation problems');
    }

    if (!this.stationService.station || this.stationService.station === "no station selected") {
      this.modal.alert()
        .message(message)
        .open();
      return;
    }
    this.router.navigate([route]);
  }

  disableStationSelect() {
    let path = <any>window.location.href;
    return path.includes('infleeting') ||
      path.includes('defleeting') ||
      path.includes('rent') ||
      path.includes('exitGate') ||
      path.includes('return') ||
      path.includes('turnaround');
  }

  processMenu(station) {
    if (station === "no station selected") {
      for (let element in this.rolesMapping) {
        if (this.rolesMapping.hasOwnProperty(element)) {
          this.rolesMapping[element] = false;
        }
      }
    } else {
      for (let element in this.rolesMapping) {
        if (this.rolesMapping.hasOwnProperty(element)) {
          this.rolesMapping[element] = CmGuardService.isUserInRole(station + CmGuardService.delimiter + element);
        }
      }
    }
  }

}

class MainMenuRoles {
  infleeting: boolean;
  defleeting: boolean;
  rent: boolean;
  delivery: boolean;
  deliveryHAS: boolean;
  gate: boolean;
  return: boolean;
  collection: boolean;
  collectionHAS: boolean;
  transit: boolean;
  turnaround: boolean;
  idleSighting: boolean;
  movementIn: boolean;
  movementInTransporter: boolean;
  movementout: boolean;
  movementoutTransporter: boolean;
  damageUpdate: boolean;

  constructor() {
    this.infleeting = false;
    this.defleeting = false;
    this.rent = false;
    this.delivery = false;
    this.deliveryHAS = false;
    this.gate = false;
    this.return = false;
    this.collection = false;
    this.collectionHAS = false;
    this.transit = false;
    this.turnaround = false;
    this.idleSighting = false;
    this.movementIn = false;
    this.movementout = false;
    this.damageUpdate = false;
    this.movementInTransporter = false;
    this.movementoutTransporter = false;
  }
}
