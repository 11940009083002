import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {CmLocationsService} from "../../services/cm-location.service";
import {Subscription} from "rxjs/Subscription";
import {CmHttpClient} from "../../services/http_client";
import {Employee} from "../cm-assign/cm-assign.component";
import {OutsideJob} from "../cm-job-list/cm-job.model";

@Component({
    selector: 'cm-job-list',
    template: require('./cm-job-list-by-district.component.html'),
    styles: [require('./cm-job-list-by-district.component.scss').toString()]
})
export class CmJobListByDistrictComponent implements OnInit {

    private jobList: Array<OutsideJob> = [];
    private userList: Array<Employee> = [];

    private districtList: Array<OutsideJob> = [];
    private districtSourceList: Array<OutsideJob> = [];

    private districtObject = {};
    private districtHashMap = {};
    private openedDistrict: OutsideJob;

    private pageLimit: number = 400;
    private total: number;

    private sub: Subscription;

    private dragAndDropEl: OutsideJob;
    private dragAndDropLogin: string;

    private showUserJob: boolean = false;

    // constructor(private router: Router,
    //             private locationsService: CmLocationsService,
    //             private http: CmHttpClient) {
    //
    //
    //     this.sub = locationsService.dataBaseDataLoaded$.subscribe(
    //         data => {
    //             this.jobList = data.list;
    //             this.districtObject = {};
    //             this.districtHashMap = {};
    //
    //             this.jobList.forEach(item => {
    //                 if (item.location === null) {
    //                     console.log(item);
    //                 }
    //
    //                 if (this.districtHashMap.hasOwnProperty(item.district)) {
    //                     this.districtHashMap[item.district].push(item);
    //                 } else {
    //                     this.districtHashMap[item.district] = [item];
    //                 }
    //
    //
    //                 let calcualtedObj = new OutsideJob();
    //                 if (this.districtObject.hasOwnProperty(item.district)) {
    //                     calcualtedObj = this.districtObject[item.district];
    //                     calcualtedObj.mv += item.mv;
    //                     calcualtedObj.toWs += item.toWs;
    //                     calcualtedObj.check += item.check;
    //                     // calcualtedObj.priority = calcualtedObj.priority > item.priority ? item.priority : calcualtedObj.priority;
    //                 } else {
    //                     calcualtedObj.mv = item.mv;
    //                     calcualtedObj.toWs = item.toWs;
    //                     calcualtedObj.check = item.check;
    //                     // calcualtedObj.priority = item.priority;
    //                     calcualtedObj.district = item.district;
    //                     calcualtedObj.location = null;
    //                 }
    //                 this.districtObject[item.district] = calcualtedObj;
    //
    //             });
    //
    //
    //             for (let el in this.districtHashMap) {
    //                 this.districtHashMap[el].sort((a, b) => a.name.localeCompare(b.name));
    //             }
    //
    //             let list = [];
    //             for (let el in this.districtObject) {
    //                 let value = this.districtObject[el];
    //                 // value.location = null;
    //                 list.push(value);
    //             }
    //             list.sort((a, b) => {
    //                 return a.district.localeCompare(b.district);
    //             });
    //             this.districtList = list;
    //             this.districtSourceList = list;
    //
    //             this.total = 400;
    //
    //
    //         }
    //     );
    //
    //     this.http.get('/user-information/api/v1/users').subscribe(
    //         data => {
    //             let list = data.json();
    //             list.sort((a, b) => {
    //                 return a.login.localeCompare(b.login);
    //             });
    //             this.userList = list;
    //         }
    //     );
    //
    //     locationsService.locationItemLoaded$.subscribe(
    //         data => {
    //             let idArr = [];
    //             data.forEach(el => idArr.push(el.id));
    //             let url = '/bikes/api/v1/tasks/booking/' + this.dragAndDropLogin;
    //             this.http.post(url, idArr).subscribe(
    //                 () => this.ngOnInit()
    //             );
    //         }
    //     );
    // }
    //
    //
    // allowDrop(ev) {
    //     ev.preventDefault();
    // }
    //
    // drag(ev) {
    //     this.dragAndDropEl = ev;
    // }
    //
    // drop(ev, login) {
    //     ev.preventDefault();
    //
    //     if (this.dragAndDropEl && this.dragAndDropEl.location) {
    //         this.dragAndDropLogin = login;
    //         this.locationsService.loadDataByLocation(this.dragAndDropEl.location);
    //
    //         this.openedDistrict = null;
    //     }
    // }
    //
    // private openDistrict(row) {
    //     if (this.openedDistrict) {
    //         this.openedDistrict = null;
    //         this.districtList = this.districtSourceList;
    //     } else {
    //         this.openedDistrict = row;
    //         let index = this.districtList.indexOf(row);
    //         let firstPart = this.districtList.slice(0, index + 1);
    //         let secondPart = this.districtList.slice(index + 1, this.districtList.length);
    //         this.districtList = firstPart.concat(this.districtHashMap[row.district]).concat(secondPart);
    //     }
    // }
    //
    // private openLocation(outsideJob: OutsideJob) {
    //     this.router.navigate(['/sec/joblist', outsideJob.location, {from: 'joblist'}]);
    // }
    //
    // private setPage(page: number) {
    //     this.sub.unsubscribe();
    //     this.locationsService.dataBaseDataLoaded$.subscribe(
    //         data => {
    //             this.jobList = data.list;
    //         }
    //     );
    //
    //     this.locationsService.loadFullData(page, this.pageLimit);
    // }
    //
    // setShowUserJob(value) {
    //     this.showUserJob = value;
    // }
    ngOnInit() {
        // this.locationsService.loadFullData(1, this.pageLimit);
    }

}