export class CmSparePart {
    id: number;
    category: string;
    categoryId: number;
    categoryCode: string;
    quantity: number;
    part: string;
    barcode: string;
    supplier: string;
    description: string;
    pictures: string[];
    articleNumber: string;
    supplierArticleNumber: string;
    code: string;
    number: number;
    hidden: boolean;
}