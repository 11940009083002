import {Serializable} from "./serializable";
import {CmCoordinates} from "./coordinates";

export class Marker extends Serializable {
    name: string;
    coordinates: CmCoordinates;
    // latitude: number;
    // longitude: number;
    type: MarkerType;
    quantities: any;
    highlighted: boolean = true;
    bordered: boolean = false;
    hasLockedBikes: boolean;
}

export enum MarkerType {
    JOB,
    BIKE,
    EMPLOYEE,
    BIKE_CLUSTER
}