import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from "../../../services/cm-data.service";

@Component({
    selector: 'cm-status-filter',
    template: require('./cm-job-status-filter.component.html')
})
export class CmStatusFilterComponent implements OnInit {
    @Input() selectedStatuses: Array<string> = [];
    @Input() dataSource: string = 'jobStatus';
    @Output() changeStatus: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    private statusList: Array<StatusListItem> = [];

    constructor(private dataService: DataService) {
    }

    ngOnChanges() {
        this.statusList.forEach(el => el.selected = false);
        if (this.selectedStatuses) {
            this.selectedStatuses.forEach(el => {
                this.statusList.forEach(el1 => {
                    if (el1.status.name === el) {
                        el1.selected = true;
                    }
                })
            });
        }
    }

    ngOnInit() {
        this.dataService.returnLocalizedMap('/'  + this.dataSource).subscribe(
            data => {
                data[this.dataSource].forEach(el => this.statusList.push(new StatusListItem(el)));
                this.ngOnChanges();
            }
        );
    }

    private _changeStatus() {
        let outList = [];
        this.statusList.forEach(el => {
            if (el.selected) {
                outList.push(el.status.name);
            }
        });
        this.changeStatus.next(outList);
    }
}

class StatusListItem {
    status: StatusItem;
    selected: boolean;

    constructor(status: StatusItem) {
        this.status = status;
        this.selected = false;
    }
}

class StatusItem {
    name: string;
    text: string;
}