import { Observable } from "rxjs/Observable";
import { BikeJobListItem, CmJobType } from "./../cm-bike-job/cm-bike-job-list.model";
import { EMPLOYEE_WORKLOAD_THRESHOLD } from "./../../services/cm-user-workload.service";
import { Component, OnInit } from "@angular/core";
import { DialogRef, ModalComponent } from "ngx-modialog";
import { BSModalContext, Modal } from "ngx-modialog/plugins/bootstrap";
import { CmHttpClient } from "../../services/http_client";
import { CmTranslateService } from "../../services/localization/cm-translate.service";
import { CmUserWorkloadService } from "../../services/cm-user-workload.service";

@Component({
  selector: "cm-assign",
  template: require("./cm-assign.component.html"),
  styles: [require("./cm-assign.component.scss").toString()]
})
export class CmAssignComponent implements OnInit, ModalComponent<CustomModalContext> {
  context: CustomModalContext;

  private employeeList: Array<Employee> = [];
  private sourceEmployeeList: Array<Employee> = [];
  private assignee: Employee;
  private filter: string;

  private unassign: string;

  constructor(
    public dialog: DialogRef<CustomModalContext>,
    private http: CmHttpClient,
    private translateService: CmTranslateService,
    private workloadService: CmUserWorkloadService,
    private modal: Modal
  ) {
    this.context = dialog.context;
    this.unassign = this.translateService.get("unassign.Label");
  }

  ngOnInit() {
    this.http.get("/user-information/api/v1/users").subscribe(data => {
      if (this.unassign && this.unassign.length > 0) this.employeeList.push(new Employee(this.unassign));

      let userList = Employee.sortUsers(data);
      this.employeeList = this.employeeList.concat(userList);
      this.sourceEmployeeList = JSON.parse(JSON.stringify(userList));
    });
  }

  private close() {
    this.context.parent.reloadData.call(this.context.parent);
    this.dialog.close();
  }

  private selectEmployee(employee: Employee) {
    this.assignee = employee;
  }

  private assign() {
    if (this.assignee) {
      let jobItems: BikeJobListItem[] = this.context.parent.jobItemList.filter(jobItem => jobItem.checked === true);

      if (this.assignee.login === this.unassign) {
        this.undispatch(jobItems);
        return;
      }

      // For Check-Jobs we calculate the workload for the assignee and display a warning if the workload is too high
      let checkJobs: BikeJobListItem[] = jobItems.filter(jobItem => CmJobType[CmJobType.TO_CHECK] === jobItem.task);
      if (checkJobs && checkJobs.length > 0) {
        this.workloadService.loadEmployeeWorkLoad(this.assignee).subscribe(
          response => {
            let employeeWorkLoad = response[this.assignee.login];
            let jobsWorkLoad: number = this.workloadService.calculateJobsWorkload(checkJobs);
            let workload = jobsWorkLoad || 0 + employeeWorkLoad || 0;
            if (EMPLOYEE_WORKLOAD_THRESHOLD < workload) {
              // Display warning
              this.modal
                .confirm()
                .message(
                  this.translateService.get("dispatching.workload.warning", {
                    assignee: this.getName(this.assignee),
                    workload: workload
                  })
                )
                .open()
                  .result
                .then(dialog => dialog.result)
                .then(ok => this.dispatch(jobItems))
                .catch(cancel => console.log("Cancel"));
            } else this.dispatch(jobItems);
          },
          error => {
            console.log(JSON.stringify(error));
            this.modal
              .prompt()
              .message(`Error occured while trying to load the workload for ${this.getName(this.assignee)}`)
              .open();
            this.dispatch(jobItems);
          }
        );
      } else this.dispatch(jobItems);
    }
  }

  private dispatch(jobItems: any[]) {
    this.http
      .post(`/bikes-sql/api/v1/tasks/booking?user=${this.assignee.login}`, jobItems.map(jobItem => jobItem.id))
      .subscribe(data => this.close());
  }

  private undispatch(jobItems: any[]) {
    this.http
      .post("/bikes-sql/api/v1/tasks/unbooking", jobItems.map(jobItem => jobItem.id))
      .subscribe(data => this.close());
  }

  private filterUsers() {
    if (this.filter) {
      // this.employeeList = this.sourceEmployeeList.filter(el => el.login.indexOf(this.filter) >= 0);
      this.employeeList = this.sourceEmployeeList.filter(el => {
        let filterResult = -1;
        if (el.lastName) {
          filterResult = el.lastName.toLowerCase().search(this.filter.toLowerCase());
          if (filterResult !== -1) return true;
        }
        if (el.firstName) {
          filterResult = el.firstName.toLowerCase().search(this.filter.toLowerCase());
          if (filterResult !== -1) return true;
        }
        if (el.login) {
          filterResult = el.login.toLowerCase().search(this.filter.toLowerCase());
          if (filterResult !== -1) return true;
        }

        return false;
      });
    } else {
      this.employeeList = this.sourceEmployeeList;
    }
  }

  private getName(employee: Employee): string {
    // if (employee.firstName && employee.lastName) return `${employee.lastName} ${employee.firstName}`;

    // return employee.login;
    return Employee.getName(employee)
  }
}

export class CustomModalContext extends BSModalContext {
  public parent: any;
}

export class Employee {
  login: string;
  firstName: string;
  lastName: string;

  constructor(login) {
    this.login = login;
  }

  // static getFullName(employee: Employee): string {
  //   if (employee.firstName && employee.lastName) return `${employee.lastName} ${employee.firstName}`;
  //   return employee.login;
  // }
  static getName(employee: Employee): string {
    // if (employee.firstName && employee.lastName) return `${employee.lastName} ${employee.firstName}`;

    return employee.login;
  }

  static sortUsers(userList) {
    if (userList) {
      return userList.sort((a, b) => {
        // return Employee.getFullName(a).localeCompare(Employee.getFullName(b));
        return Employee.getName(a).localeCompare(Employee.getName(b));
      });
    }
    return [];
  }
}
