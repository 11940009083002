export var LANGUAGES = [
  {
    id:'en',
    name:'English'
  },
  {
    id:'de',
    name:'Deutsch'
  },
  {
    id:'fr',
    name:'Français'
  },
  {
    id:'ru',
    name:'Русский'
  }
];
