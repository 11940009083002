import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {CmHttpClient} from "../../../services/http_client";
import {CmRepairModel} from "../cm-repair.model";
import * as moment from "moment/moment";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";
import {TabTitle} from "../../../shared/tabTitle";
import {DialogRef, ModalComponent} from "ngx-modialog";
import {BSModalContext} from "ngx-modialog/plugins/bootstrap";

@Component({
    selector: 'cm-repair-item',
    template: require('./cm-repair-item.component.html'),
    styles: [require('./cm-repair-item.component.scss').toString()]
})
export class CmRepairItemComponent extends TabTitle implements ModalComponent<CmRepairModalDialog> {

    private model: CmRepairModel = new CmRepairModel();

    constructor(private http: CmHttpClient,
                public dialog: DialogRef<CmRepairModalDialog>,
                private router: Router,
                private r: ActivatedRoute,
                public translate: CmTranslateService) {
        super('repairs.Label', translate);
        this.model.id = dialog.context.repairId;
        this.loadData();
    }

    private loadData() {
        this.http.get<CmRepairModel>('/bikes-sql/api/v1/repair/' + this.model.id).subscribe(
            data => {
                this.model = data;
            }
        );
    }

    private close() {
        this.dialog.close();
    }

    private formatDate(date): string {
        if (date) {
            return moment(date).format('DD.MM.YYYY HH:mm');
        } else {
            return '-';
        }
    }
}

export class CmRepairModalDialog extends BSModalContext {
    public repairId: number;
}