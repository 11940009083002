import {Injectable} from "@angular/core";
import {CmHttpClient} from "./http_client";
import {v4} from "uuid";
import {HttpHeaders} from "@angular/common/http";
const uuidv4 = require('uuid/v4')

@Injectable()
export class CmAmazonPicturesService {

  private _url: string;

  get url(): string {
    return this._url;
  }

  constructor(private http: CmHttpClient) {
    this.http.get('/object-storage/api/v1/object/baseUrl',{responseType: "text"}).subscribe(
        (data:any) => this._url = data//.text()
    );
  }

  public submitPicture(picture): string {

    if (!picture) {
      return picture;
    }

    if (!this.checkIfPictureFormat(picture)) {
      //if it is not picture format then submit the data to server
      return picture;
    }

    let contentType, data;
    try {
      contentType = this.getContentType(picture);
      data = this.getData(picture);
    } catch (e) {
      throw new Error('incorrect image format');
    }

    let requestOptions = {
      headers: this.createHeaders(contentType),
      responseType: 'text'
    };

    let uuid4 = uuidv4();
    let url = '/object-storage/api/v1/object/store/' + uuid4;

    this.http.post(url, data, requestOptions).subscribe(data => {
      console.log("success saving file " + uuid4)
    }, error => {
      console.error("error saving file " + uuid4);
    });

    return uuid4;
  }

  public getPicture(data: string) {
    if (!data) {
      return data;
    }

    if (this.checkIfPictureFormat(data)) {
      return data;
    } else {
      return this.url + data;
    }
  }

  private checkIfPictureFormat(picture: string): boolean {
    return picture.indexOf(";") > 0 && picture.indexOf(':') > 0 && picture.indexOf(',') > 0;
  }

  private getContentType(picture: string): string {
    return picture.split(';')[0].split(':')[1];
  }

  private getData(picture: string): string {
    return picture.split(',')[1];
  }

  private createHeaders(contentType) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', contentType);
    return headers;
  }
}
