import {Injectable} from "@angular/core";
import {Subject} from "rxjs/Subject";

@Injectable()
export class CmStationService {

  private stationSubj = new Subject<any>();
  stationChange$ = this.stationSubj.asObservable();

  private _station: string;

  get station(): string {
    return this._station;
  }

  public setStation(station: string) {
    this._station = station;
    this.stationSubj.next(station);
  }
}
