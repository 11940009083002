import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {CmBikeDispatchModel} from '../cm-bike-dispatch.model';
import {CmHttpClient} from '../../../services/http_client';
import {DataService} from '../../../services/cm-data.service';
import {TableFilter} from '../../../shared/tableFilter';
import {MarkerType} from '../../../shared/marker';
import {TabTitle} from '../../../shared/tabTitle';
import {CmTranslateService} from '../../../services/localization/cm-translate.service';
import {CmGuardService} from '../../../services/guards/cm-guard.service';
import {Modal} from 'ngx-modialog/plugins/bootstrap';
import {CmUtil} from '../../../shared/cm-util';
import {PageAdapter} from "../../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import {CmComponentTrackerService} from "../../../services/cm-component-tracker-service";
import {NgxSpinnerService} from "ngx-spinner";
import {SpinnerUtils} from '../../../shared/spinnerUtils';
import {CmReportComponent} from "../../cm-report/cm-report.component";
import {ReportExtractParam, ReportExtractParamField} from "../../../shared/reportExtractParam";
import moment = require('moment');

@Component({
  selector: 'cm-bike-dispatch',
  template: require('./cm-bike-dispatch.component.html'),
  styles: [require('./cm-bike-dispatch.component.scss').toString(), require('../../cm-main/main.css').toString()]
})
export class CmBikeDispatchComponent extends TabTitle implements OnInit, OnDestroy {
  private filters: CmBikeDispatchFilters = new CmBikeDispatchFilters();

  private pageLimit: number = 15;
  private total: number;
  private initPage: number = 1;
  private page: number;

  private jobItemList: Array<CmBikeDispatchModel> = [];

  private stationList = [];
  private statusList = [];

  private subList: Array<Subscription> = [];

  private csvFile;
  private csvFileName: string;

  private spType: string = SpinnerUtils.type;
  private spText: string = SpinnerUtils.text;
  private spColor: string = SpinnerUtils.color;
  private spBdColor: string = SpinnerUtils.bdColor;
  private spFullScreen: boolean = SpinnerUtils.fullScreen;
  private spSize: string = SpinnerUtils.size;

  private reportType: string = "BikesData";
  private reportsListLen: Number = 0;
  private childProcessing: boolean = false;
  private maxReportsListSize = 5;

  @ViewChild('archivedrep', {static: false}) archivedChild: CmReportComponent;

  constructor(
    private dataService: DataService,
    private http: CmHttpClient,
    private router: Router,
    private modal: Modal,
    public translate: CmTranslateService,
    private componentTracker: CmComponentTrackerService,
    private spinner: NgxSpinnerService
  ) {
    super('bikeDispatch.Label', translate);
  }

  ngOnInit() {
    let stationSub = this.dataService.getStationList().subscribe(data => {
      this.stationList = data;
      this.dataService.returnLocalizedMap('bikeStatus').subscribe((data:any) => {
        this.statusList = data.bikeStatus;
        let lastPage = this.componentTracker.readLastParentPageForCurrentComponentIfExist()
        if(lastPage)
          this.loadData(this.page = lastPage)
        else
        this.loadData();
      });
    });
    this.subList.push(stationSub);
    this.csvFileName = this.translate.get('chooseFileToImport.label') || 'chooseFileToImport.label';
  }

  ngOnDestroy() {
    for (let sub of this.subList) {
      sub.unsubscribe();
    }
  }

  public reloadData() {
    this.jobItemList = [];
    this.loadData();
  }

  private loadData(page?: number) {
    this.spinner.show();
    this.page = page;
    this.http
      .get<PageAdapter<CmBikeDispatchModel>>(
        '/bikes-sql/api/v1/dispatch/list?page=' +
          (page || this.initPage) +
          '&limit=' +
          this.pageLimit +
          this.filters.toString()
      )
      .subscribe(
        data => {
          this.spinner.hide();
          this.jobItemList = data.content;
          this.total = data.total;
          if(this.total == 0){
            this.modal.alert().message(this.translate.get("msg.NoResult")).open();
          }
        },
        error => {
          this.spinner.hide();
          console.log("Error : " + error);
          this.jobItemList = [];
          this.modal.alert().message("Error : " + error);
        }
      );
  }

  private filter() {
    this.filters.save();
    this.loadData();
  }

  private showDetails(row) {
    this.componentTracker.goToDetailsFromParentPage(this.page);
    this.router.navigate(['/sec/bikeDispatch/' + row.bikeId]);
  }

  private formatDate(date): string {
    if (date) return moment(date).format('DD.MM.YYYY HH:mm');
    return '';
  }

  private getRowColor(value) {
    if (value) {
      return 'red';
    } else {
      return 'green';
    }
  }

  private isBikeMissing(row) {
    if (row.status === 'missing' || row.status === 'MISSING') {
      return 'red';
    } else {
      return 'green';
    }
  }

  private isActive(row) {
    if (row.active) {
      return 'green';
    } else {
      return 'red';
    }
  }

  private getStation(station: string) {
    return this.dataService.getStationFromList(station, this.stationList);
  }

  private getStatus(statusId: string) {
    if (!statusId)
      return '';
    let item = this.statusList.filter(el => el.name.toLowerCase() === statusId.toLowerCase())[0];
    if (item) {
      return item.text;
    } else {
      return statusId;
    }
  }

  private sort(field) {
    this.filters.sort(field);
    this.loadData();
  }

  private getBikeType() {
    return MarkerType.BIKE;
  }

  private hasLock(lock) {
    if (lock) {
      return 'green';
    } else {
      return 'red';
    }
  }
  private importCSV() {
    // uploadCSVasJSON
    var rows = this.csvFile.split(/\r\n|\r|\n/g); // as 'split("\n")' doesn't works for mac os files

    let headers = rows[0].split(',');
    headers = headers.map(e => e.trim());
    let latitudeIndex = headers.indexOf('latitude');
    let longitudeIndex = headers.indexOf('longitude');
    let locationIdIndex = headers.indexOf('locationId');
    let result = [];
    for (var i = 1; i < rows.length - 1; i++) {
      var obj = {};
      var currentline = rows[i].split(',');
      for (var j = 0; j < headers.length; j++) {
        if (headers[j] === 'latitude') continue;
        if (headers[j] === 'longitude') continue;

        if (headers[j] === 'locationId') continue;
        if (headers[j] === 'locationNumber') continue;
        if (headers[j] === 'locationName') continue;

        obj[headers[j]] = currentline[j];
      }
      if (
        currentline[latitudeIndex] !== null &&
        currentline[latitudeIndex] !== '0' &&
        currentline[latitudeIndex] !== '' &&
        currentline[longitudeIndex] !== null &&
        currentline[longitudeIndex] !== '0' &&
        currentline[longitudeIndex] !== ''
      ) {
        obj['coordinates'] = {};
        obj['coordinates']['latitude'] = currentline[latitudeIndex];
        obj['coordinates']['longitude'] = currentline[longitudeIndex];
      }
      if (
        currentline[locationIdIndex] !== null &&
        currentline[locationIdIndex] !== '0' &&
        currentline[locationIdIndex] !== ''
      ) {
        obj['location'] = {};
        obj['location']['locationId'] = currentline[locationIdIndex];
      }

      result.push(obj);
    }
    this.dataService
      .sendJson('/bikes-sql/api/v1/bicycles/all', result)
      .subscribe(
        reps => this.showMessage(this.translate.get('successLabel')),
        err => this.showMessage(this.translate.get(err))
      );
  }

  exportRepairs() {

    if(this.reportsListLen >= this.maxReportsListSize){
      this.archivedChild.removeLastReportFromList();
    }

    let urlParams = this.filters.toString();
    if(urlParams.length > 0) {
      urlParams = '?' + urlParams.substring(1);
    }

    let lockTypeSubstitutions = {
      "bike": this.translate.get("bikeLockType.bike"),
      "station": this.translate.get("bikeLockType.station"),
      "none": this.translate.get("bikeLockType.none")
    };

    let stationSubstitutions = {};
    this.stationList.forEach(st => {
      stationSubstitutions[st['name']] = st['text']
    })

    let reportFileName = this.translate.get("report.bike.fileName") ;
    let reportParams = new ReportExtractParam();
    reportParams.method = "GET";
    reportParams.path = '/bikes-sql/api/v1/bicycles/export/' + urlParams;

    reportParams.fields =
        [
          new ReportExtractParamField("bikeId", this.translate.get("report.bike.header.bikeId")),
          new ReportExtractParamField("bikeNumber", this.translate.get("report.bike.header.bikeNumber")),
          new ReportExtractParamField("branding", this.translate.get("report.bike.header.bikeBranding")),
          new ReportExtractParamField("city", this.translate.get("report.bike.header.city")),
          new ReportExtractParamField("location.locationId", this.translate.get("report.bike.header.locationId")),
          new ReportExtractParamField("location.locationNumber", this.translate.get("report.bike.header.locationNumber")),
          new ReportExtractParamField("location.locationName", this.translate.get("report.bike.header.locationName")),
          new ReportExtractParamField("coordinates.latitude", this.translate.get("report.bike.header.latitude")),
          new ReportExtractParamField("coordinates.longitude", this.translate.get("report.bike.header.longitude")),
          new ReportExtractParamField("frame", this.translate.get("report.bike.header.frame")),
          new ReportExtractParamField("lock", this.translate.get("report.bike.header.lock")),
          new ReportExtractParamField("lockVersion", this.translate.get("report.bike.header.lockVersion")),
          new ReportExtractParamField("lockVoltage", this.translate.get("report.bike.header.lockVoltage")),
          new ReportExtractParamField("battery.chargeInPercent", this.translate.get("dispatching.chargeLevel")),
          new ReportExtractParamField("status", this.translate.get("report.bike.header.status")),
          new ReportExtractParamField("statusChanged", this.translate.get("report.bike.header.statusChanged"), "dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("type", this.translate.get("report.bike.header.type")),
          new ReportExtractParamField("lockType", this.translate.get("troubleTickets.LockType"), null, null, lockTypeSubstitutions),
          new ReportExtractParamField("loadedTo", this.translate.get("report.bike.header.loadedTo"),null,null, stationSubstitutions),
          new ReportExtractParamField("customerCare", this.translate.get("report.bike.header.customerCare")),
          new ReportExtractParamField("customerCareSince", this.translate.get("customerCareSince.label")),
          new ReportExtractParamField("timestamp", this.translate.get("timestamp.label")),
          new ReportExtractParamField("lastReportTime", this.translate.get("report.bike.header.lastReportTime"))
        ];

    this.dataService.getReportExtract2(reportParams, this.reportType ,reportFileName)
        .subscribe(
            data => {
              this.childProcessing = true;
              this.archivedChild.startProcessingProcess();

            },
            error => {
              console.log("Error downloading the file.");
            });

  }

  processingMessage(event: any) {
    this.childProcessing = event;
  }

  onListSize($event: Number) {
    this.reportsListLen = $event
    let i=0;
  }

  private exportCSV() {
    this.http
      .get<PageAdapter<CmBikeDispatchModel>>(
        '/bikes-sql/api/v1/dispatch/list?page=' +
          1 +
          '&limit=' +
          (this.filters.downloadBikesLimit != null ? this.filters.downloadBikesLimit : 1000000) +
          this.filters.toString()
      )
      .subscribe(
        data => {
          const jsonAr = data.content;
          const headers = Object.keys(jsonAr[0]);
          var i = headers.indexOf('coordinates');
          if (i >= 0) {
            headers.splice(i, 1);
          }
          var i = headers.indexOf('location');
          if (i >= 0) {
            headers.splice(i, 1);
          }
          headers.push('latitude');
          headers.push('longitude');
          headers.push('locationId');
          headers.push('locationNumber');
          headers.push('locationName');

          var CSV = '';
          CSV += headers.join(',') + '\r\n';
          for (var i = 0; i < jsonAr.length; i++) {
            var row = '';
            //2nd loop will extract each column and convert it in string comma-seprated
            for (var j = 0; j < headers.length; j++) {
              if (headers[j] === 'longitude' || headers[j] === 'latitude') {
                jsonAr[i][headers[j]] = jsonAr[i]['coordinates'] === null ? '' : jsonAr[i]['coordinates'][headers[j]];
              }

              if (headers[j] === 'locationId' || headers[j] === 'locationNumber' || headers[j] === 'locationName') {
                jsonAr[i][headers[j]] = jsonAr[i]['location'] === null ? '' : jsonAr[i]['location'][headers[j]];
              }

              var val = jsonAr[i][headers[j]];
              val = val === null ? '' : val;
              val = val
                .toString()
                .replace(/,/g, ',')
                .replace(/"/g, '""');
              row += '"' + val + '",';
            }
            row.slice(0, row.length - 1);
            //add a line break after each row
            CSV += row + '\r\n';
          }
          var tmp = CSV;

          let filedate = moment(new Date()).format('YYYYMMDD_HH-mm-ss');
          const fileName = 'biks_export_' + filedate + '.csv';
          CmUtil.writeDownFile(tmp, 'text/csv;charset=UTF-8', fileName);
        },
        error => {
          this.showMessage(this.translate.get(error));
        },
        () => {
          this.showMessage(this.translate.get('successLabel'));
        }
      );
  }

  private showMessage(message: any) {
    this.modal
      .alert()
      .message(message)
      .open();
  }

  private uploadCsvFileChooser(event) {
    let file = event.target.files[0];
    if (!file) return;

    this.csvFileName = file.name;
    let reader = new FileReader();
    let thisContext = this;

    reader.addEventListener(
      'load',
      function() {
        thisContext.csvFile = reader.result;
      },
      false
    );

    reader.readAsText(file);
  }

  private allowedToEditSettings(): boolean {
    return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
  }
}

class CmBikeDispatchFilters extends TableFilter {
  bikeNumber: string;
  location: string;
  branding: string[];
  customerCare: boolean;
  lostBike: boolean;
  showInactive: boolean;
  noLock: boolean;
  station: number;
  statuses: Array<string>;
  downloadBikesLimit: number;
  statusChangedHoursAgo: number;
  bikeTypes: Array<string>;
  downloadPageNumber: number;

  constructor() {
    super('bikeDispatch');
    this.downloadBikesLimit = 100;
  }

  public toString(): string {
    let result = super.toString();
    if (this.bikeNumber) {
      result += '&bikeNumber=' + this.bikeNumber;
    }
    if (this.customerCare) {
      result += '&care=' + this.customerCare;
    }
    if (this.location) {
      result += '&location=' + this.location;
    }
    if (this.lostBike) {
      result += '&lostBike=' + this.lostBike;
    }
    if (this.showInactive) {
      result += '&showInactive=' + this.showInactive;
    }
    if (this.noLock) {
      result += '&noLock=' + this.noLock;
    }
    if (this.station) {
      result += '&station=' + this.station;
    }
    if (this.statuses && this.statuses.length > 0) {
      result += '&statuses=' + this.statuses;
    }
    if (this.branding && this.branding.length > 0) {
      result += '&bikeBranding=' + this.branding;
    }
    if (this.statusChangedHoursAgo) {
      result += '&statusChangedHoursAgo=' + this.statusChangedHoursAgo;
    }
    if (this.bikeTypes && this.bikeTypes.length > 0) {
      result += `&bikeTypes=${this.bikeTypes}`;
    }
    return result;
  }

  public clear() {
    super.clear();
    this.bikeNumber = null;
    this.location = null;
    this.branding = [];
    this.customerCare = false;
    this.lostBike = false;
    this.showInactive = false;
    this.noLock = false;
    this.station = null;
    this.statuses = [];
    this.downloadBikesLimit = 100;
    this.statusChangedHoursAgo = null;
    this.bikeTypes = [];
    this.save();
  }
}
