import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CmLocation, CmLocationInterval, CmLocationsService} from '../../../services/cm-location.service';
import {Modal} from 'ngx-modialog/plugins/bootstrap';
import {CmTranslateService} from '../../../services/localization/cm-translate.service';
import {CmValidatable} from '../../cm-validatable/cm-validatable';
import {CmHttpClient} from '../../../services/http_client';
import {TabTitle} from '../../../shared/tabTitle';
import {BikeType} from "../../../shared/bike";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
  selector: 'cm-location-item',
  template: require('./cm-location-item.component.html'),
  styles: [require('./cm-location-item.component.scss').toString()]
})
export class CmLocationItemComponent extends TabTitle implements OnInit {
  private locationId: number;
  location: CmLocation;
  private intervals: Array<CmLocationInterval> = [];

  private validList: Array<CmValidatable> = [];
  private maxValue: number = 999;
  private maxIntervalsCount = 6;

  private intervalDefault        : CmLocationInterval = null;
  private intervalCurrent        : CmLocationInterval = null;

  private pedelecIntervalDefault : CmLocationInterval = null;
  private pedelecIntervalCurrent : CmLocationInterval = null;

  private cargoIntervalDefault : CmLocationInterval = null;
  private cargoIntervalCurrent : CmLocationInterval = null;

  INTERVAL_DEFAULT = 'INTERVAL_DEFAULT';
  INTERVAL_CURRENT = 'INTERVAL_CURRENT'

  PEDELEC_DEFAULT  = 'INRERVAL_DEFAULT_PEDELEC';
  PEDELEC_CURRENT  = 'INRERVAL_CURRENT_PEDELEC';

  CARGO_DEFAULT = 'INTERVAL_DEFAULT_CARGO';
  CARGO_CURRENT = 'INTERVAL_CURRENT_CARGO';

  private statusChanged: boolean = false;

  constructor(
    private router: Router,
    private r: ActivatedRoute,
    public locationService: CmLocationsService,
    private modal: Modal,
    private http: CmHttpClient,
    private translationService: CmTranslateService
  ) {
    super('jobList.LocationsLabel', translationService);

    r.params.map(p => p).subscribe(data => {
      this.locationId = (<any>data).id;
    });
  }

  ngOnInit() {
    this.http.get<CmLocation>('/bikes-sql/api/v1/locations/' + this.locationId).subscribe(data => {
      let tmpLocation = data;
      tmpLocation.fullName = this.locationService.getFullName(tmpLocation);
      this.location = tmpLocation;
    }); // ext sub
    this.loadIntervals();
  }

  loadIntervals(){
    this.http.get<Array<CmLocationInterval>>('/bikes-sql/api/v1/locations/' + this.locationId + '/intervals').subscribe(
        (data:Array<CmLocationInterval>) => {
          this.intervals = data;
          let i = this.intervals.length;
          if(i > this.maxIntervalsCount){
            this.modal.alert().message("error: intervals so march!!").open();
          }
          let map = new Map();

          while (i>0){
            i--;
            let t = this.intervals[i];
            let t1 = BikeType.PEDELEC;
            let t2 = BikeType.CARGOPEDELEC;
            let t3 = BikeType.BIKE;
            let t4 = BikeType[BikeType.PEDELEC];
            let t5 = BikeType[BikeType.CARGOPEDELEC];
            let t6 = BikeType[BikeType.BIKE];
            let t7 = BikeType[t.type];
            if(this.intervals[i].startTime == null && this.intervals[i].finishTime == null){  // Defaults

              if( this.intervals[i].type == BikeType[BikeType.PEDELEC] ) {
                this.pedelecIntervalDefault = this.intervals[i];
                map.set(this.PEDELEC_DEFAULT, this.pedelecIntervalDefault)
              }else if(this.intervals[i].type == BikeType[BikeType.CARGOPEDELEC]){
                this.cargoIntervalDefault = this.intervals[i];
                map.set(this.CARGO_DEFAULT, this.cargoIntervalDefault)
              }else{
                this.intervalDefault = this.intervals[i];
                map.set(this.INTERVAL_DEFAULT,this.intervalDefault)
              }


            }else{


              if(this.intervals[i].type == BikeType[BikeType.PEDELEC]){
                this.pedelecIntervalCurrent = this.intervals[i];
                map.set(this.PEDELEC_CURRENT,this.pedelecIntervalCurrent)
              }else if(this.intervals[i].type == BikeType[BikeType.CARGOPEDELEC]){
                this.cargoIntervalCurrent  = this.intervals[i];
                map.set(this.CARGO_CURRENT,this.cargoIntervalCurrent)
              }else {
                this.intervalCurrent =  this.intervals[i];
                map.set(this.INTERVAL_CURRENT,this.intervalCurrent)
              }
            }

          }



//-----------------------------------------------------

          if( !map.has(this.PEDELEC_DEFAULT)  ){
            this.pedelecIntervalDefault = new CmLocationInterval();
            this.pedelecIntervalDefault.type = BikeType[BikeType.PEDELEC];
            this.pedelecIntervalDefault.startTime = null;
            this.pedelecIntervalDefault.finishTime = null;
            this.pedelecIntervalDefault.locationId = this.locationId;
            this.intervals.push(this.pedelecIntervalDefault);
          }
          if( !map.has(this.CARGO_DEFAULT)  ){
            this.cargoIntervalDefault = new CmLocationInterval();
            this.cargoIntervalDefault.type = BikeType[BikeType.CARGOPEDELEC];
            this.cargoIntervalDefault.startTime = null;
            this.cargoIntervalDefault.finishTime = null;
            this.cargoIntervalDefault.locationId = this.locationId;
            this.intervals.push(this.cargoIntervalDefault);
          }
          if( !map.has(this.INTERVAL_DEFAULT)  ){
            this.intervalDefault = new CmLocationInterval();
            this.intervalDefault.type = BikeType[BikeType.BIKE];
            this.intervalDefault.startTime = null;
            this.intervalDefault.finishTime = null;
            this.intervalDefault.locationId = this.locationId;
            this.intervals.push(this.intervalDefault);
          }
          if( !map.has(this.PEDELEC_CURRENT)  ){
            this.pedelecIntervalCurrent = new CmLocationInterval();
            this.pedelecIntervalCurrent.type = BikeType[BikeType.PEDELEC];
            this.pedelecIntervalCurrent.locationId = this.locationId;
            this.intervals.push(this.pedelecIntervalCurrent);
          }
          if( !map.has(this.CARGO_CURRENT)  ){
            this.cargoIntervalCurrent = new CmLocationInterval();
            this.cargoIntervalCurrent.type = BikeType[BikeType.CARGOPEDELEC];
            this.cargoIntervalCurrent.locationId = this.locationId;
            this.intervals.push(this.cargoIntervalCurrent);
          }
          if( !map.has(this.INTERVAL_CURRENT)  ){
            this.intervalCurrent = new CmLocationInterval();
            this.intervalCurrent.type = BikeType[BikeType.BIKE];
            this.intervalCurrent.locationId = this.locationId;
            this.intervals.push(this.intervalCurrent);
          }
        }// int subscribe
        ,error => {
          console.log("error: " + error);
        }
    ) //=====================================================

  }

  private changeLocationStatus(status){
    this.location.currentStatus = status.target.checked ? this.locationService.STATUS_ACTIVE : this.locationService.STATUS_INACTIVE;
    this.statusChanged = this.statusChanged == false;
  }

  private close() {
    this.router.navigate(['/sec/locations']);
  }

  private save() {
    let valid = true;
    this.validList.forEach(el => {
      let val = el.validate();
      valid = valid ? val : false;
    });

    if (valid) {

      if(!this.intervalCurrent.finishTime)
        this.intervalCurrent.finishTime = 0;
      if(!this.intervalCurrent.startTime)
        this.intervalCurrent.startTime = 0;

      if (!this.pedelecIntervalCurrent.finishTime)
        this.pedelecIntervalCurrent.finishTime = 0;
      if(!this.pedelecIntervalCurrent.startTime)
        this.pedelecIntervalCurrent.startTime = 0;

      if (!this.cargoIntervalCurrent.finishTime)
        this.cargoIntervalCurrent.finishTime = 0;
      if(!this.cargoIntervalCurrent.startTime)
        this.cargoIntervalCurrent.startTime = 0;

      let validIntervals = [];
      for(let loc of this.intervals) {


        if(
               ( loc.max         ==null  )
            && ( loc.min         ==null  )
            && ( loc.optimum     ==null  )
            && ( loc.thresholdMax==null  )
            && ( loc.thresholdMin==null  )
            && ( !loc.finishTime   || loc.finishTime   == 0 )
            && ( !loc.startTime    || loc.startTime     == 0 )

        ){
                  // this.location.interval=null;
        }else{
          let isCorrect=true;
          if( loc.max == null)          isCorrect=false;
          if( loc.min == null)          isCorrect=false;
          if( loc.optimum == null)      isCorrect=false;
          if( loc.thresholdMin == null) isCorrect=false;
          if( loc.thresholdMax == null) isCorrect=false;

          if(!isCorrect) {
            this.modal
                .alert().message(this.translationService.get("message.fill.error")).open()
            return
          };

          validIntervals.push(loc)
        }

     }


      this.http.post('/bikes-sql/api/v1/locations/' + this.locationId + '/intervals', validIntervals).subscribe(() => {

        if(this.statusChanged){

          this.locationService.locationStatusUpdate(this.locationId, this.location.currentStatus).subscribe((data)=>{

            this.http.put('/bikes-sql/api/v1/locations/' + this.locationId, {"currentStatus" : this.location.currentStatus}).subscribe(() => {

              let message = this.translationService.get('message.SaveMsg')
                  + ' <br/> ' + this.translationService.get(this.locationService.isLocationActive(this.location) ? 'message.stationActivated' : 'message.stationDeactivated');

              this.modal
                  .alert()
                  .message(message)
                  .message(message)
                  .open()
                  .result
                  .then(dialog => dialog.result)
                  .then(result => window.location.reload());


              this.statusChanged = false;
            })
          });

        }else{
          this.modal
              .alert()
              .message(this.translationService.get('message.SaveMsg'))
              .open()
              .result
              .then(dialog => dialog.result)
              .then(result => window.location.reload());
        }
      });
    }

  }



  private allowedToEditSettings(): boolean {
    return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
  }

}
