import {CmSparePart} from "../cm-spare-part/cm-spare-part-list.model";

export class CmDamageBikeCategoryBasic {
    check: number;
    toBSC: number;
    block: number;
    closeComplainDirectly: boolean;
    closeWhenBikeMoves: boolean;
    internal: boolean;
    withoutReportDaysLimit: number;
    
    estimatedRepairTime: number;

    constructor(check: number, toBSC: number, block: number, estimatedRepairTime: number, closeComplainDirectly: boolean, closeWhenBikeMoves: boolean, internal: boolean, withoutReportDaysLimit: number) {
        this.check = check;
        this.toBSC = toBSC;
        this.block = block;
        this.estimatedRepairTime = estimatedRepairTime;
        this.closeComplainDirectly = closeComplainDirectly;
        this.closeWhenBikeMoves = closeWhenBikeMoves;
        this.internal = internal;
        this.withoutReportDaysLimit = withoutReportDaysLimit;
    }
}

export class CmDamageBikeCategoryModel extends CmDamageBikeCategoryBasic {
    public name: string;
    public mappingTextStr: string;
    public mappingText: Array<string> = [];
    public number: number;

    constructor(number?: number, name?: string, mappingText?: Array<string>, check?: number, toBSC?: number, block?: number, estimatedRepairTime?: number, closeComplainDirectly?: boolean, closeWhenBikeMoves?: boolean, internal?: boolean, withoutReportDaysLimit?: number) {
        super(check, toBSC, block, estimatedRepairTime, closeComplainDirectly, closeWhenBikeMoves, internal, withoutReportDaysLimit);
        this.number = number;
        this.name = name;
        this.mappingText = mappingText;

    }
    public spareParts: Array<CmSparePart>;
    public suggestedPartsIds: Array<any>;
    public suggestedParts: any;
    public checkTime: number;
}