import {
    Component,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
    Input,
    ChangeDetectorRef,
    HostListener
} from "@angular/core";
import {BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import * as moment from "moment";
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ru';
import {DEFAULT_LANGUAGE} from "../../app.component";
import { setTheme } from 'ngx-bootstrap/utils';

import {frLocale, listLocales, ruLocale} from 'ngx-bootstrap/chronos';
import { defineLocale } from 'ngx-bootstrap/chronos';

import { deLocale } from 'ngx-bootstrap/locale';


@Component({
    selector: 'datetimepicker',
    template: require('./datetimepicker.component.html'),
    styles: [require('./datetimepicker.component.scss').toString(), require('ngx-bootstrap/datepicker/bs-datepicker.scss').toString()]
})
export class DateTimePickerComponent implements OnChanges , OnInit{
    @Input() dateTimeValue: string;
    @Input() showTimePart: boolean = true;
    @Input() allowFuture: boolean = false;
    @Output() datePickerselect = new EventEmitter();

    dateTime: Date;
    defaultMaxDate: Date = new Date();
    selectedLang: string;
    locales = listLocales();

    bsConfig: Partial<BsDatepickerConfig>;
    colorTheme = 'theme-red';
    dateInputFormat= 'DD.MM.YYYY HH:mm';
    public opened: boolean = false;
    validTime: boolean = true;

    constructor(private localeService: BsLocaleService) {
        setTheme('bs3'); // or 'bs4'
        defineLocale('de', deLocale);
        defineLocale('fr', frLocale);
        defineLocale('ru', ruLocale);
    }

        ngOnInit(): void {
        this.selectedLang = localStorage.getItem("customerLang") || DEFAULT_LANGUAGE;
        moment.locale(this.selectedLang);
        this.localeService.use(this.selectedLang);
            if (this.showTimePart) {
                this.dateInputFormat= 'DD.MM.YYYY HH:mm';
            } else {
                this.dateInputFormat= 'DD.MM.YYYY';
            }
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme , dateInputFormat: this.dateInputFormat});

            if (this.dateTimeValue && moment(this.dateTimeValue).isValid()) {
                 this.dateTime = moment(this.dateTimeValue).toDate();

        }
    }

    ngAfterViewInit() {
        this.selDate(this.dateTime);
    }

    ngOnChanges() {
        if (this.dateTimeValue && moment(this.dateTimeValue).isValid()) {
            this.dateTime = moment(this.dateTimeValue).toDate();
        } else {
            this.dateTime = null;
            // this.select.emit(null);
        }
    }

    private getValue() {
        if (this.dateTime) {
                return moment(this.dateTime).format(this.dateInputFormat);
        } else {
            return '';
        }
    }

    private selDate(event) {
        if(event) {
                this.dateTime=event;
                this.datePickerselect.emit(moment(this.dateTime).format());
        }
    }

    private oKBtn(){
        if(this.validTime)
        {
            this.opened = !this.opened;
        }
    }

    private clearBtn(){
        this.dateTime=null;
        this.datePickerselect.emit(null);
        this.validTime = true;
        this.oKBtn();
    }

    private onClick(){
        if( !this.opened ) {
            let event = new Event("closeDatePickers");
            document.dispatchEvent(event);
        }
        this.opened=!this.opened;
    }

    private  isValidTime(event: boolean){
        this.validTime = event;
    }

    @HostListener('document:closeDatePickers',[`$event`])
    private closeAllDatePickers(event: Event){
        this.opened = false;
    }
}
