import {Component, OnInit} from "@angular/core";
import {CmHttpClient} from "../../../services/http_client";
import {CmStationService} from "../../../services/cm-station.service";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
  selector: 'cm-current-station',
  template: require('./cm-current-station.component.html'),
  styles: [require('./cm-current-station.component.scss').toString()],
  providers: [CmHttpClient]
})
export class CmCurrentStationComponent implements OnInit {
  currentStation: string;

  stationList: string[];
  private isDropdown = false;


  constructor(private stationService: CmStationService) {

  }

  ngOnInit() {
    this.stationList = CmGuardService.getStationList();
    this.currentStation = this.stationList[0];
  }

  selectStation(station) {
    CmGuardService.getStationList();
    this.currentStation = station;
    this.stationService.setStation(station);
    this.toogleDropdown();
  }

  toogleDropdown() {
    this.isDropdown = !this.isDropdown;
  }
}
