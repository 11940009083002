import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmHttpClient} from "../../../services/http_client";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'cm-bike-status-filter',
    template: require('./cm-bike-status-filter.component.html'),
    styles: [require('./cm-bike-status-filter.component.scss').toString()]
})
export class CmBikeStatusFilterComponent implements OnInit {
    @Input() selectedStatuses: Array<string> = [];
    @Input() statusChangedHoursAgo: number;
    @Input() mapMode: boolean;

    @Output() statusChangedHoursAgoChange = new EventEmitter<number>();
    @Output() selectedStatusesChange = new EventEmitter<Array<string>>();
    @Output() statusesLoaded = new EventEmitter<any>();
    @Output() search = new EventEmitter<any>();

    private statusList: Array<StatusListItem> = [];
    private dataSourceInit = false;

    private MAP_STATUSES = ['sperr', 'LOCKED', 'READY', 'ready', 'OPEN', 'open'];

    constructor(private translationService: TranslateService,
                private http: CmHttpClient,
                private ref: ChangeDetectorRef) {
    }

    ngOnChanges() {
        this.statusList.forEach(el => el.selected = false);
        if (this.selectedStatuses) {
            this.selectedStatuses.forEach(el => {
                this.statusList.forEach(el1 => {
                    if (el1.name === el) {
                        el1.selected = true;
                    }
                })
            });
        }
    }

    ngOnInit() {
        this.http.get('/bikes-sql/api/v1/dispatch/countByStatus' + (this.mapMode ? '?loaded=false' : '')).subscribe(data => {
            this.statusesLoaded.next(data);
            let content = data['content'];
            if (content && content.length) {
                this.translationService.get(content.map(item => CmBikeStatusFilterComponent.localizationKey(item['status']))).subscribe(localization => {
                   content.forEach(statusData => {
                       let status = statusData['status'];
                       if (status && (!this.mapMode || this.MAP_STATUSES.includes(status))) {
                           let text = localization[CmBikeStatusFilterComponent.localizationKey(status)] + ' (' + statusData['count'] + ')';
                           this.statusList.push(new StatusListItem(status, text));
                       }
                   });
                   this.dataSourceInit = true;

                   if (this.selectedStatuses) {
                       this.selectedStatuses = this.selectedStatuses.filter(selected => this.statusList.some(status => status.name === selected));
                   }
                   this.ngOnChanges();
                });
            }
        });
    }

    private static localizationKey(status: string): string {
        return 'bikeStatus.' + status.toLowerCase();
    }

    private _changeStatus() {
        let outList = [];
        this.statusList.forEach(el => {
            if (el.selected) {
                outList.push(el.name);
            }
        });
        this.selectedStatusesChange.next(outList);
    }

    private statusChangedHoursAgoChanged(value) {
        this.ref.detectChanges();
        let tmpValue = value != 0 ? Math.abs(parseInt(value)) : null;
        if (tmpValue > 10000) {
            tmpValue = 10000;
        }
        this.statusChangedHoursAgo = tmpValue;
        this.statusChangedHoursAgoChange.next(tmpValue);
    }

}

class StatusListItem {
    name: string;
    text: string;
    selected: boolean;

    constructor(name: string, text: string) {
        this.name = name;
        this.text = text;
    }
}