import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {CmAliveService} from "./services/cm-alive-service";

@Component({
  selector: "main",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent {
  constructor(private translate: TranslateService, cmAliveService: CmAliveService) {
    let selectedLang = localStorage.getItem("customerLang") || DEFAULT_LANGUAGE;
    this.translate.use(selectedLang);
    cmAliveService.init();
  }
}

export const DEFAULT_LANGUAGE = "de";
