import {Serializable} from "../../shared/serializable";
import {CmCoordinates} from "../../shared/coordinates";
import {CmLocationData} from "../../shared/location";
import {CmBikeListItem} from "../cm-bike-short-list/cm-bike-list.model";

export interface TroubleTicketList {
    tickets: Array<TroubleTicket>;
    total: number;
}

export class TroubleTicket extends Serializable {
    //list data
    id: number;
    bike: CmBikeListItem;
    bikes: Array<TroubleTicketBike> = [];
    category: string;
    location: CmLocationData;
    status: string;
    priority: number;
    firstTripInMinutes: number;

    //details data
    coordinates: CmCoordinates;
    time: string;
    reporter: string;
    whoCaused: string;
    booking: number;
    customer: number;
    // type: string;
    issue: string;
    creationDate: string;
    createdHow: string;
    createdBy: string;
    type: string;

    pictureNames: Array<string> = [];

    constructor() {
        super();
        this.priority = 3;
    }
}

export class TroubleTicketBike {
    bikeId: number;
    bikeNumber: string;
    coordinates: CmCoordinates;

    constructor(bikeId: number, bikeNumber: string, coordinates: CmCoordinates) {
        this.bikeId = bikeId;
        this.bikeNumber = bikeNumber;
        this.coordinates = coordinates;
    }
}
