import { Component, OnInit } from '@angular/core';
import { Modal } from 'ngx-modialog/plugins/bootstrap';
import { CmGuardService } from '../../services/guards/cm-guard.service';
import { CmHttpClient } from '../../services/http_client';
import { CmTranslateService } from '../../services/localization/cm-translate.service';
import { TabTitle } from '../../shared/tabTitle';
import { CmUtil } from './../../shared/cm-util';
import moment = require('moment');
import {PageAdapter} from "../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import {NgxSpinnerService} from "ngx-spinner";
import { SpinnerUtils } from '../../shared/spinnerUtils';

@Component({
  selector: 'cm-news',
  template: require('./cm-news.component.html'),
  styles: [require('./cm-news.component.scss').toString()]
})
export class CmNewsComponent extends TabTitle implements OnInit {
  private city;
  private user;
  private news: CmNewsModel = new CmNewsModel();
  private newsList: CmNewsModel[] = [];

    private spType: string = SpinnerUtils.type;
    private spText: string = SpinnerUtils.text;
    private spColor: string = SpinnerUtils.color;
    private spBdColor: string = SpinnerUtils.bdColor;
    private spFullScreen: boolean = SpinnerUtils.fullScreen;
    private spSize: string = SpinnerUtils.size;
    private isSavePressed = false;

  constructor(private http: CmHttpClient,
              private modal: Modal,
              private translateService: CmTranslateService,
              private spinner: NgxSpinnerService
  ) {
    super('news.Label', translateService);
  }

  ngOnInit() {
    this.city = CmGuardService.getUserOrganizationFromToken();
    this.user = CmGuardService.getUserName();
    this.loadNews();
  }

 private loadNews() {
      this.spinner.show();
    this.http
      .get<PageAdapter<CmNewsModel>>('/bikes-sql/api/v1/newsfeed?page=1&limit=100')
      .subscribe(
        data => {
            this.spinner.hide();
            this.newsList = CmUtil.simpleSort(data.content, 'id')
            if(this.newsList.length == 0){
                this.modal.alert().message(this.translateService.get("msg.NoResult")).open();
            }
        },
        error => {
            this.spinner.hide();
          console.log(error);
          this.modal
            .alert()
            .message(this.translateService.get('error.NoResultsLabel'))
            .open();
        }
      );
  }

  private clear() {
    this.news = new CmNewsModel();
  }

  private save(isValid: boolean) {
      this.isSavePressed = true;
      if(isValid == false){
          return;
      }
    this.isSavePressed = false;
    this.news.author = this.user;
    this.news.city = this.city;
    this.news.category = "misc";
    // Stupid logic of service, but until must be in the future to save it
    this.news.until = moment().year(2342).toDate();
    this.http.post('/bikes-sql/api/v1/newsfeed/message', this.news).subscribe(() => {
      this.modal
        .alert()
        .message(this.translateService.get('message.SaveMsg'))
        .open();
        this.clear();
        this.loadNews();
    });
  }

  private delete(news: CmNewsModel) {
    // news.until = moment().year(1954).toDate();
    this.http.delete(`/bikes-sql/api/v1/newsfeed/${news.id}?user=${this.user}`).subscribe(() => {
      this.modal
        .alert()
        .message(this.translateService.get('message.DeleteMsg'))
        .open();
        this.loadNews();
    });
  }

  private update(news: CmNewsModel) {
    this.http.put(`/bikes-sql/api/v1/newsfeed/${this.news.id}`, this.news).subscribe(() => {
      this.modal
        .alert()
        .message(this.translateService.get('message.SaveMsg'))
        .open();
    });
  }

  private formatDate(date) {
    return CmUtil.formatDate(date);
  }
    onTextChange(text){
        return text = text.trimLeft();
    }
}

class CmNewsModel {
  id: number;
  headline: string;
  text: string;
  category: string;
  author: string;
  created: Date;
  updated: Date;
  until: Date;
  city: string;
}
