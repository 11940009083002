import {CmLocationData} from "../../shared/location";

export class CmAutoTicketConfig {
  bikes: Bike[];
  location: CmLocationData;
  id: number;
  issue: string;
  category: string;
  howOften: number;
  priority: number;
  reporter: string;
  type: string;
}

export class Bike {
  bikeId: number;
  bikeNumber: string;
}

