import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmHttpClient} from "../../../services/http_client";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'cm-bike-branding-filter',
    template: require('./cm-bike-branding-filter.component.html'),
    styles: [require('./cm-bike-branding-filter.component.scss').toString()]
})
export class CmBikeBrandingFilterComponent implements OnInit {
    @Input() selectedBrands: Array<string> = [];
    @Input() mapMode: boolean;

    @Output() selectedBrandsChange = new EventEmitter<Array<string>>();
    @Output() brandsLoaded = new EventEmitter<any>();

    private brandsList: Array<BrandListItem> = [];
    private dataSourceInit = false;

    constructor(private translationService: TranslateService,
                private http: CmHttpClient) {
    }

    ngOnChanges() {
        this.brandsList.forEach(el => el.selected = false);
        if (this.selectedBrands) {
            this.selectedBrands.forEach(el => {
                this.brandsList.forEach(el1 => {
                    if (el1.name === el) {
                        el1.selected = true;
                    }
                })
            });
        }
    }

    ngOnInit() {
        this.http.get<Array<string>>('/bikes-sql/api/v1/bicycles/branding')
            .subscribe(data => {
            this.brandsLoaded.next(data);
            if (data) {
                    data.forEach(brand => {
                        this.brandsList.push(new BrandListItem(brand))
                        }
                    );
                    this.dataSourceInit = true;

                    if (this.selectedBrands) {
                        this.selectedBrands = this.selectedBrands.filter(selected => this.brandsList.some(brand => brand.name === selected));
                    }
                    this.ngOnChanges();
                }
        });

    }

    private _changeStatus() {
        let outList = [];
        this.brandsList.forEach(el => {
            if (el.selected) {
                outList.push(el.name);
            }
        });
        this.selectedBrandsChange.next(outList);
    }
}


class BrandListItem {
    name: string;
    selected: boolean;

    constructor(name: string) {
        this.name = name;
    }
}