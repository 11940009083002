import { Observable } from 'rxjs/Observable';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewChildren,
  isDevMode,
  OnDestroy
} from '@angular/core';
import { AgmInfoWindow } from '@agm/core';
import * as moment from 'moment/moment';
import { CmCoordinates } from './../../shared/coordinates';
import { Marker, MarkerType } from '../../shared/marker';
import { CmOrganizationService } from './../../services/cm-organization.service';
import {DataService} from "../../services/cm-data.service";

@Component({
  selector: 'cm-map',
  template: require('./cm-map.component.html'),
  styles: [require('./cm-map.component.scss').toString()]
})
export class CmMapComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  markers: Array<Marker> = [];

  @Input()
  mapHeight: number = 500;

  @Input()
  branding: []

  @Input()
  employee: []

  @Output()
  changeBound: EventEmitter<string> = new EventEmitter<string>();

  openedMarker: Marker = null;

  private mapCenter: Observable<CmCoordinates>;

  private latitude: number;
  private longitude: number;

  private zoom: number = 12;

  private delay: number = 2000;
  private currentTimeout;

  private bikeIconPath = {
      red: {
          normal: '/master-data/api/v1/bin/clientFiles/bike_red',
          pedelec: '/master-data/api/v1/bin/clientFiles/bike_red_pedelec'
      },
      yellow: {
          normal: '/master-data/api/v1/bin/clientFiles/bike_yellow',
          pedelec: '/master-data/api/v1/bin/clientFiles/bike_yellow_pedelec'
      },
      green: {
          normal: '/master-data/api/v1/bin/clientFiles/bike',
          pedelec: '/master-data/api/v1/bin/clientFiles/bike_pedelec'
      }
  };

  private bikeClusterPath: string = '/master-data/api/v1/bin/clientFiles/bike_cluster.png';

  private stationUrlBase: string = '/report-format/api/v1/image/station';

  private employeePath: string = '/master-data/api/v1/bin/clientFiles/employee.png';

  styleFunc(feature) {
    return ({
      clickable: false,
      strokeColor: feature.getProperty('strokeColor'),
      zIndex: feature.getProperty('strokeColor') == 'red' ? 0 : 100,
      fillOpacity: 0,
      strokeWeight: 2
    });
  }

  // TODO: For dev load it from local folder
  // private bikeGreenPath = 'img/bike.png';
  // private bikeYellowPath = 'img/bike_yellow.png';
  // private bikeRedPath = 'img/bike_red.png';
  // private bikeClusterPath = 'img/bike_cluster.png';

  // private stationGreenPath = 'img/station.png';
  // private stationYellowPath = 'img/station_yellow.png';
  // private stationRedPath = 'img/station_red.png';

  // private employeePath = 'img/employee.png';
  //todo : just chech new argument
  @ViewChildren(AgmInfoWindow)
  childrenList;

  private boundsInitialized: boolean = false;
  areas: Object[];

  constructor(private orgaService: CmOrganizationService,
              private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.readCityAreas().subscribe(d => {
      this.areas = d;
      this.areas.forEach(a => {
        let initialPolygon = a['polygon'];
        let fullPolygon =
        {
          "type": "FeatureCollection",
            "features": [
          {
            "type": "Feature",
            "properties": {
            },
            "geometry": initialPolygon
          }]
        };
        a['polygon'] = fullPolygon;
      });
      this.areas.forEach(a => a['polygon'].features.forEach(f => f.properties.strokeColor = a['type'] == 'OPERATIONAREA' ? 'red' : 'blue'));
    });
  }

  ngOnChanges() {
    // Display a single marker in the maps center
    if (this.markers && this.markers.length === 1) this.displaySingleMarkerInMapCenter(this.markers[0]);
    else this.loadMapCenterByLoginContext();
  }

  private displaySingleMarkerInMapCenter(marker: Marker) {
    if (this.validCoords(marker)) {
      // Do it only if center coordintates are different
      if (marker.coordinates.latitude === this.latitude && marker.coordinates.longitude === this.longitude) return;
      this.latitude = marker.coordinates.latitude;
      this.longitude = marker.coordinates.longitude;
      this.zoom = 16;
      console.log(`MapCenter byMarker lat: ${this.latitude} long: ${this.longitude}`);
    } else console.warn(`displaySingleMarkerInMapCenter: Marker ${JSON.stringify(marker)} invalid!`);
  }

  private validCoords(marker: Marker): boolean {
    if (marker && marker.coordinates) {
      if (marker.coordinates.latitude && marker.coordinates.longitude) return true;
    }
    return false;
  }

  private loadMapCenterByLoginContext() {
    this.mapCenter = this.orgaService.loadCoordinatesByLoginContext();
    this.mapCenter.subscribe(data => {
      // If there is a longitude and latitude dont override it
      if (this.longitude && this.latitude) return;
      this.longitude = data.longitude;
      this.latitude = data.latitude;
      console.log(`MapCenter byLogin lat: ${this.latitude} long: ${this.longitude}`);
    });
  }

  ngOnDestroy() {
    this.closeMarkerDialogs();
  }

  private click(marker) {
    this.closeMarkerDialogs();
    this.openedMarker = marker;
  }

  private closeMarkerDialogs() {
    try {
      if (this.childrenList) {
        this.childrenList.filter(child => child !== null).forEach(child => {
          // child.close();
          child.close().then(null, error => console.error(error));
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  private getOpacity(marker){
    return marker.highlighted ? 1.0 : 0.8;
  }

  private getZIndex(marker){
    return marker.highlighted ? 1.0 : 0;
  }

  private getDotDev(marker){
    // return "https://dbconnect-test.checkmobile.com" + this.getDot(marker);
    return "http://localhost:8081" + this.getDot(marker);
  }

  private getDot(marker) {
    switch (marker.type) {
      case MarkerType.BIKE:
        return this.getBikeMarker(marker);
      case MarkerType.EMPLOYEE:
        return this.employeePath;
      case MarkerType.BIKE_CLUSTER:
        return this.bikeClusterPath;
      default:
        return this.getStationMarker(marker);
    }
  }

  private isJobType(marker: Marker): boolean {
    return marker.type === MarkerType.JOB;
  }

  private isBikeType(marker: Marker): boolean {
    return marker.type === MarkerType.BIKE;
  }

  private isEmployeeType(marker: Marker): boolean {
    return marker.type === MarkerType.EMPLOYEE;
  }

  private isBikeClusterType(marker: Marker): boolean {
    return marker.type === MarkerType.BIKE_CLUSTER;
  }

  private getLabel(marker) {
    if (marker.type === MarkerType.BIKE) {
      return marker.bikes.length.toString();
    }
  }

  private boundsChange(bounds) {
    try {
      if (!this.boundsInitialized) {  // for first load
        this.boundsInitialized = true;
        this.sendBox(bounds);
      } else {
        if (this.currentTimeout) {
          this.stopTimeout();
        }
        this.restartTimeout(bounds);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private restartTimeout(bounds) {
    try {
      this.currentTimeout = setTimeout(() => {
        this.sendBox(bounds);
        this.currentTimeout = null;
      }, this.delay);
    } catch (error) {
      console.error(error);
    }
  }

  private stopTimeout() {
    clearTimeout(this.currentTimeout);
  }

  private sendBox(bounds){
    let data = {
      southLatitude: bounds.getSouthWest().lat(),
      northLatitude: bounds.getNorthEast().lat(),
      westLongitude: bounds.getSouthWest().lng(),
      eastLongitude: bounds.getNorthEast().lng()
    };
    this.changeBound.next(JSON.stringify(data));

  }

  private getStationMarker(marker) {
    let color, bike, pedelec, cargopedelec, hasLockedBikes = '_';
    switch (marker.priority) {
      case 1:
        color = 'r';
        break;
      case 2:
        color = 'y';
        break;
      default:
        color = 'g';
    }

    hasLockedBikes = marker.hasLockedBikes ? "!" : "_";

    if (marker.quantities) {
      let quantities = marker.quantities;
      bike = this.getStringValue(quantities['BIKE']);
      pedelec = this.getStringValue(quantities['PEDELEC']);
      cargopedelec = this.getStringValue(quantities['CARGOPEDELEC']);
    }

    const border = marker.bordered ? 'b' : '' ;

    return this.stationUrlBase + `(${color}${bike}${pedelec}${cargopedelec}${hasLockedBikes})${border}.png`
  }

  private getStringValue(quantity){
    if(quantity && quantity != 0){
      return quantity > 0 ? '+'.concat(quantity) : quantity;
    }else{
      return '_';
    }
  }

  /*
   * Green -> No Job, or Job with Priority 3
   * Yellow --> Job with Priority 2
   * Red --> Job with Priority 1 or Bike has status "Sperr"
   */
  private getBikeMarker(marker) {
    let type = marker.pedelec ? 'pedelec' : 'normal';
    if (marker.status) {
      if (SPERR_HAL_STATUS === marker.status.toLowerCase() || SPERR_MSP_STATUS.toLowerCase() === marker.status.toLowerCase())
          return this.bikeIconPath.red[type] + (marker.bordered ? '_b.png' : '.png');
    }

    let color = this.bikeIconPath.green[type];

    if (marker.priority) {
      switch (marker.priority) {
        case 1:
          color = this.bikeIconPath.red[type];
          break;
        case 2:
          color = this.bikeIconPath.yellow[type];
          break;
        case 3:
          color = this.bikeIconPath.green[type];
          break;
      }
    }


    return color + (marker.bordered ? '_b.png' : '.png');
  }

  private formatTimestamp(dateStr) {
    return moment(dateStr).format('DD.MM.YYYY HH:mm,ss');
  }
}
export const SPERR_HAL_STATUS = 'sperr';
export const SPERR_MSP_STATUS = 'LOCKED';
export const READY_HAL_STATUS = 'ready';
export const READY_MSP_STATUS = 'READY';

export interface CmCity {
  id:number;
  alias:Array<string>;
  name:string;
  northLatitude:number;
  southLatitude:number;
  westLongitude:number;
  eastLongitude:number;
  emailDispatcher:string;
  emailCustomerCenter:string;
}
