import { CmUtil } from './../../../shared/cm-util';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { DataService } from '../../../services/cm-data.service';
import { CmHttpClient } from '../../../services/http_client';
import { BSModalContext, Modal } from 'ngx-modialog/plugins/bootstrap';
import { overlayConfigFactory } from 'ngx-modialog';
import { CmAssignComponent } from '../../cm-assign/cm-assign.component';
import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import {BikeJobListItem} from '../cm-bike-job-list.model';
import { TableFilter } from '../../../shared/tableFilter';
import { MarkerType } from '../../../shared/marker';
import { TabTitle } from '../../../shared/tabTitle';
import { CmTranslateService } from '../../../services/localization/cm-translate.service';
import {PageAdapter} from "../../cm-spare-part/list-modal/cm-spare-part-list-modal.component";
import {ReportExtractParam, ReportExtractParamField} from "../../../shared/reportExtractParam";
import {HttpResponse} from "@angular/common/http";
import {CmComponentTrackerService} from "../../../services/cm-component-tracker-service";
import {CmBikeJobItemComponent} from "../item/cm-bike-job-item.component";
import {NgxSpinnerService} from "ngx-spinner";
import { SpinnerUtils } from '../../../shared/spinnerUtils';
import {BikeType} from "../../../shared/bike";
import {CmReportComponent} from "../../cm-report/cm-report.component";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
  selector: 'cm-bike-job-list',
  template: require('./cm-bike-job-list.component.html'),
  styles: [require('./cm-bike-job-list.component.scss').toString(), require('../../cm-main/main.css').toString()]
})
export class CmBikeJobListComponent extends TabTitle implements OnInit {
  private filters: BikesJobListFilters = new BikesJobListFilters();

  private pageLimit: number = 15;
  private total: number;
  private page: number = 1;

  private jobItemList: Array<BikeJobListItem> = [];
  private typeList = [];
  private statusList = [];
  private stationList = [];

  private currentTimeStr = moment().format();

  private spType: string = SpinnerUtils.type;
  private spText: string = SpinnerUtils.text;
  private spColor: string = SpinnerUtils.color;
  private spBdColor: string = SpinnerUtils.bdColor;
  private spFullScreen: boolean = SpinnerUtils.fullScreen;
  private spSize: string = SpinnerUtils.size;

  private childProcessing: boolean = false;
  private reportType: string = "BikeJobList";
  private reportsListLen: Number = 0;
  private maxReportsListSize = 5;

  @ViewChild('archivedrep', {static: false}) archivedChild: CmReportComponent;


  constructor(
    private dataService: DataService,
    private http: CmHttpClient,
    private router: Router,
    private modal: Modal,
    public translate: CmTranslateService,
    private componentTracker: CmComponentTrackerService,
    private spinner: NgxSpinnerService
  ) {
    super('bikeJobList.Title', translate);

    setInterval(() => {
      this.currentTimeStr = moment().format();
    }, 1000);
  }

  ngOnInit() {
    this.dataService.returnLocalizedMap('/bikesJobListType').subscribe((data:any) => (this.typeList = data.bikesJobListType));
    this.dataService.returnLocalizedMap('/jobStatus').subscribe((data:any) => (this.statusList = data.jobStatus));
    this.dataService.getStationList().subscribe(data => this.stationList = data);

    this.filters = new BikesJobListFilters();
    this.loadData();
  }

  public reloadData() {
    this.jobItemList = [];
    this.loadData();
  }

  private loadData() {
    this.spinner.show();
    this.filters.save();

    this.http
      .get<PageAdapter<BikeJobListItem>>(`/bikes-sql/api/v1/tasks/bikesJobList?page=${this.page}&limit=${this.pageLimit}${this.filters.toString()}`)
      .subscribe(
        data => {
          this.spinner.hide();
          this.jobItemList = data.content;
          this.total = data.total;
          if(this.total == 0){
            this.modal.alert().message(this.translate.get("msg.NoResult")).open();
          }
        },
        error => {
          this.spinner.hide();
          this.jobItemList = [];
          this.total = 0;
          console.error(error);
          this.modal.alert().message(" Error: " + (error.error ? error.error.message : error.message)
          ).open()
        }
      );
  }

  private setPage(page: number) {
    this.page = page;
    this.loadData();
  }

  private showDetails(row) {
    this.modal.open(CmBikeJobItemComponent, overlayConfigFactory({id: row.id}, BSModalContext));
  }

  private assign(row) {
    row.checked = true;
    this.modal.open(CmAssignComponent, overlayConfigFactory({ parent: this }, BSModalContext));
  }

  private getTaskName(taskId): string {
    if (this.typeList) {
      for (let item of this.typeList) {
        if (item.name === taskId) return item.text;
      }
    }

    return taskId;
  }

  private getStatusName(taskId): string {
    if (this.statusList) {
      for (let item of this.statusList) {
        if (item.name === taskId) return item.text;
      }
    }
    return taskId;
  }

  private formatDate(date): string {
    return CmUtil.formatDate(date);
  }

  private sort(field) {
    this.filters.sort(field);
    this.reloadData();
  }

  private getLocationType() {
    return MarkerType.JOB;
  }

  private getBikeTypes() {
    return Object.keys(BikeType)
  }

  private getQuantity(item: BikeJobListItem, bikeType: string) {
    return item == null || item.quantities == null ? null : item.quantities[bikeType];
  }

  dataExtract() {
    if(this.reportsListLen >= this.maxReportsListSize){
      this.archivedChild.removeLastReportFromList();
    }


    let reportFileName = this.translate.get("report.jobAssign.fileName") ;
    let reportParams = new ReportExtractParam();
    reportParams.method = "POST";
    reportParams.path = `/bikes-sql/api/v1/history?${this.filters.toStringExportCase()}`;
    reportParams.paging = true;

    let stationSubstitution = {};
    this.stationList.forEach(s => stationSubstitution[s.name] = s.text);

    let typeSubstitution = {
        LOAD: this.translate.get("report.jobAssign.cell.LOAD"),
        UNLOAD: this.translate.get("report.jobAssign.cell.UNLOAD")
    };

    reportParams.fields =
        [
          new ReportExtractParamField("bike.bikeNumber", this.translate.get("report.jobAssign.header.bikeNumber")),
          new ReportExtractParamField("bike.branding", this.translate.get("report.bike.header.bikeBranding")),
          new ReportExtractParamField("employee", this.translate.get("report.jobAssign.header.employee")),
          new ReportExtractParamField("when", this.translate.get("report.jobAssign.header.date"), "dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("type", this.translate.get("report.jobAssign.header.type"), null, null, typeSubstitution),
          new ReportExtractParamField("station", this.translate.get("report.jobAssign.header.station"), null, null, stationSubstitution),
          new ReportExtractParamField("location.locationName", this.translate.get("report.jobAssign.header.location"), null, null, stationSubstitution)
        ];

    this.dataService.getReportExtract2(reportParams, this.reportType ,reportFileName)
        .subscribe(
            data => {
              this.childProcessing = true;
              this.archivedChild.startProcessingProcess();

            },
            error => {
              console.log("Error downloading the file.");
            });

  }

  jobsDataExtractReport(){
    if(this.reportsListLen >= this.maxReportsListSize){
      this.archivedChild.removeLastReportFromList();
    }

    let filterString=this.filters.toString();

    if (this.filters.type){
      let first = filterString.indexOf("&type=");
      let last  = filterString.indexOf("&",first+1);
      let wordStr;
      if(last==-1){
        wordStr =  filterString.substring(first);
      }else{
        wordStr =  filterString.substring(first,last);
      }
      filterString = filterString.replace(wordStr,"");
    }

    let reportParams = new ReportExtractParam();
    reportParams.method = "GET";
    reportParams.path = `/bikes-sql/api/v1/tasks/bikesJobListExport?fullReport=true${filterString}`;
    reportParams.paging = true;

    let statusSubstitution = {
      "Open":       this.translate.get("report.moving.cell.open"),
      "Closed":     this.translate.get("report.moving.cell.closed"),
      "InProgress": this.translate.get("report.moving.cell.inProgress")
    };

    reportParams.fields =
        [
          new ReportExtractParamField("id",this.translate.get('report.taskId.Label')),
          new ReportExtractParamField("branding",this.translate.get('branding.Label')),
          new ReportExtractParamField("city",this.translate.get('report.bike.header.city')),
          new ReportExtractParamField("bike.bikeNumber",this.translate.get('report.bike.header.bikeNumber')),
          new ReportExtractParamField("bike.active",this.translate.get('active.Label')),
          new ReportExtractParamField("bike.type",this.translate.get('bikeType.Label')),
          new ReportExtractParamField("notAtStation",this.translate.get('bikeType.notAtStation')),
          new ReportExtractParamField("loadedBy",this.translate.get('report.loadedBy')),
          new ReportExtractParamField("loadedWhen",this.translate.get('report.loadedWhen')),
          new ReportExtractParamField("task",this.translate.get('report.task.Label')),
          new ReportExtractParamField("status",this.translate.get('troubleTickets.StatusLabel'),null,null, statusSubstitution),
          new ReportExtractParamField("location.locationNumber",this.translate.get("report.bike.header.locationNumber")),
          new ReportExtractParamField("location.name", this.translate.get('report.bike.header.locationName')),
          new ReportExtractParamField("location.currentStatus", this.translate.get('report.location.status.Label')),
          new ReportExtractParamField("location.type", this.translate.get('report.location.type.Label')),
          new ReportExtractParamField("coordinates.latitude", this.translate.get('report.location.latitude.Label')),
          new ReportExtractParamField("coordinates.longitude", this.translate.get('report.location.longitude.Label')),
          new ReportExtractParamField("whoCreated",this.translate.get('report.moving.whoCreated')),
          new ReportExtractParamField("whoClosed" ,this.translate.get('report.moving.whoClosed')),
          new ReportExtractParamField("dateCreated",this.translate.get('troubleTickets.CreationDateLabel'),"dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("dateFinished",this.translate.get('finishDate.Label'),"dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("user",this.translate.get('jobItem.AssignedToLabel')),
          new ReportExtractParamField("dateAssigned",this.translate.get("assignmentDate.Label"),"dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("mustBeFixedAt",this.translate.get('location.fixTime'),"dd.MM.yyyy HH:mm", "CET")     // -------------
        ];
    let quantitiesPrefix = "quantities";
    Object.keys(BikeType).forEach(bikeType => {
      let field = quantitiesPrefix + bikeType;
      let label = 'bikeType.report' + "." + bikeType;
      reportParams.fields.push(new ReportExtractParamField(field, this.translate.get(label)));
    });
    let reportFileName = this.translate.get("report.tasksExtract.fileName") ;

    this.dataService.getReportExtract2(reportParams, this.reportType ,reportFileName)
        .subscribe(
            data => {
              this.childProcessing = true;
              this.archivedChild.startProcessingProcess();

            },
            error => {
              console.log("Error downloading the file.");
            });

  }

  dataExtractMovingReport() {
    if(this.reportsListLen >= this.maxReportsListSize){
      this.archivedChild.removeLastReportFromList();
    }


    let filterString=this.filters.toString();
    if (this.filters.type){
      let first = filterString.indexOf("&type=");
      let last  = filterString.indexOf("&",first+1);
      let wordStr;
      if(last==-1){
        wordStr =  filterString.substring(first);
      }else{
        wordStr =  filterString.substring(first,last);
      }
      filterString = filterString.replace(wordStr,"");
    }
    let reportParams = new ReportExtractParam();
    reportParams.method = "GET";
    reportParams.path = `/bikes-sql/api/v1/tasks/bikesJobListExport?type=TO_MOVE&showMustBeFixedOnly=true${filterString}`;  // TO_MOVE!!!!!!!!
    reportParams.paging = true;

      let typeTaskSubstitution = {
          TO_MOVE: this.translate.get('bikesJobListType.list.TO_MOVE')
      };
    let statusSubstitution = {
      "Open":       this.translate.get("report.moving.cell.open"),
      "Closed":     this.translate.get("report.moving.cell.closed"),
      "InProgress": this.translate.get("report.moving.cell.inProgress")
    };


      reportParams.fields =
        [
          new ReportExtractParamField("priority",this.translate.get('priority.Label')),
          new ReportExtractParamField("dateCreated",this.translate.get('troubleTickets.CreationDateLabel'),"dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("dateFinished",this.translate.get('finishDate.Label'),"dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("dateAssigned",this.translate.get("assignmentDate.Label"),"dd.MM.yyyy HH:mm", "CET"),
          new ReportExtractParamField("status",this.translate.get('troubleTickets.StatusLabel'),null,null, statusSubstitution),
          new ReportExtractParamField("user",this.translate.get('report.moving.user')),
          new ReportExtractParamField("whoCreated",this.translate.get('report.moving.whoCreated')),
          new ReportExtractParamField("whoClosed" ,this.translate.get('report.moving.whoClosed')),
          new ReportExtractParamField("location.locationNumber",this.translate.get("report.moving.locationNumber")),
          new ReportExtractParamField("location.locationName", this.translate.get('location.Label')),
          new ReportExtractParamField("mustBeFixedAt",this.translate.get('location.fixTime'),"dd.MM.yyyy HH:mm", "CET")     // -------------
          // new ReportExtractParamField("task"),
        ];
      let quantitiesPrefix = "quantities";
      Object.keys(BikeType).forEach(bikeType => {
        let field = quantitiesPrefix + bikeType;
        let label = quantitiesPrefix + "." + bikeType;
        reportParams.fields.push(new ReportExtractParamField(field, this.translate.get(label)));
      });
    let reportFileName = this.translate.get("report.bikeJobList.fileName") ;

    this.dataService.getReportExtract2(reportParams, this.reportType ,reportFileName)
        .subscribe(
            data => {
              this.childProcessing = true;
              this.archivedChild.startProcessingProcess();

            },
            error => {
              console.log("Error downloading the file.");
            });


  }

  canLoadXls():boolean {
    return CmGuardService.isOrganizationAdmin();
  }

  processingMessage(event: any) {
    this.childProcessing = event;
  }

  onListSize($event: Number) {
    this.reportsListLen = $event
    let i=0;
  }


}

export class BikesJobListFilters extends TableFilter {
  bikeNumber: string;
  type: string;
  haveNoLocation: boolean;
  disabledBikes: boolean;
  status: Array<string>;
  branding: Array<string>;
  bikeTypes: Array<string>;
  location: string;
  priority: number;
  users: Array<string>;
  dateCreatedFrom: string;
  dateCreatedTo: string;
  dateFinishedFrom: string;
  dateFinishedTo: string;

  constructor() {
    super('bikeJobListFilters');
    this.initDates();
  }

  public clear() {
    super.clear();
    this.bikeNumber = null;
    this.type = null;
    this.location = null;
    this.disabledBikes = false;
    this.haveNoLocation = false;
    this.priority = null;
    this.branding = [];
    this.users = [];
    this.status = [];
    this.bikeTypes = [];
    this.resetDates();
    this.save();
  }

  public toString(): string {
    let result = super.toString();
    if (this.bikeNumber) {
      result += `&bikeNumber=${this.bikeNumber}`;
    }
    if (this.type) {
      result += `&type=${this.type}`;
    }
    if (this.location) {
      result += `&location=${this.location}`;
    }
    if (this.haveNoLocation) {
      result += `&haveNoLocation=${this.haveNoLocation}`;
    }
    if (this.disabledBikes) {
      result += `&disabledBikes=${this.disabledBikes}`;
    }
    if (this.priority) {
      result += `&priority=${this.priority}`;
    }
    if (this.users && this.users.length > 0) {
      result += `&user=${this.users}`;
    }
    if (this.dateCreatedFrom) {
      result += `&dateCreatedFrom=${encodeURIComponent(this.dateCreatedFrom)}`;
    }
    if (this.dateCreatedTo) {
      result += `&dateCreatedTo=${encodeURIComponent(this.dateCreatedTo)}`;
    }
    if (this.dateFinishedFrom) {
      result += `&dateFinishedFrom=${encodeURIComponent(this.dateFinishedFrom)}`;
    }
    if (this.dateFinishedTo) {
      result += `&dateFinishedTo=${encodeURIComponent(this.dateFinishedTo)}`;
    }
    if (this.status && this.status.length > 0) {
      result += `&status=${this.status}`;
    }
    if(this.branding && this.branding.length > 0){
      result += `&bikeBranding=${this.branding}`;
    }
    if (this.bikeTypes && this.bikeTypes.length > 0) {
      result += `&bikeTypes=${this.bikeTypes}`;
    }

    return result;
  }

  toStringExportCase() {
    let result = super.toString();
    if (this.bikeNumber) {
      result += `&bikeNumbers=${this.bikeNumber}`;
    }
    if (this.location) {
      result += `&locationIds=${this.location}`;
    }
    if (this.branding && this.branding.length > 0) {
      if(result.length && result.length > 0){
        result += `&`;
      }
      result += `bikeBranding=${this.branding}`;
    }
    if (this.users && this.users.length > 0) {
      result += `&user=${this.users}`;
    }
    if (this.dateCreatedFrom) {
      console.log(this.dateCreatedFrom);
      result += `&dateFrom=${encodeURIComponent(this.dateCreatedFrom)}`;
    }
    if (this.dateCreatedTo) {
      result += `&dateTo=${encodeURIComponent(this.dateCreatedTo)}`;
    }

    return result;

  }

  private resetDates() {
    let currDate = new Date();
    this.dateCreatedTo = moment(currDate).format();
    this.dateCreatedFrom = moment(currDate.setMonth(currDate.getMonth() - 1)).format();
    this.dateFinishedFrom = null;
    this.dateFinishedTo = null;
  }

  private initDates() {
    let currDate = new Date();
    this.dateCreatedTo = this.dateCreatedTo || moment(currDate).format();
    this.dateCreatedFrom = this.dateCreatedFrom || moment(currDate.setMonth(currDate.getMonth() - 1)).format();
  }
}
