import {Component, OnInit} from "@angular/core";
import {DialogRef} from "ngx-modialog";
import {CustomModalContext} from "../../cm-assign/cm-assign.component";
import {CmBikeListItem} from "../cm-bike-list.model";
import {TableFilter} from "../../../shared/tableFilter";
import {DataService} from "../../../services/cm-data.service";
import {CmSparePart} from "../cm-spare-part-list.model";
import {CmHttpClient} from "../../../services/http_client";
import {Router} from "@angular/router";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";
import {TabTitle} from "../../../shared/tabTitle";

@Component({
    selector: 'cm-modal-spare-part-list',
    template: require('./cm-spare-part-list-modal.component.html'),
    styles: [require('./cm-spare-part-list-modal.component.scss').toString()]
})
export class CmSparePartListModalComponent extends TabTitle implements OnInit {
    private items: Array<CmSparePart> = [];

    parent = null;

    private pageLimit: number = 15;
    private total: number;
    private initPage: number = 1;

    private sortField: string;
    private sortDirection: string;

    //filters
    private filters: Filters = new Filters();
    private categories = [];
    private parts = [];
    private stationList = [];
    private stationCodes = [];

    constructor(private http: CmHttpClient,
                private dataService: DataService,
                private router: Router,
                public translate: CmTranslateService,
                public dialog: DialogRef<CustomModalContext>) {
        super('menu.InventoryLabel', translate);
    }

    ngOnInit() {
        this.dataService.getStationList().subscribe(
            data => {
                this.stationList = data;
                this.stationCodes = this.stationList === null ? null : this.stationList
                    .map(function (station) {
                            return station.name
                         }
                  );
                this.filters.stationCodes = this.stationCodes;
                this.dataService.getSparePartsCategoriesList().subscribe(
                    data => {
                        this.categories = data;
                        if (this.filters.category) {
                            this.loadInventoryPart();
                        }
                        this.loadData();

                    }
                );


            }
        );


    }



    private close() {
        this.dialog.close();
    }

    private selectBike(bike: CmBikeListItem) {
        this.parent.selectBike(bike);
        this.close();
    }

    private loadData() {
        this.setPage(this.initPage);
    }

    private loadInventoryPart() {
        this.dataService.getSparePartsByCategory(this.filters.category).subscribe(
            data => this.parts = data
        );
    }

    private openItem(item: CmSparePart) {
        this.dialog.context.parent.addSuggestedPart(item);
       this.close();
    }

    private setPage(page: number) {

        this.http.get('/bikes-sql/api/v1/availability/availability/parts?page=' + page + '&limit=' + this.pageLimit +
            this.filters.createUrl()).map(result => result).subscribe(
            (data:PageAdapter<CmSparePart>) => {
                this.items = data.content;
                this.total = data.total;
            }
        )
    }

    private filter() {
        this.loadData();
        this.filters.save();
    }

    private clearFilters() {
        this.filters.clear();
    }

    private addGroup() {
        this.router.navigate(['/sec/sparePart/addGroup']);
    }

    private addPart() {
        this.router.navigate(['/sec/sparePart/-1']);
    }

    private createMovement() {
        this.router.navigate(['/sec/sparePart/movePart'])
    }

    private exportData() {
        window.open('/bikes-sql/api/v1/availability/availability/partsXls?' + this.filters.createUrl(), 'blank');
    }

    private sort(field) {
        this.filters.sort(field);
        this.reloadData();
    }

    public reloadData() {
        this.items = [];
        this.loadData();
    }
}


class Filters extends TableFilter {
    id: number;
    articleNumber: string;
    station: number;
    category: string;
    part: string;
    freeText: string;
    showHidden: boolean;
    showAllCities: boolean = false;
    stationCodes = [];

    constructor() {
        super('sparePartListFilters');
    }

    public createUrl(): string {
        let url: string = '';
        if (this.id) {
            url += '&id=' + this.id;
        }
        if (this.articleNumber) {
            url += '&articleNumber=' + this.articleNumber;
        }
        if (this.station) {
            url += '&station=' + this.station;
        }
        if (this.category) {
            url += '&category=' + this.category;
        }
        if (this.part) {
            url += '&part=' + this.part;
        }
        if (this.freeText) {
            url += '&freeText=' + this.freeText;
        }
        if (this.showHidden) {
            url += '&showHidden=' + this.showHidden
        }
        if(!this.showAllCities && this.stationCodes){
            url +=  `&stations=${this.stationCodes}`;
        }

        return url;
    }

    public clear() {
        this.id = null;
        this.articleNumber = null;
        this.station = null;
        this.category = null;
        this.part = null;
        this.freeText = null;
        this.showHidden = false;
        this.showAllCities = false;
        this.save();
    }
}
export interface PageAdapter<T> {
    total: number;
    content: Array<T>;
}