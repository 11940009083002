import { DEFAULT_LANGUAGE } from "./../app.component";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { LANGUAGES } from "./mock/language.mock";
import { CmHttpClient } from "./http_client";
import {HttpHeaders, HttpHeaderResponse} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {ReportExtractParam} from "../shared/reportExtractParam";
import {CmArchivedReport} from "../components/cm-report/cm-archived-report.model";
import {timeout} from "rxjs/operators";

@Injectable()
export class DataService {
  constructor(private http: CmHttpClient) {}

  station: string;

  private damageCategoriesUrl =  '/bikes-sql/api/v1/damage-categories';
  private reportingSqlUrl = '/report-format/api/v1';
  private areaUrl = '/bikes-sql/api/v1/areas';

  returnMap(url) {
    return this.http.get(url).map(result => this.processJsonFromData(result));
  }

  returnLocalizedMap(url) {
    let selectedLang = localStorage.getItem("customerLang") || DEFAULT_LANGUAGE;
    return this.http
      .get("/master-data/api/v1/localization/" + selectedLang + "/" + url)
      .map(result => this.processJsonFromData(result));
  }

  returnIdCodeMap(url) {
    return this.returnMap(url).map(result => {
      let output = [];
      result.forEach(el =>
        output.push({
          name: el.id,
          text: el.code
        })
      );
      return output;
    });
  }

  returnNumberCodeMap(url) {
    return this.returnMap(url).map(result => {
      let output = [];
      result.forEach(el =>
        output.push({
          name: el.number,
          text: el.code
        })
      );
      return output;
    });
  }

  readCityAreas() : Observable<any> {
      return this.http.get(this.areaUrl);
  }

    sendCsvConfigurationFile(type: string, fileName: string, file: any) {
        return this.http.post(this.damageCategoriesUrl + '/' + type + '/import', file, {headers: this.getUploadFileHeaders(fileName)});
    }
    sendCsvFile(url: string, fileName: string, file: any) {
        return this.http.post(url, file, {headers: this.getUploadFileHeaders(fileName)});
    }

    sendJson(url: string, body: any):Observable<any> {
        return this.http.post(url, body, null);
    }

  private getUploadFileHeaders(fileName: string): HttpHeaders {
    let headers:HttpHeaders = new HttpHeaders();
    headers=headers.append('Content-Type', 'application/octet-stream');
    headers=headers.append('Content-Disposition', 'attachment; filename=' + fileName);
    return headers;
  }

  /**
   * Process input JSON data. If the data is null then return empty object.
   * @param data
   * @returns {Array}
   */
  private processJsonFromData(data) {
    let arr = [];
    try {
      arr = data;//.json();
    } catch (e) {
      arr = [];
    }

    return arr;
  }

  getLanguages(): Promise<any> {
    return Promise.resolve(LANGUAGES);
  }

  getStationList() {
    return this.returnIdCodeMap("/user-information/api/v1/stations");
  }

  getSparePartsCategoriesList(showHidden = false) {
    if(showHidden){
      return this.returnNumberCodeMap(`/bikes-sql/api/v1/category?showHidden=${showHidden}`);
    }else {
      return this.returnNumberCodeMap("/bikes-sql/api/v1/category");
    }
  }

  getSparePartsByCategory(category: string) {
    return this.returnIdCodeMap("/bikes-sql/api/v1/parts/category/" + category);
  }

  saveBikeAllowed(bikeId, allowedLocationIds) {
    return this.http.post('/bikes-sql/api/v1/bicycle/' + bikeId + '/allowable', allowedLocationIds, null);
  }

  getBikeAllowedLocations(bikeId) {
    return this.returnMap('/bikes-sql/api/v1/bicycle/'+bikeId+'/allowable');
  }

  getStationFromList(station: string, stationList: Array<any>) {
    let stationId: number;
    try {
      stationId = parseInt(station);
    } catch (e) {
      return station;
    }

    let item = stationList.filter(el => el.name === stationId)[0];
    if (item) {
      return item.text;
    } else {
      return "";
    }
  }

  getReportExtract2(params: ReportExtractParam, reportType:string, fileName: string): Observable<any> {
    let url = this.reportingSqlUrl +'/excel2new/'  + reportType  + "/"  + fileName ;
    let headers = new HttpHeaders();
    headers=headers.append('Content-Type', 'application/json');
    let reqParams = {
      headers: headers,
      // responseType: 'blob'
    };
    return this.http.post(url, params, reqParams);
  }
  getReportExtract(params: ReportExtractParam, fileName: string): Observable<any> {
    let url = this.reportingSqlUrl +'/excel/'+ fileName;
    let headers = new HttpHeaders();
    headers=headers.append('Content-Type', 'application/json');
    let reqParams = {
      headers: headers,
      responseType: 'blob'
    };
    return this.http.post(url, params, reqParams);
  }

  static getErrorMsg(error: any) : string {
    let retStr = "";
    if(error.error){
      if(error.error.errors){
        for(let val of error.error.errors){
          retStr = retStr + "<br> in object: " +  val.objectName + " - field: " + val.field + "  " + (val.message? val.message : val.defaultMessage);
        }
      }
      retStr = (error.error.message? error.error.message: error.error) +  retStr;
    }else{
      retStr = retStr + error.message
    }
    return retStr;
  }
  getArchivedReportList(reportType:string): Observable<CmArchivedReport[]> {
    return this.returnMap(this.reportingSqlUrl + "/archived-report/for-me/" + reportType)
  }

  deleteArchivedReport(uuid: string): Observable<any> {
    return this.deleteReturnResponse(this.reportingSqlUrl + "/archived-report/delete/" + uuid);
  }

  cancelReportProcessing(reportType: string): Observable<any> {
    return this.postReturnSame(this.reportingSqlUrl + '/processing-cancel/' + reportType, {})
  }

  loadArchivedReport(uuid: string): Observable<any> {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let reqParams = {
      headers: headers,
      responseType: 'blob'
    };
    return this.http.get(this.reportingSqlUrl + '/archived-report/load/'+uuid, reqParams);
  }

  postReturnSame(url: string, body: any) {
    return this.http.post(url, JSON.stringify(body), null)
        .map((result: any) => body);
  }
  deleteReturnResponse(url: string) {
    return this.http.delete(url)
        .map((response: any) => response);
  }
  // getAutomatedExtractReport(filters:any): Observable<any> {
  //   let url = this.reportingSqlUrl + "/automated-extract";
  //   // url =  window.location.protocol + "//localhost:8087/hertz-sql-reporting/api/v1"  + "/automated-extract";
  //   let headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.post(url, filters, null).pipe(timeout(180000));
  // }

}
