import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CmHttpClient} from "../../../services/http_client";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'cm-bike-type-filter',
    template: require('./cm-bike-type-filter.component.html'),
    styles: [require('./cm-bike-type-filter.component.scss').toString()]
})
export class CmBikeTypeFilterComponent implements OnInit {
    @Input() selectedTypes: Array<string> = [];
    @Input() mapMode: boolean;

    @Output() selectedTypesChange = new EventEmitter<Array<string>>();
    @Output() typesLoaded = new EventEmitter<any>();
    @Output() search = new EventEmitter<any>();

    private typeList: Array<TypeListItem> = [];
    private dataSourceInit = false;

    constructor(private translationService: TranslateService,
                private http: CmHttpClient,
                private ref: ChangeDetectorRef) {
    }

    ngOnChanges() {
        this.typeList.forEach(el => el.selected = false);
        if (this.selectedTypes) {
            this.selectedTypes.forEach(el => {
                this.typeList.forEach(el1 => {
                    if (el1.name === el) {
                        el1.selected = true;
                    }
                })
            });
        }
    }

    ngOnInit() {
        this.http.get('/bikes-sql/api/v1/bicycles/types').subscribe(data  => {
            this.typesLoaded.next(data);
            let types = data as any;
            if (types && types.length) {
                this.translationService.get(types.map(item => CmBikeTypeFilterComponent.localizationKey(item))).subscribe(localization => {
                    types.forEach(type => {
                       let text = localization[CmBikeTypeFilterComponent.localizationKey(type)];
                       this.typeList.push(new TypeListItem(type, text));
                   });
                   this.dataSourceInit = true;

                   if (this.selectedTypes) {
                       this.selectedTypes = this.selectedTypes.filter(selected => this.typeList.some(type => type.name === selected));
                   }
                   this.ngOnChanges();
                });
            }
        });
    }

    private static localizationKey(type: string): string {
        return 'bikeType.' + type;
    }

    private _changeType() {
        let outList = [];
        this.typeList.forEach(el => {
            if (el.selected) {
                outList.push(el.name);
            }
        });
        this.selectedTypesChange.next(outList);
    }

}

class TypeListItem {
    name: string;
    text: string;
    selected: boolean;

    constructor(name: string, text: string) {
        this.name = name;
        this.text = text;
    }
}