import moment = require('moment');

export class CmUtil {
  static simpleSort(list: any[], sortProperty: string, direction: SORT_DIRECTION = SORT_DIRECTION.DESC): any[] {
    try {
      if (Array.isArray(list) && sortProperty) {
        return list.sort((a, b) => {
          let prop1 = a[sortProperty];
          let prop2 = b[sortProperty];
          if (prop1 && prop2) {
            switch (direction) {
              case SORT_DIRECTION.ASC:
                return this.isNumber(prop1) && this.isNumber(prop2) ? prop1 - prop2 : prop1.localeCompare(prop2);
              case SORT_DIRECTION.DESC:
                return this.isNumber(prop1) && this.isNumber(prop2) ? prop2 - prop1 : prop2.localeCompare(prop1);
              default:
                break;
            }
          }
          return -1;
        });
      }
      return list;
    } catch (error) {
      console.error(error);
      return list;
    }
  }

  static isNumber(param: any): boolean {
    return param ? typeof param === 'number' : false;
  }

  static formatDate(date, format = DEFAULT_DATE_FORMAT): string {
    try {
      return date ? moment(date).format(format) : date;
    } catch (error) {
      console.error(error);
      return date;
    }
  }
    public static  writeDownFile(csv, contentType, fileName){

        const blob = new Blob([csv], { type: contentType });

        console.log('start download:');
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName);
        }else {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click(); // IE: "Access is denied";
            document.body.removeChild(a);
        }
    }
    public static  CSVToArray (strData, strDelimiter) {

        strDelimiter = (strDelimiter || ",");

        var objPattern = new RegExp(
            (
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
        );

        var arrData = [[]];
        var arrMatches = null;

        while (arrMatches = objPattern.exec(strData)) {
            var strMatchedDelimiter = arrMatches[1];
            if (
                strMatchedDelimiter.length &&
                strMatchedDelimiter !== strDelimiter
            ) {
                arrData.push([]);
            }
            var strMatchedValue;
            if (arrMatches[2]) {
                strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"),
                    "\""
                );
            } else {
                strMatchedValue = arrMatches[3];
            }
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        return (arrData);
    }

    static downloadFile(data: Response, fileName: string) {
        let blob = data;//.blob();

        if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, fileName);
        else {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
        }
    }


}


export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY HH:mm';

export enum SORT_DIRECTION {
  ASC,
  DESC
}
