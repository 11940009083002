import { CmAutoTicketConfigComponent } from "../cm-auto-ticket-config/item/cm-auto-ticket-config.component";
import { CmAutoTicketListComponent } from "../cm-auto-ticket-config/list/cm-auto-ticket-config-list.component";
import { CmHome } from "./cm-home.component";
import { CmProfileComponent } from "../cm-profile/cm-profile.component";
import { CmCurrentStationComponent } from "../cm-profile/cm-current-station/cm-current-station.component";
import { CmSettingsComponent } from "../cm-profile/settings/cm-settings.component";
import { CmRootPageComponent } from "../cm-main-map/cm-map-with-filters.component";
import { DatepickerModule, TimepickerModule } from "ngx-bootstrap";
import { CmMapComponent } from "../cm-map/cm-map.component";
import { CmJobListComponent } from "../cm-job-list/list/cm-job-list.component";
import { CmJobItemComponent } from "../cm-job-list/item/cm-job-item.component";
import { CmJobItemMapComponent } from "../cm-job-list/item/cm-job-item-map.component";
import { CmLocationItemComponent } from "../cm-location/item/cm-location-item.component";
import { CmTroubleTicketListComponent } from "../cm-trouble-ticket/list/cm-trouble-tiket-list.component";
import { CmTroubleTicketComponent } from "../cm-trouble-ticket/item/cm-trouble-ticket.component";
import { CmSparePartListComponent } from "../cm-spare-part/list/cm-spare-part-list.component";
import { CmMoveSparePartComponent } from "../cm-spare-parts-move/cm-move-spare-part.component";
import { CmSparePartDetailComponent } from "../cm-spare-part/item/cm-spare-part-detail.component";
import { CmPicturesComponent } from "../cm-pictures/cm-pictures.component";
import { CmBikeJobListComponent } from "../cm-bike-job/list/cm-bike-job-list.component";
import { CmBikeJobItemComponent } from "../cm-bike-job/item/cm-bike-job-item.component";
import { CmJobListByDistrictComponent } from "../cm-job-list-disctrict-group/cm-job-list-by-district.component";
import { CmUserComponent } from "../cm-user/cm-user.component";
import { CmNewsComponent } from "../cm-news/cm-news.component";
import { CmBikeDispatchComponent } from "../cm-bike-dispatch/list/cm-bike-dispatch.component";
import { CmBikeDispatchItemComponent } from "../cm-bike-dispatch/item/cm-bike-dispatch-item.component";
import { CmRepairListComponent } from "../cm-repair/list/cm-repair-list.component";
import { CmRepairItemComponent } from "../cm-repair/item/cm-repair-item.component";
import {CmDamageBikeCatConfigComponent} from "../cm-damage-cat-bike-config/list/cm-damage-cat-bike-config-list.component";
import {CmDamageLocationCatConfigComponent} from "../cm-damage-cat-location-config/list/cm-damage-cat-location-config-list.component";
import {CmDamageBikeCatConfigItemComponent} from "../cm-damage-cat-bike-config/item/cm-damage-cat-bike-config-item.component";
import {CmDamageLocationCatConfigItemComponent} from "../cm-damage-cat-location-config/item/cm-damage-cat-location-config-item.component";

export const secServicies = [DatepickerModule, TimepickerModule];

export const secComponents = [
  CmHome,
  CmRootPageComponent,
  CmProfileComponent,
  CmCurrentStationComponent,
  CmSettingsComponent,
  CmMapComponent,
  CmJobListComponent,
  CmJobItemComponent,
  CmJobItemMapComponent,
  CmLocationItemComponent,
  CmTroubleTicketListComponent,
  CmTroubleTicketComponent,
  CmSparePartListComponent,
  CmMoveSparePartComponent,
  CmSparePartDetailComponent,
  CmPicturesComponent,
  CmBikeJobListComponent,
  CmBikeJobItemComponent,
  CmUserComponent,
  CmJobListByDistrictComponent,
  CmDamageBikeCatConfigComponent,
  CmDamageLocationCatConfigComponent,
  CmDamageBikeCatConfigItemComponent,
  CmDamageLocationCatConfigItemComponent,
  CmBikeDispatchComponent,
  CmBikeDispatchItemComponent,
  CmNewsComponent,
  CmRepairListComponent,
  CmRepairItemComponent,
  CmAutoTicketListComponent,
  CmAutoTicketConfigComponent
];
