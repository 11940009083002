import {NgModule} from "@angular/core";
import {CmAuthService} from "./services/cm-auth.service";
import {routing} from "./app.routes";
import {CmLogin} from "./components/cm-login/cm-login.component";
import {AppComponent} from "./app.component";
import {CmNoRolesComponent} from "./components/cm-no-roles/cm-no-role.component";
import {secComponents, secServicies} from "./components/cm-main/cm-home.module";
import {CmSharedModule} from "./shared/shared.module";
import {LocationStrategy, HashLocationStrategy} from "@angular/common";
import {CmPageNotFoundComponent} from "./components/cm-error-page/cm-page-not-found.component";
import {AgmCoreModule} from "@agm/core";

@NgModule({
  imports: [
    routing,
    CmSharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBQaeSDTl7e9p13PKgE7Qyq3z99zFKn_Xg'
    })
  ],
  providers: [
    CmAuthService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    ...secServicies
  ],
  declarations: [
    AppComponent,
    CmLogin,
    CmNoRolesComponent,
    CmPageNotFoundComponent,
    ...secComponents
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
