import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {CmHttpClient} from "../../../services/http_client";
import {DialogRef, ModalComponent, overlayConfigFactory} from "ngx-modialog";
import {BSModalContext, Modal} from "ngx-modialog/plugins/bootstrap";
import {CmTranslateService} from "../../../services/localization/cm-translate.service";
import {DataService} from "../../../services/cm-data.service";
import {CmBaseAutoTicketConfigComponent} from "../cm-base-auto-ticket-config.component";
import {CmAutoTicketConfig} from "../cm-auto-ticket-conifg.model";
import {CmModalBikeListComponent} from "../../cm-bike-short-list/modal-window/cm-modal-bike-list.component";
import {BIKE_TYPE} from "../../../shared/const/missionType";
import {CmModalLocationListComponent} from "../../cm-location/modal-window/cm-modal-location-list.component";
import {CmLocation} from "../../../services/cm-location.service";
import {CmGuardService} from "../../../services/guards/cm-guard.service";

@Component({
  selector: "cm-auto-ticket-config",
  template: require("./cm-auto-ticket-config.component.html"),
  styles: [require("./cm-auto-ticket-config.component.scss").toString()]
})
export class CmAutoTicketConfigComponent extends CmBaseAutoTicketConfigComponent
  implements OnInit, ModalComponent<CmAutoTicketConfigModalDialog> {
  private config: CmAutoTicketConfig;
  private priorityList = [1, 2, 3];
  private damageCategoryType = BIKE_TYPE;

  constructor(
    public dialog: DialogRef<CmAutoTicketConfigModalDialog>,
    private http: CmHttpClient,
    private dataService: DataService,
    private router: Router,
    protected translationService: CmTranslateService,
    protected modal: Modal
  ) {
    super("autoTicketConfig.Title", modal, translationService);

    this.config = dialog.context.config;
  }

  ngOnInit() {}

  private close() {
    this.dialog.close();
  }

  private save() {
    this.http.post("/bikes-sql/api/v1/auto-tickets-info" + (this.isLocationAutoTroubleTicket() ? '/location' : ''), this.config).subscribe(
        data => {this.modal.alert()
            .message(this.translationService.get('message.SaveMsg'))
            .open()
            .result
            .then(dialog => dialog.result)
            .then(result => this.closeAndRefreshParent())
            .catch(err => {});
        },
      error => {
        console.error(JSON.stringify(error));
        this.showMessage(`Error: ${this.translationService.get(error.message)}`);
      }
    );
  }

  private closeAndRefreshParent() {
    try {
      this.dialog.context.parent.reloadData.call(this.dialog.context.parent);
      this.dialog.close();
    } catch (error) {
      console.error(error);
    }
  }

  private openBikes() {
    this.modal.open(CmModalBikeListComponent, overlayConfigFactory({parent: this}, BSModalContext))
  }

  public selectBike(bike: any) {
    if(!this.config.bikes) this.config.bikes = [];
    if(this.config.bikes.length == 10) {
      this.showMessage("Sorry, you can't add more then 10 bikes.");
      return;
    }

    this.config.bikes.push(bike);
  }

  private removeBike(row) {
    let index = this.config.bikes.indexOf(row, 0);
    if (index > -1) {
      this.config.bikes.splice(index, 1);
    }
  }

  private allowedToEditSettings(): boolean {
    return CmGuardService.isOrganizationAdmin() || CmGuardService.isStationManager();
  }

  private isLocationAutoTroubleTicket() {
    return this.config.type === 'LOCATION'
  }

  private isBikeAutoTroubleTicket() {
    return this.config.type === 'BIKE'
  }

  private openLocations() {
    this.modal.open(CmModalLocationListComponent, overlayConfigFactory({ parent: this }, BSModalContext));
  }

  public selectLocation(location: CmLocation) {
    this.config.location = new CmLocation();
    this.config.location.locationId = location.locationId;
    this.config.location.locationName = location.fullName;
  }

}

export class CmAutoTicketConfigModalDialog extends BSModalContext {
  public config: CmAutoTicketConfig;
  public parent: any;
}
